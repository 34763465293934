// @flow strict
import { BLUE, PURPLE } from "../theme";
import { DEFAULT_PARAMS } from "../constants";

const createDoor = (
  position: XYZ,
  rotation: XYZ,
  id: string,
  textValue: string,
  params: ?Params,
  addClasses: boolean = true
): AframeHTMLElement => {
  const poi = document.createElement("a-entity");
  poi.object3D.position.set(position.x, position.y, position.z);
  poi.object3D.rotation.order = "YXZ";
  poi.object3D.rotation.set(rotation.x, rotation.y, rotation.z);
  poi.setAttribute("id", id);

  const subtype = (params && params.subtype) || DEFAULT_PARAMS.door.subtype;
  const width = (params && params.width) || DEFAULT_PARAMS.door.width;
  const height = (params && params.height) || DEFAULT_PARAMS.door.height;
  const borderWidth = 0.04;
  const halfWidth = width / 2;
  const halfHeight = height / 2;
  const halfBorderWidth = borderWidth / 2;

  const textEl = document.createElement("a-text");
  textEl.setAttribute("value", textValue);
  textEl.setAttribute("font", "dist/custom-msdf.json");
  textEl.setAttribute("negate", "false");
  textEl.setAttribute("position", "-0.4 1.3 0");
  poi.appendChild(textEl);

  // const door = document.createElement("a-entity");
  // door.setAttribute("gltf-model", "url(door.glb)");
  // door.classList.add("element");
  // door.classList.add("interactiveElement");
  // poi.appendChild(door);

  // border left
  const plane1 = document.createElement("a-plane");
  if (addClasses) plane1.classList.add("element");
  plane1.setAttribute(
    "position",
    `${-halfWidth + halfBorderWidth} ${halfHeight} 0`
  );
  plane1.setAttribute("height", height);
  plane1.setAttribute("width", borderWidth);
  plane1.setAttribute(
    "material",
    `color: ${
      subtype === "interior" ? BLUE : PURPLE
    }; shader: flat; side: double`
  );
  poi.appendChild(plane1);

  // border right
  const plane2 = document.createElement("a-plane");
  if (addClasses) plane2.classList.add("element");
  plane2.setAttribute(
    "position",
    `${halfWidth - halfBorderWidth} ${halfHeight} 0`
  );
  plane2.setAttribute("height", height);
  plane2.setAttribute("width", borderWidth);
  plane2.setAttribute(
    "material",
    `color: ${
      subtype === "interior" ? BLUE : PURPLE
    }; shader: flat; side: double`
  );
  poi.appendChild(plane2);

  // border top
  const plane3 = document.createElement("a-plane");
  if (addClasses) plane3.classList.add("element");
  plane3.setAttribute("position", `0 ${height - halfBorderWidth} 0`);
  plane3.setAttribute("height", borderWidth);
  plane3.setAttribute("width", width - 2 * borderWidth);
  plane3.setAttribute(
    "material",
    `color: ${
      subtype === "interior" ? BLUE : PURPLE
    }; shader: flat; side: double`
  );
  poi.appendChild(plane3);

  const center = document.createElement("a-plane");
  if (addClasses) center.classList.add("element");
  if (addClasses && params && params.description) {
    center.classList.add("interactiveElement");
  }
  center.setAttribute("position", `0 ${halfHeight - borderWidth / 2} 0`);
  center.setAttribute("height", height - borderWidth);
  center.setAttribute("width", width - 2 * borderWidth);
  center.setAttribute(
    "material",
    `color: #eee; transparent: true; opacity: 0.2; shader: flat; side: double`
  );
  poi.appendChild(center);
  if (addClasses) poi.classList.add("editInfo");
  if (addClasses) poi.classList.add("door");
  return poi;
};

export default createDoor;
