// @flow strict
import React, { Component } from "react";

import appStore from "../images/badge_appstore.png";
import googlePlay from "../images/badge_googleplay.png";
import PageContainer from "../components/PageContainer";

type Props = {};

export default class QR extends Component<Props> {
  render() {
    return (
      <PageContainer>
        <div>
          If you just scanned a QR code with your Camera app, please download
          the "Easy Path View" app on the App Store or on Google Play and scan
          again the QR code with the app.
        </div>

        <div style={{ marginTop: "20px" }}>
          <a href="https://apps.apple.com/us/app/easy-path-view/id1488203437">
            <img
              style={{ height: "80px" }}
              alt="Download on the App Store"
              src={appStore}
            />
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.illusion3d.easypathview&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
            <img
              style={{ height: "80px" }}
              alt="Get it on Google Play"
              src={googlePlay}
            />
          </a>
          <p>
            Google Play and the Google Play logo are trademarks of Google LLC.
          </p>
        </div>
      </PageContainer>
    );
  }
}
