/* global THREE */
// @flow strict
import { UTILITIES } from "../state";
import deleteObject from "./deleteObject";

export const supportsAR = () => {
  return UTILITIES.activeRealityType === "ar";
};

export const getPointFromUuid = (uuid: string): ?Point => {
  return UTILITIES.points.get(uuid);
};

export const createRandomId = (): string =>
  Math.round(Math.random() * -1000000).toString();

export const undoStep = () => {
  if (UTILITIES.points.size > 0) {
    let lastUuid;
    for (const item of UTILITIES.points.entries()) {
      // item is an array [key, value]
      if (
        item[1].record &&
        item[1].record.collectionId !== UTILITIES.selectedCollection
      ) {
        continue;
      }
      lastUuid = item[0];
    }
    if (lastUuid) {
      const pointElToDelete = document.getElementById(lastUuid);
      pointElToDelete && deleteObject(pointElToDelete);
      return true;
    }
  }
  return false;
};

export const calcDistance = (start: XYZ, end: XYZ) => {
  return Math.sqrt(
    Math.pow(end.x - start.x, 2) +
      Math.pow(end.y - start.y, 2) +
      Math.pow(end.z - start.z, 2)
  );
};

export function showHUD(msg: string) {
  const hud = document.getElementById("hud");
  // hud.setAttribute("value", msg);
  hud.innerHTML = msg.replace(/\n/g, "<br/>");
}

// precision to 1mm or 0.001 rad
export const roundTo3Decimals = (n: number) => {
  return Math.round(n * 1000) / 1000;
};

// precision to 1cm
export const roundTo2Decimals = (n: number) => {
  return Math.round(n * 100) / 100;
};

export const getLocalPositionAndRotation = (function() {
  const tempObj = new THREE.Object3D();
  tempObj.rotation.order = "YXZ";
  const tempMatrix = new THREE.Matrix4();
  return function(cursor: Object3D, camera: Object3D, container: Object3D) {
    tempObj.matrixAutoUpdate = false;
    tempObj.matrix.copy(cursor.matrix);
    tempObj.matrix.decompose(
      tempObj.position,
      tempObj.quaternion,
      tempObj.scale
    );
    tempObj.matrixAutoUpdate = true;
    tempObj.updateMatrix();
    const lookAtCamera = document.getElementById("lookAtCamera");
    if (lookAtCamera) {
      tempObj.quaternion.copy(lookAtCamera.object3D.quaternion);
      tempObj.updateMatrix();
    }
    const worldToLocal = tempMatrix.getInverse(container.matrixWorld);
    tempObj.applyMatrix(worldToLocal);

    // precision to 1 cm
    const localPosition: XYZ = {
      x: roundTo2Decimals(tempObj.position.x),
      y: roundTo3Decimals(tempObj.position.y),
      z: roundTo2Decimals(tempObj.position.z)
    };
    // snap to the ground if y is close to 0
    if (-0.1 <= localPosition.y && localPosition.y <= 0.1) {
      localPosition.y = 0;
    }
    // precision to 0.001 rad, about 0.06 degrees
    const localRotation: XYZ = {
      x: roundTo3Decimals(tempObj.rotation.x),
      y: roundTo3Decimals(tempObj.rotation.y),
      z: roundTo3Decimals(tempObj.rotation.z)
    };
    return { position: localPosition, rotation: localRotation };
  };
})();
