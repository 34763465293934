// @flow strict
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { SnackbarProvider, withSnackbar } from "notistack";

import SignIn from "./pages/SignIn";
import QR from "./pages/QR";
import { CssBaseline, ThemeProvider } from "./material";

import App from "./App";
import * as serviceWorker from "./serviceWorker";
import theme from "./theme";
import "./scene";

const MyApp = withSnackbar(App);

ReactDOM.render(
  <Router>
    <CssBaseline />
    <SnackbarProvider maxSnack={3} preventDuplicate dense>
      <ThemeProvider theme={theme}>
        <Switch>
          <Route path="/qr">
            <QR />
          </Route>
          <Route path="/signin">
            <SignIn />
          </Route>
          <Route path="/">
            <MyApp />
          </Route>
        </Switch>
      </ThemeProvider>
    </SnackbarProvider>
  </Router>,
  document.getElementById("root")
);

serviceWorker.unregister();
// serviceWorker.register({
//   onUpdate: () => {
//     //Some UI notification comes here, then reload
//     window.location.reload();
//   }
// });
