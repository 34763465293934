// @flow strict
import { type State } from "./App";

export const getDestinations = (points: Points): Destinations =>
  points
    .filter(
      point =>
        (point.type === "door" ||
          point.type === "zone" ||
          point.type === "elevator") &&
        point.text
    )
    .map(p => ({
      value: p.recordId,
      // $FlowFixMe
      label: p.text || "",
      // $FlowFixMe
      type: p.type,
      position: p.position
    }))
    .sort((a, b) => (a.label > b.label ? 1 : -1));

export const loadPoints = (prevState: State, points: Points) => {
  const newPoints: Map<UUID, Point> = new Map();
  for (const point of points) {
    newPoints.set(point.uuid, point);
  }
  return { ...prevState, points: newPoints, saved: true, deletedPointIds: [] };
};

export const savePoints = (prevState: State) => {
  return { ...prevState, saved: true, deletedPointIds: [] };
};

export const addPoint = (prevState: State, newPoint: Point) => {
  // const points = new Map(prevState.points);
  const points = prevState.points;
  points.set(newPoint.uuid, newPoint);
  return { ...prevState, points, saved: false };
};

export const deletePoint = (prevState: State, pointToDelete: Point) => {
  // const points = new Map(prevState.points);
  const points = prevState.points;
  points.delete(pointToDelete.uuid);
  const deletedPointIds = [];
  if (pointToDelete.type === "step") {
    if (!pointToDelete.prevPoint && !pointToDelete.recordId.startsWith("-")) {
      deletedPointIds.push(pointToDelete.recordId);
    }
  } else {
    if (!pointToDelete.recordId.startsWith("-")) {
      deletedPointIds.push(pointToDelete.recordId);
    }
  }
  return {
    ...prevState,
    points,
    deletedPointIds: [...prevState.deletedPointIds, ...deletedPointIds],
    saved: false
  };
};

export const updatePoint = (prevState: State, newPoint: Point) => {
  // const points = new Map(prevState.points);
  const points = prevState.points;
  points.set(newPoint.uuid, newPoint);
  return { ...prevState, points, saved: false };
};
