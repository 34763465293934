// @flow strict
import React, { PureComponent } from "react";
import { MenuItem } from "../material";

import ActionButton from "./ActionButton";
import { UTILITIES } from "../state";

import iconDoor from "../images/icons/256x256/door.png";
import iconEditPath from "../images/icons/256x256/edit-path.png";
// import iconZone from "../images/icons/256x256/zone.png";
import iconElevator from "../images/icons/256x256/elevator.png";
// import iconLinkToInfo from "../images/icons/256x256/link-to-info.png";
import iconLinkToQRcode from "../images/icons/256x256/link-to-qrcode.png";
import iconBlueprint from "../images/icons/256x256/blueprint.png";
// import iconASA from "../images/icons/256x256/asa.png";
// import iconStairs from "../images/icons/256x256/stairs.png";
// import iconUsers from "../images/icons/256x256/users.png";

type Props = {
  currentAction: ?string,
  floorFound: boolean,
  isPersonalBucket: boolean,
  originMarkerFound: boolean,
  subtype: ?DoorEnum,
  onClick: (actionId: ?EditorAction, subtype: ?DoorEnum) => void,
  startMarkerDetection: () => void,
  stopMarkerDetection: () => void
};

export default class ActionsBar extends PureComponent<Props> {
  render() {
    const {
      currentAction,
      floorFound,
      isPersonalBucket,
      originMarkerFound,
      subtype,
      onClick,
      startMarkerDetection,
      stopMarkerDetection
    } = this.props;
    return (
      <div
        style={{
          position: "absolute",
          paddingTop: "10px",
          right: 0,
          zIndex: 500,
          display: "flex",
          flexDirection: "column"
        }}
      >
        {/* <ActionButton
      icon={iconUsers}
      active={currentAction === "users"}
      id="users"
      onClick={onClick}
    /> */}
        <ActionButton
          icon={iconEditPath}
          active={currentAction === "editPath"}
          id="editPath"
          disabled={!originMarkerFound}
          onClick={(actionId: ?EditorAction, subtype: ?DoorEnum) => {
            if (currentAction === "editPath") {
              onClick(null, null);
            } else {
              onClick(actionId, subtype);
              UTILITIES.prevPoint = null;
            }
          }}
          simpleAction
        />
        {/* <ActionButton
          icon={iconZone}
          active={currentAction === "zone"}
          id="zone"
          disabled={!originMarkerFound}
          onClick={(actionId: ?EditorAction, subtype: ?DoorEnum) => {
            if (currentAction === "zone") {
              onClick(null, null);
            } else {
              onClick(actionId, subtype);
            }
          }}
          simpleAction
        /> */}
        <ActionButton
          icon={iconDoor}
          active={currentAction === "door"}
          id="door"
          disabled={!originMarkerFound}
          onClick={onClick}
        >
          <MenuItem value="entrance" selected={subtype === "entrance"}>
            Exterior entrance door
          </MenuItem>
          <MenuItem value="interior" selected={subtype === "interior"}>
            Interior door
          </MenuItem>
        </ActionButton>
        {!isPersonalBucket ? (
          <React.Fragment>
            <ActionButton
              icon={iconElevator}
              active={currentAction === "elevator"}
              id="elevator"
              disabled={!originMarkerFound}
              onClick={(actionId: ?EditorAction, subtype: ?DoorEnum) => {
                if (currentAction === "elevator") {
                  onClick(null, null);
                } else {
                  onClick(actionId, subtype);
                }
              }}
              simpleAction
            />
            <ActionButton
              icon={iconBlueprint}
              active={currentAction === "blueprint"}
              id="blueprint"
              disabled={!originMarkerFound}
              onClick={(actionId: ?EditorAction, subtype: ?DoorEnum) => {
                if (currentAction === "blueprint") {
                  onClick(null, null);
                } else {
                  onClick(actionId, subtype);
                }
              }}
              simpleAction
            />
            {/* <ActionButton
              icon={iconASA}
              active={currentAction === "asa"}
              id="asa"
              disabled={!originMarkerFound}
              onClick={(actionId: ?EditorAction, subtype: ?DoorEnum) => {
                if (currentAction === "asa") {
                  onClick(null, null);
                } else {
                  onClick(actionId, subtype);
                }
              }}
              simpleAction
            /> */}
          </React.Fragment>
        ) : null}

        {/* <ActionButton
      icon={iconStairs}
      active={currentAction === "stairs"}
      id="stairs"
      onClick={onClick}
    /> */}
        {/* <ActionButton
      icon={iconLinkToInfo}
      active={currentAction === "linkToInfo"}
      id="linkToInfo"
      onClick={onClick}
    /> */}
        <ActionButton
          icon={iconLinkToQRcode}
          active={currentAction === "marker"}
          id="marker"
          disabled={!floorFound}
          onClick={(actionId: ?EditorAction, subtype: ?DoorEnum) => {
            if (currentAction === "marker") {
              stopMarkerDetection();
              stopMarkerDetection.flush();
              onClick(null, null);
            } else {
              startMarkerDetection();
              onClick(actionId, subtype);
            }
          }}
          simpleAction
        />
      </div>
    );
  }
}
