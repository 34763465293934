// @flow strict
export let KINTO_URL = "";
export let SAVE_BASIC_HEADER = false;
export let QRCODEGEN_URL = "https://easypathview.net/qrcodegen/";
if (process.env.NODE_ENV === "production") {
  if (window.location.host === "epveditor.nova-ideo.com") {
    // staging
    // KINTO_URL = "https://epveditor.nova-ideo.com/v1";
    KINTO_URL = "https://epvkinto.nova-ideo.com/v1";
    QRCODEGEN_URL = "https://epveditor.nova-ideo.com/qrcodegen/";
  } else if (window.location.host === "local.easypathview.net") {
    KINTO_URL = "https://local.easypathview.net/v1";
    QRCODEGEN_URL = "https://local.easypathview.net/qrcodegen/";
  } else {
    // prod
    KINTO_URL = "https://easypathview.net/v1";
    // KINTO_URL = "https://kinto.easypathview.net/v1";
    QRCODEGEN_URL = "https://easypathview.net/qrcodegen/";
  }
} else {
  SAVE_BASIC_HEADER = true;
  KINTO_URL = `${window.location.protocol}//${
    window.location.hostname
  }:8888/v1`;
  // KINTO_URL = "http://192.168.1.24:8888/v1";
  QRCODEGEN_URL = "http://localhost/epv/qrcodegen/";
}

export const DEFAULT_Y = -1.4;
export const DETECTED_MARKER_COLOR = "#ccc";

export const DEFAULT_PARAMS = {
  step: null,
  door: { width: 1, height: 2.1, subtype: "entrance" },
  elevator: { width: 1.5, depth: 2 },
  zone: { width: 3, depth: 2 },
  blueprint: { width: 50, depth: 50 }
};

export const emptyObject = {};
