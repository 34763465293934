// @flow strict
import { DETECTED_MARKER_COLOR } from "../constants";

//const MARKER_SIZE = 0.053;
const MARKER_SIZE = 0.190;
const createMarker = (
  position: XYZ,
  rotation: XYZ,
  id: string,
  textValue: string,
  params: ?Params = null,
  addClasses: boolean = true,
  color: string = "yellow"
): AframeHTMLElement => {
  let marker = document.getElementById(id);
  if (marker) {
    return marker;
  }
  marker = document.createElement("a-entity");
  marker.object3D.position.set(position.x, position.y, position.z);
  marker.object3D.rotation.order = "YXZ";
  marker.object3D.rotation.set(rotation.x, rotation.y, rotation.z);
  marker.object3D.updateMatrix();
  marker.setAttribute("id", id);
  const square = document.createElement("a-plane");
  marker.appendChild(square);
  if (addClasses) square.classList.add("element");
  const markerSize =
    color === DETECTED_MARKER_COLOR ? MARKER_SIZE + 0.05 : MARKER_SIZE;
  square.setAttribute("width", markerSize);
  square.setAttribute("height", markerSize);
  square.object3D.rotation.order = "YXZ";
  square.object3D.rotation.set(-Math.PI / 2, 0, 0);
  if (color === DETECTED_MARKER_COLOR) {
    square.object3D.position.y = -0.002;
  }
  square.setAttribute(
    "material",
    `color: ${color}; side: double; transparent: true; opacity: 0.5`
  );
  if (color !== DETECTED_MARKER_COLOR) {
    // sphere above marker
    const sphere = document.createElement("a-sphere");
    marker.appendChild(sphere);
    const radius = 0.01;
    sphere.setAttribute("radius", radius);
    sphere.setAttribute(
      "material",
      `color: ${color}; side: double; transparent: true; opacity: 0.5`
    );
    sphere.object3D.position.set(0, 0, -markerSize / 2 - radius);
  }
  const markerIdEl = document.createElement("a-text");
  markerIdEl.setAttribute("font", "dist/custom-msdf.json");
  markerIdEl.setAttribute("negate", "false");
  marker.appendChild(markerIdEl);
  if (addClasses) marker.classList.add("editInfo");
  markerIdEl.setAttribute("id", "markerId");
  markerIdEl.setAttribute("position", "0.1 0 -0.02");
  markerIdEl.setAttribute("rotation", "-90 0 0");
  markerIdEl.setAttribute("value", textValue);
  return marker;
};

export default createMarker;
