// @flow strict
import React, { Component } from "react";
import { Button, TextField } from "../material";

type Props = {
  email: ?string,
  loginAccount: (event: SyntheticEvent<>) => void,
  onChangeEmail: (value: string) => void,
  onChangePassword: (value: string) => void,
  password: string
};

export default class LoginForm extends Component<Props> {
  render() {
    const {
      email,
      loginAccount,
      onChangeEmail,
      onChangePassword,
      password
    } = this.props;
    return (
      <form onSubmit={loginAccount}>
        <TextField
          label="Enter your email"
          helperText={
            email && email.length === 0 ? "This field is required" : null
          }
          type="email"
          required
          onChange={(event: SyntheticInputEvent<HTMLInputElement>) => {
            const value = event.currentTarget.value;
            onChangeEmail(value);
          }}
          value={email || ""}
        />
        <TextField
          label="Enter your password"
          type="password"
          required
          onChange={(event: SyntheticInputEvent<HTMLInputElement>) => {
            const value = event.currentTarget.value;
            onChangePassword(value);
          }}
          value={password}
        />
        <Button
          variant="contained"
          key="button"
          style={{ marginTop: "1em" }}
          className={`login`}
          type="submit"
        >
          Log in
        </Button>
      </form>
    );
  }
}
