// @flow strict
import { createTheme } from "./material";

export const BLUE = "#049ff1";
export const PATH_COLOR = BLUE;
export const PATH_WIDTH = 0.6;
export const PATH_OPACITY = 0.5;
export const PURPLE = "#ff09cc";
export const ORANGE = "orange";
export const GREEN = "green";
export const GREEN_CURSOR = "#4CAF50";
export const RED_CURSOR = "#F44336";

const theme = createTheme({
  typography: {
    useNextVariants: true
  },
  palette: {
    primary: {
      light: "#757ce8",
      main: BLUE,
      // main: "#3f50b5",
      dark: "#002884",
      contrastText: "#fff"
    },
    secondary: {
      light: "#ff7961",
      main: "#f44336",
      dark: "#ba000d",
      contrastText: "#000"
    }
  }
});

export default theme;
