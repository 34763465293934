/* global THREE, AFRAME */
import { BLUE } from "../theme";
var randomColors = [BLUE];
// var randomColors = ["red", "orange", /* 'yellow', */ "green", "blue", "violet"];

AFRAME.registerComponent("follow-intersection", {
  schema: {
    follow: { default: false }
  },
  init: function() {
    this.el.addEventListener("raycaster-intersected", evt => {
      this.intersectingRaycaster = evt.detail.el.components.raycaster;
    });
    this.el.addEventListener("raycaster-intersected-cleared", () => {
      this.intersectingRaycaster = null;
    });
    this.cursor = document.getElementById("cursor");
  },
  tick: function() {
    if (!this.intersectingRaycaster) {
      return;
    }
    if (!this.data.follow) {
      return;
    }
    const intersection = this.intersectingRaycaster.getIntersection(this.el);
    if (intersection) {
      this.cursor.setAttribute("position", intersection.point);
    }
  }
});

export function raycasterNeedsUpdate() {
  var raycaster = document.getElementById("raycaster").components.raycaster;
  raycaster.setDirty();
}

export function modifyRaycasterObjects(
  editorMode: boolean,
  onlyPlanes: boolean = false
) {
  const raycasterEl = document.getElementById("raycaster");
  if (editorMode) {
    if (onlyPlanes) {
      // .step is a subset of .element
      // allow to add a step through a door
      raycasterEl.setAttribute(
        "raycaster",
        "objects:.plane,.step;interval:100"
      );
    } else {
      // .element includes .step, doors, zones
      raycasterEl.setAttribute(
        "raycaster",
        "objects:.plane,.element;interval:100"
      );
    }
  } else {
    if (onlyPlanes) {
      // can't interact with elements until they are visible
      raycasterEl.setAttribute("raycaster", "objects:.plane;interval:100");
    } else {
      raycasterEl.setAttribute(
        "raycaster",
        "objects:.plane,.interactiveElement;interval:100"
      );
    }
  }
  raycasterNeedsUpdate();
}

var tempMat4 = new THREE.Matrix4();
var tempScale = new THREE.Vector3();

export function onAddedOrUpdatedPlanes(evt) {
  var sc = AFRAME.scenes[0];
  for (const anchor of evt.detail.anchors) {
    if (anchor.alignment === 1) {
      // 0 is horizontal plane
      // 1 is vertical plane
      return;
    }
    var created = false;
    var colorToUse;
    var plane = document.getElementById("plane_" + anchor.identifier);
    if (!plane) {
      // Create and append the plane.
      created = true;
      colorToUse =
        randomColors[Math.floor(Math.random() * randomColors.length)];
      plane = document.createElement("a-entity");
      plane.setAttribute("id", "plane_" + anchor.identifier);
      plane.setAttribute("class", "plane");
      // The default is to not be visible for newly created plane, will be visible only if raycaster is on it.
      plane.object3D.visible = false;

      plane.setAttribute(
        "material",
        "shader:grid;interval:0.1;side:double;opacity:0.5;color:" + colorToUse
      );

      sc.appendChild(plane);
      /*
      plane.insertAdjacentHTML(
        "beforeend",

        // Add a plane label (which needs to be rotated to match a-box).
        '<a-entity class="label" rotation="-90 0 0"></a-entity>'

        // Add bounding box.
        // + '<a-box class="bbox" position="0 0 0" height="0" material="wireframe:true;opacity:0.5;color:' + colorToUse + '"></a-box>'
        // Add a thing to mark the center of the plane.
        //      +  '<a-entity thing></a-entity>'
      );
      */
      // Create the temp objects we will use when updating.
      plane.tempPosition = new THREE.Vector3();
      plane.tempQuaternion = new THREE.Quaternion();
      plane.tempEuler = new THREE.Euler(0, 0, 0, "YXZ");
      plane.tempRotation = new THREE.Vector3();
    } else {
      colorToUse = plane.getAttribute("material", "color");
    }

    // Update the plane.
    var dx = anchor.extent[0];
    var dz = anchor.extent[1];
    tempMat4.fromArray(anchor.modelMatrix);
    tempMat4.decompose(plane.tempPosition, plane.tempQuaternion, tempScale);
    plane.tempEuler.setFromQuaternion(plane.tempQuaternion);
    plane.tempRotation.set(
      plane.tempEuler.x,
      plane.tempEuler.y,
      plane.tempEuler.z
    );
    plane.object3D.position.copy(plane.tempPosition);
    plane.object3D.rotation.copy(plane.tempRotation);
    // Currently, scale is always 1...
    // plane.setAttribute('scale', evt.detail.scale);

    // If we have vertices, use polygon geometry
    if (anchor.vertices) {
      plane.setAttribute("geometry", {
        primitive: "polygon",
        vertices: anchor.vertices.join(",")
      });
    } else {
      plane.setAttribute(
        "geometry",
        "primitive:box; width:" + dx + "; height:0.001; depth:" + dz
      );
    }

    // Update the bounding box.
    /*
    var bbox = plane.querySelector('.bbox');
    bbox.setAttribute('width', dx);
    bbox.setAttribute('depth', dz);
*/
    /*
    // Fill out the plane label with informative text.
    // DETAIL: when creating, getAttribute doesn't work this tick
    plane.querySelector(".label").setAttribute("text", {
      width: dx,
      height: dz,
      color: "gray",
      align: "left",
      zOffset: 0.01,
      wrapCount: 100,
      value:
        //   'id: ' + anchor.identifier
        // + '\nwidth: ' + dx
        // + '\ndepth: ' + dz
        // + '\nposition x: ' + plane.tempPosition.x
        "\nposition y: " + plane.tempPosition.y
      // + '\nposition z: ' + plane.tempPosition.z
      // + '\nrotation x: ' + plane.tempRotation.x
      // + '\nrotation y: ' + plane.tempRotation.y
      // + '\nrotation z: ' + plane.tempRotation.z
      // Currently, scale is always 1...
      //+ '\nscale x: ' + plane.getAttribute('scale').x
      //+ '\nscale y: ' + plane.getAttribute('scale').y
      //+ '\nscale z: ' + plane.getAttribute('scale').z
    });
*/
    // We updated the plane (or added it), so update the raycaster.
    // Because there may be a DOM change, we need to wait a tick.
    if (created) {
      setTimeout(raycasterNeedsUpdate);
    } else {
      raycasterNeedsUpdate();
    }
  }
}

export function onRemovedPlanes(evt) {
  for (const anchor of evt.detail.anchors) {
    var plane = document.getElementById("plane_" + anchor.identifier);
    if (plane && plane.parentElement) {
      plane.parentElement.removeChild(plane);
    }
  }
}

export function addPlaneListeners() {
  var sc = AFRAME.scenes[0];
  // Listen for plane events that aframe-ar generates.
  sc.addEventListener("anchorsadded", onAddedOrUpdatedPlanes);
  sc.addEventListener("anchorsupdated", onAddedOrUpdatedPlanes);
  sc.addEventListener("anchorsremoved", onRemovedPlanes);
}
