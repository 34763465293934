// @flow strict
import isEqual from "lodash/isEqual";

export const createLineString = (
  coordinates: Array<GeoJsonPoint>,
  recordId: string,
  sharedData: StepSharedData
): PathRecord => {
  const record = {
    id: recordId,
    type: "path",
    accessible: sharedData.accessible,
    geometry: {
      type: "LineString",
      coordinates: coordinates
    }
  };
  return record;
};

export const toRecords = (
  points: PointsMap,
  selectedCollection: ?CollectionId
) => {
  // transform points to records
  // points contains the steps in the right order
  const records: Array<Record> = [];
  const linestrings: { [string]: Points } = {};
  const linestringRecords: { [string]: ?Record } = {};
  const linestringSharedData: { [string]: StepSharedData } = {};

  for (const point of points.values()) {
    if (
      selectedCollection &&
      point.record &&
      point.record.collectionId !== selectedCollection
    ) {
      // don't modify elements from another collection
      continue;
    }
    if (point.type === "step") {
      if (!(point.recordId in linestrings)) {
        linestrings[point.recordId] = [];
        linestringSharedData[point.recordId] = point.sharedData;
      }
      linestrings[point.recordId].push(point);
      linestringRecords[point.recordId] = point.record;
    } else {
      const record = {
        rotation: point.rotation,
        text: point.text,
        type: point.type,
        id: point.recordId,
        params: point.params,
        attachment: point.attachment,
        geometry: {
          type: "Point",
          coordinates: [point.position.x, point.position.z, point.position.y]
        }
      };
      if (!record.params) {
        delete record.params;
      }
      if (!record.attachment) {
        delete record.attachment;
      }
      if (record.id.startsWith("-")) {
        delete record.id;
      } else {
        // Set original geometry object so that kintojs deepEqual works when comparing records.
        if (point.record && isEqual(point.record.geometry, record.geometry)) {
          // $FlowFixMe
          record.geometry = point.record.geometry;
        }
      }
      // $FlowFixMe complains about record.type "step"
      records.push(record);
    }
  }
  for (const recordId in linestrings) {
    const points = linestrings[recordId];
    const coordinates = points.map(point => [
      point.position.x,
      point.position.z,
      point.position.y
    ]);
    const record = createLineString(
      coordinates,
      recordId,
      linestringSharedData[recordId]
    );
    if (recordId.startsWith("-")) {
      delete record.id;
    } else {
      // Set original geometry object so that kintojs deepEqual works when comparing records.
      if (
        linestringRecords[recordId] &&
        isEqual(linestringRecords[recordId].geometry, record.geometry)
      ) {
        // $FlowFixMe
        record.geometry = linestringRecords[recordId].geometry;
      }
    }
    records.push(record);
  }
  return records;
};
