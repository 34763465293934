// @flow strict
import { DEFAULT_PARAMS } from "../constants";
import { PURPLE } from "../theme";
import createZone from "./createZone";

export const createElevator = (
  position: XYZ,
  rotation: XYZ,
  id: string,
  textValue: string,
  params: ?Params,
  addClasses: boolean = true,
  color: string = PURPLE
): AframeHTMLElement => {
  const poi = createZone(
    position,
    rotation,
    id,
    textValue,
    params,
    addClasses,
    color
  );
  const width = (params && params.width) || DEFAULT_PARAMS.elevator.width;
  const depth = (params && params.depth) || DEFAULT_PARAMS.elevator.depth;
  const height = 2.5;
  const borderWidth = 0.04;
  const halfDepth = depth / 2;
  const halfHeight = height / 2;
  const box = document.createElement("a-box");
  box.setAttribute("position", `0 ${halfHeight} -${halfDepth}`);
  box.setAttribute("height", height);
  box.setAttribute("depth", depth - 2 * borderWidth);
  box.setAttribute("width", width - 2 * borderWidth);
  box.setAttribute(
    "material",
    `color: #eee; transparent: true; opacity: 0.2; shader: flat;`
  );
  poi.appendChild(box);
  return poi;
};

export default createElevator;
