// @flow strict
import { Matrix4 } from "super-three";
const relocateContainer = (function() {
  const mtxA = new Matrix4();
  const transformMtx = new Matrix4();
  return function(
    container: Object3D,
    detectedMarkerMatrix: Matrix4,
    localMarkerMatrixWorld: ?Matrix4
  ) {
    container.matrixAutoUpdate = false;
    const savePositionY = container.position.y;
    if (!localMarkerMatrixWorld) {
      // detected marker position x, z and the floor y, marker rotation y become the container origin
      container.matrix.copy(detectedMarkerMatrix);
    } else {
      // https://stackoverflow.com/a/47961368
      // We want the transform that move localMarker (A) to detectedMarker (B)
      // and apply it to the container.
      // T = B dot inv(A)
      mtxA.getInverse(localMarkerMatrixWorld);
      transformMtx.copy(detectedMarkerMatrix);
      transformMtx.multiply(mtxA);
      container.applyMatrix(transformMtx);
    }
    container.rotation.order = "YXZ";
    container.matrix.decompose(
      container.position,
      container.quaternion,
      container.scale
    );
    container.matrixAutoUpdate = true;
    if (!localMarkerMatrixWorld) {
      container.position.y = savePositionY;
    }
    container.rotation.x = 0;
    container.rotation.z = 0;
    container.updateMatrixWorld(true);
  };
})();

export default relocateContainer;
