// @flow strict
import { getHueLightState } from "../hue";

const createGltf = (
  position: XYZ,
  rotation: XYZ,
  id: string,
  textValue: string,
  params: ?Params,
  addClasses: boolean = true
): AframeHTMLElement => {
  let poi;
  if (params && params.hue) {
    poi = document.createElement("a-box");
    poi.setAttribute("height", "3");
    poi.setAttribute("width", "1");
    poi.setAttribute("depth", "1");
    poi.setAttribute("color", "white");
    poi.setAttribute("opacity", 0.2);
    // $FlowFixMe
    getHueLightState(params.hue).then(state =>
      state.on
        ? poi.setAttribute("color", "yellow")
        : poi.setAttribute("color", "white")
    );
  } else {
    poi = document.createElement("a-entity");
  }
  poi.object3D.position.set(position.x, position.y, position.z);
  poi.object3D.rotation.order = "YXZ";
  poi.object3D.rotation.set(rotation.x, rotation.y, rotation.z);
  poi.setAttribute("id", id);

  const obj = document.createElement("a-entity");
  if (params && params.url) {
    obj.setAttribute("gltf-model", params.url);
  }
  poi.appendChild(obj);

  if (addClasses) poi.classList.add("editInfo");
  if (addClasses) poi.classList.add("interactiveElement");
  if (addClasses) poi.classList.add("element");
  return poi;
};

export default createGltf;
