// @flow strict
import React, { PureComponent } from "react";
import {
  Avatar,
  Button,
  Checkbox,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  SwipeableDrawer,
  withStyles
} from "../material";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import HomeIcon from "@material-ui/icons/Home";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

const styles = (theme: Theme) => ({
  activeButton: {
    backgroundColor: "#ccc"
  },
  nested: {
    paddingLeft: theme.spacing.unit * 4
  }
});

type Props = {
  classes: Classes,
  online: boolean,
  open: boolean,
  onClose: () => void,
  onOpen: () => void,
  profilePicture: ?string,
  profileName: ?string,
  editorMode: boolean,
  isPersonalBucket: boolean,
  onSwitchMode: () => void,
  onLogout: () => void,
  downloadQRcodes: () => void,
  downloadingQRcodes: boolean,
  selectedBucketPublished: null | boolean,
  publishBucket: () => void,
  unpublishBucket: () => void,
  user: KintoUserObject,
  buckets: Array<Bucket>,
  collections: { [BucketId]: Collections },
  canAccessEditorMode: () => boolean,
  onSelectBucket: (bucketId: string) => void,
  onToggleCollectionVisibility: (collectionId: CollectionId) => void,
  selectedBucket: string,
  selectedCollection: string,
  loadRecords: (collectionId: CollectionId) => void
};

class Menu extends PureComponent<Props> {
  render() {
    const {
      collections,
      classes,
      online,
      open,
      onClose,
      onOpen,
      profilePicture,
      profileName,
      editorMode,
      isPersonalBucket,
      onSwitchMode,
      onLogout,
      downloadQRcodes,
      downloadingQRcodes,
      selectedBucketPublished,
      publishBucket,
      unpublishBucket,
      user,
      buckets,
      canAccessEditorMode,
      onSelectBucket,
      selectedBucket,
      selectedCollection,
      onToggleCollectionVisibility,
      loadRecords
    } = this.props;
    let selectedBucketTitle;
    const selectedBucketObj = buckets.find(
      bucket => selectedBucket === bucket.id
    );
    if (selectedBucketObj) {
      selectedBucketTitle = selectedBucketObj.title || selectedBucketObj.id;
    }
    return (
      <SwipeableDrawer open={open} onClose={onClose} onOpen={onOpen}>
        <List>
          {profilePicture ? (
            <ListItem>
              <Avatar src={profilePicture} />
              <ListItemText>{profileName}</ListItemText>
            </ListItem>
          ) : null}
          {user.id && user.id.startsWith("account") ? (
            <ListItem>
              <AccountCircleIcon />
              <ListItemText>{user.id.slice(8).split("@")[0]}</ListItemText>
            </ListItem>
          ) : null}
          <ListItem>
            <Button variant="contained" onClick={onLogout}>
              Log out
            </Button>
          </ListItem>
        </List>
        {canAccessEditorMode() ? (
          <React.Fragment>
            <Divider />
            <List>
              <ListItem button onClick={onSwitchMode}>
                <ListItemText primary="Editor mode" />
                <Checkbox checked={editorMode} />
              </ListItem>
              {!isPersonalBucket &&
              selectedBucketTitle &&
              selectedCollection ? (
                <React.Fragment>
                  <ListItem>
                    <Button
                      disabled={downloadingQRcodes}
                      variant="contained"
                      onClick={downloadQRcodes}
                    >
                      {downloadingQRcodes ? (
                        <React.Fragment>
                          Downloading QR codes for<br /> {selectedBucketTitle}...
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          Download QR codes for<br /> {selectedBucketTitle}
                        </React.Fragment>
                      )}
                    </Button>
                  </ListItem>
                  {selectedBucketPublished !== null ? (
                    <React.Fragment>
                      {selectedBucketPublished ? (
                        <ListItem>
                          <Button variant="contained" onClick={unpublishBucket}>
                            Unpublish venue
                          </Button>
                        </ListItem>
                      ) : (
                        <ListItem>
                          <Button
                            variant="contained"
                            onClick={publishBucket}
                            title="You need to publish the venue so the visitors can scan the QR codes."
                          >
                            Publish venue
                          </Button>
                        </ListItem>
                      )}
                    </React.Fragment>
                  ) : null}
                </React.Fragment>
              ) : null}
            </List>
          </React.Fragment>
        ) : null}
        <Divider />
        <List subheader={<ListSubheader component="div">Venues</ListSubheader>}>
          {buckets.map(bucket => (
            <React.Fragment key={bucket.id}>
              <ListItem
                disabled={!online}
                button
                onClick={() => onSelectBucket(bucket.id)}
                // onClick={
                //   selectedBucket !== bucket.id
                //     ? () => onSelectBucket(bucket.id)
                //     : null
                // }
                className={
                  selectedBucket === bucket.id ? classes.activeButton : null
                }
              >
                <HomeIcon />
                <ListItemText>{bucket.title || bucket.id}</ListItemText>
              </ListItem>
              {collections[bucket.id] &&
              selectedBucket === bucket.id &&
              selectedBucket !== user.bucket ? (
                <List component="div" disablePadding>
                  {collections[bucket.id].map(collection => (
                    <ListItem
                      value={collection.id}
                      key={collection.id}
                      button
                      className={
                        selectedCollection === collection.id
                          ? [classes.nested, classes.activeButton]
                          : classes.nested
                      }
                      onClick={() => {
                        onClose();
                        loadRecords(collection.id);
                      }}
                    >
                      <ListItemText>
                        {collection.title || collection.id}
                      </ListItemText>
                      {collection.id === selectedCollection ? (
                        ""
                      ) : collection.visible ? (
                        <VisibilityIcon
                          onClick={e => {
                            e.stopPropagation();
                            onToggleCollectionVisibility(collection.id);
                          }}
                        />
                      ) : (
                        <VisibilityOffIcon
                          onClick={e => {
                            e.stopPropagation();
                            onToggleCollectionVisibility(collection.id);
                          }}
                        />
                      )}
                    </ListItem>
                  ))}
                </List>
              ) : null}
            </React.Fragment>
          ))}
        </List>
      </SwipeableDrawer>
    );
  }
}

export default withStyles(styles)(Menu);
