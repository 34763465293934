/* global THREE */
// @flow strict
import emit from "./emit";
import { PATH_COLOR, PATH_WIDTH, PATH_OPACITY, PURPLE } from "../theme";
import { calcDistance } from "./utils";
import { raycasterNeedsUpdate } from "./planes";
import { UTILITIES } from "../state";
var randomColors = ["#126a98", "#1c4175", "#4381d5"];

const tempMatrix = new THREE.Matrix4();
const tempVector = new THREE.Vector3();

let colorIndex = 0;

export function createStep(
  pos: XYZ,
  ringColor: string = PURPLE
): { point: AframeHTMLElement, step: AframeHTMLElement } {
  const point = document.createElement("a-ring");
  point.setAttribute("radius-inner", "0.0001");
  point.setAttribute("radius-outer", PATH_WIDTH / 2);
  point.setAttribute("rotation", "-90 0 0");
  point.setAttribute(
    "material",
    `color: ${ringColor}; shader: flat; side: double; opacity: ${PATH_OPACITY}`
  );
  point.setAttribute("position", "0 0.006 0");

  const step = document.createElement("a-entity");
  step.setAttribute("position", pos);
  step.appendChild(point);
  return { point, step };
}

function createSegment(
  path: AframeHTMLElement,
  startPoint: Point,
  endPoint: Point,
  emitEvents: boolean = true
) {
  const start = startPoint.position;
  const end = endPoint.position;
  const ringColor = PATH_COLOR;
  const { point, step } = createStep(end, ringColor);
  step.setAttribute("id", endPoint.uuid);
  step.setAttribute("visible", false);
  path.appendChild(step);

  if (emitEvents) {
    const detail = {
      ...endPoint
    };
    emit("point-added", detail);
  }

  point.classList.add("element");
  point.classList.add("step");
  point.classList.add("endOfPath");
  step.classList.add("editInfo");

  if (start === end) {
    // if this is the start point, don't create plane
    setTimeout(() => {
      if (endPoint.forkFrom) {
        step.setAttribute("visible", false);
        point.classList.remove("element");
      } else {
        step.setAttribute("visible", true);
      }
      raycasterNeedsUpdate();
    });
    return;
  }

  const startEl = document.getElementById(startPoint.uuid);
  if (startEl) {
    const startRing = startEl.querySelector("a-ring");
    if (startRing) {
      startRing.classList.remove("endOfPath");
      setTimeout(() => {
        raycasterNeedsUpdate();
        const startEl = document.getElementById(startPoint.uuid);
        if (!startEl) {
          return;
        }
        const target = startEl.object3D.getWorldPosition(tempVector);
        step.object3D.lookAt(target);
        step.setAttribute("visible", true);
      });
    }
  }

  let color;
  if (UTILITIES.selectedDestination) {
    color = PATH_COLOR;
    const arrowShadow = document.createElement("a-entity");
    arrowShadow.setAttribute("rotation", "-90 0 0");
    arrowShadow.setAttribute(
      "geometry",
      `primitive:plane; height:${PATH_WIDTH * 0.9}; width:${PATH_WIDTH * 0.9}`
    );
    arrowShadow.setAttribute(
      "material",
      "src: #arrowShadowPNG; alpha-test: 0.1; transparent: true; shader: flat"
    );
    arrowShadow.setAttribute("position", "-0.01 0.007 0.02");

    const arrow = document.createElement("a-entity");
    arrow.setAttribute("rotation", "-90 0 0");
    arrow.setAttribute(
      "geometry",
      `primitive:plane; height:${PATH_WIDTH * 0.9}; width:${PATH_WIDTH * 0.9}`
    );
    arrow.setAttribute(
      "material",
      "src: #arrowPurplePNG; alpha-test: 0.1; transparent: true; shader: flat"
    );
    arrow.setAttribute("position", "0 0.1 0");
    step.appendChild(arrowShadow);
    step.appendChild(arrow);
  } else {
    // $FlowFixMe
    color = endPoint.sharedData.color;
    if (!color) {
      // $FlowFixMe
      if (!endPoint.sharedData.accessible) {
        color = "orange";
      } else {
        colorIndex = (colorIndex + 1) % randomColors.length;
        color = randomColors[colorIndex];
      }
      // $FlowFixMe
      endPoint.sharedData.color = color;
    }
  }

  const plane = document.createElement("a-plane");
  step.appendChild(plane);
  const dist = calcDistance(start, end);
  // const diff = end.y - start.y;
  // if (diff > 0.2) {
  //   color = "yellow";
  // } else if (diff < -0.2) {
  //   color = "blue";
  // }

  plane.setAttribute(
    "material",
    `color: ${color}; shader: flat; side: double; opacity: ${PATH_OPACITY}`
  );
  plane.setAttribute("width", dist);
  plane.setAttribute("height", PATH_WIDTH);
  plane.setAttribute("visible", false);
  setTimeout(() => {
    plane.object3D.matrixAutoUpdate = false;
    plane.object3D.applyMatrix(tempMatrix.makeTranslation(-dist / 2, 0, 0)); // for a-plane
    plane.object3D.applyMatrix(
      tempMatrix.makeRotationFromEuler(
        new THREE.Euler(-Math.PI / 2, 0, Math.PI / 2)
      )
    );
    plane.setAttribute("visible", true);
  });
}

export default createSegment;
