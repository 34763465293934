// @flow strict
import React, { PureComponent } from "react";
import { Button, Typography, withStyles } from "../material";
import { getModalStyle } from "./Prompt";
import { getHueLightState, setHueLightState } from "../hue";

// https://stackoverflow.com/a/1500501
const urlRegex = /(https?:\/\/[^\s]+)/g;
function urlify(text: string, newTab: boolean = true): string {
  // WebXR Viewer doesn't support opening new tab
  return text.replace(
    urlRegex,
    `<a href="$1"${newTab ? ' target="_blank"' : ""}>$1</a>`
  );
}

type Props = {
  activeRealityType: RealityTypes,
  open: boolean,
  classes: Classes,
  point: Point
};

export const styles = (theme: Theme) => ({
  paper: {
    backgroundColor: "white",
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 2,
    top: theme.spacing.unit * 8,
    zIndex: 999
  }
});

class InfoModal extends PureComponent<Props> {
  getState = () => {
    const { point } = this.props;
    if (point && point.type === "gltf" && point.params && point.params.hue) {
      const bulb = document.getElementById(point.uuid);
      if (bulb) {
        getHueLightState(point.params.hue).then(state =>
          state.on
            ? bulb.setAttribute("color", "yellow")
            : bulb.setAttribute("color", "white")
        );
      }
    }
  };

  componentDidMount() {
    this.getState();
  }
  componentDidUpdate(prevProps) {
    if (prevProps.point !== this.props.point) {
      this.getState();
    }
  }

  render() {
    const { activeRealityType, classes, open, point } = this.props;
    if (!point) {
      return null;
    }
    let description;
    if (point.params) {
      description = point.params.description ? point.params.description : "";
      if (description) {
        // $FlowFixMe
        description = description.replace(/\n/g, "<br/>");
        description = urlify(description, activeRealityType !== "ar");
      }
    }
    return (
      <div style={!open ? { display: "none" } : undefined}>
        <div style={getModalStyle()} className={classes.paper}>
          <Typography variant="h6" id="modal-title">
            {point.text ? point.text : ""}
          </Typography>

          {description ? (
            <Typography dangerouslySetInnerHTML={{ __html: description }} />
          ) : null}
          <div
            style={{
              marginTop: "10px",
              textAlign: "right"
            }}
          >
            {point.type === "gltf" && point.params && point.params.buttons
              ? point.params.buttons.map((button, idx) => (
                  <Button
                    key={idx}
                    variant="contained"
                    style={{ marginRight: "3em" }}
                    color="primary"
                    onClick={() => {
                      const obj = document.getElementById(point.uuid);
                      if (point.params.hue) {
                        if (obj.getAttribute("color") === "yellow") {
                          obj.setAttribute("color", "white");
                          setHueLightState({ on: false }, point.params.hue);
                        } else {
                          obj.setAttribute("color", "yellow");
                          setHueLightState({ on: true }, point.params.hue);
                        }
                      }
                    }}
                  >
                    {button.label}
                  </Button>
                ))
              : null}
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(InfoModal);
