// @flow strict
import { UTILITIES } from "../state";

const createASA = (
  position: XYZ,
  rotation: XYZ,
  id: string,
  textValue: string,
  params: ?Params,
  addClasses: boolean = true
): AframeHTMLElement => {
  const poi = document.createElement("a-entity");
  poi.object3D.position.set(position.x, position.y, position.z);
  poi.object3D.rotation.order = "YXZ";
  poi.object3D.rotation.set(rotation.x, rotation.y, rotation.z);
  poi.setAttribute("id", id);
  poi.setAttribute("visible", UTILITIES.editorMode);

  const obj = document.createElement("a-entity");
  poi.appendChild(obj);

  const origin = document.createElement("a-entity");
  origin.setAttribute("thing", "");
  origin.setAttribute("material", "color:red");
  poi.appendChild(origin);
  // if (addClasses) origin.classList.add("element");
  // if (addClasses) poi.classList.add("editInfo");
  // if (addClasses) poi.classList.add("asa");
  return poi;
};

export default createASA;
