// @flow strict
import emit from "./emit";
import { getPointFromUuid } from "./utils";
import { raycasterNeedsUpdate } from "./planes";
import { UTILITIES } from "../state";

const deleteObject = (element: AframeHTMLElement) => {
  const point = getPointFromUuid(element.id);
  if (!point) {
    return;
  }
  if (point.type === "step") {
    if (!element.querySelector(".endOfPath")) {
      console.error(
        "Can't delete a step in the middle of a linestring, not implemented"
      );
      return;
    }
    UTILITIES.prevPoint = point.prevPoint;
    if (point.prevPoint) {
      const prevStep = document.getElementById(point.prevPoint.uuid);
      if (prevStep) {
        const prevRing = prevStep.querySelector("a-ring");
        if (prevRing) {
          prevRing.classList.add("endOfPath");
          setTimeout(raycasterNeedsUpdate);
        }
      }
    }
  }

  emit("point-deleted", point);
  element.parentNode && element.parentNode.removeChild(element);

  const photo = document.getElementById("photo_" + element.id);
  photo && photo.parentNode && photo.parentNode.removeChild(photo);

  // if we deleted the second step of a fork, delete the first point as well
  if (point.type === "step" && point.prevPoint && point.prevPoint.forkFrom) {
    const pointElToDelete = document.getElementById(point.prevPoint.uuid);
    pointElToDelete && deleteObject(pointElToDelete);
  }
};
export default deleteObject;
