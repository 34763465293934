// @flow strict
import React, { PureComponent } from "react";
import { Fab, withStyles } from "../material";

const styles = theme => ({
  absolute: {
    position: "absolute",
    bottom: theme.spacing.unit * 20,
    display: "flex",
    justifyContent: "center",
    zIndex: 500,
    width: "100%"
  }
});

type Props = {
  classes: Classes,
  startMarkerDetection: () => void
};

class ScanMarkerButton extends PureComponent<Props> {
  render() {
    const { classes, startMarkerDetection } = this.props;
    return (
      <div className={classes.absolute}>
        <Fab color="primary" variant="extended" onClick={startMarkerDetection}>
          Scan marker
        </Fab>
      </div>
    );
  }
}

export default withStyles(styles)(ScanMarkerButton);
