// @flow strict
import * as React from "react";

import logo from "../images/logo.png";

type Props = { children: React.Node };

export default class PageContainer extends React.Component<Props> {
  render() {
    const { children } = this.props;
    return (
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundImage: "linear-gradient(#e0e0e0, #616161)",
          zIndex: 500,
          display: "flex",
          flex: 1,
          flexDirection: "column",
          justifyContent: "flex-start",
          padding: "30px 15% 0 15%"
        }}
      >
        <img
          src={logo}
          width="250"
          height="79"
          alt="Logo"
          style={{ margin: "0 auto" }}
        />
        <div
          style={{
            flexDirection: "column",
            display: "flex",
            paddingTop: "6em",
            margin: "0 auto"
          }}
        >
          <div style={{ marginTop: "1em" }}>{children}</div>
        </div>
      </div>
    );
  }
}
