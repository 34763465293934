import Kinto from "kinto";

import { KINTO_URL } from "./constants";

export const kinto = new Kinto({
  remote: KINTO_URL,
  adapterOptions: {
    migrateOldData: true
  }
});

export const getAttachmentUrl = (attachment: Attachment) => {
  if (attachment) {
    return "files/" + attachment.location;
  }
  return null;
};
