// @flow strict
import React, { Component } from "react";
import { Button, Modal, TextField, Typography, withStyles } from "../material";

type Props = {
  onClose: (value: string, cancelled?: boolean) => void,
  defaultValue: string,
  message: string,
  classes: Classes
};

type State = {
  value: string
};

export function getModalStyle() {
  return {
    position: "absolute",
    right: "10%",
    width: "80%",
    maxWidth: "40em",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "space-between"
  };
}

const styles = (theme: Theme) => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 2,
    top: theme.spacing.unit * 8
  }
});

class Prompt extends Component<Props, State> {
  state = { value: "" };

  componentDidMount() {
    this.setState(() => ({ value: this.props.defaultValue }));
  }
  render() {
    const { classes, message, onClose } = this.props;
    return (
      <Modal
        open={true}
        aria-labelledby="modal-title"
        onClose={() => onClose("", true)}
      >
        <div style={getModalStyle()} className={classes.paper}>
          <Typography variant="h6" id="modal-title">
            {message}
          </Typography>

          <TextField
            type="text"
            autoFocus
            onChange={(event: SyntheticInputEvent<HTMLInputElement>) => {
              const value = event.currentTarget.value;
              this.setState(() => ({
                value: value
              }));
            }}
            value={this.state.value}
          />
          <div style={{ marginTop: "10px", textAlign: "right" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => onClose(this.state.value)}
            >
              OK
            </Button>
          </div>
        </div>
      </Modal>
    );
  }
}

export default withStyles(styles)(Prompt);
