// @flow strict
import { Matrix4 } from "super-three";

const updateMarkerMatrix = (function() {
  const tempMatrix = new Matrix4();
  return function(
    marker: Object3D,
    matrixWorldMarker: Matrix4,
    container: Object3D
  ) {
    marker.matrixAutoUpdate = false;
    marker.matrix.copy(matrixWorldMarker);

    // thanks to https://aframe.io/docs/0.8.0/introduction/developing-with-threejs.html#world-to-local-transforms
    const worldToLocal = tempMatrix.getInverse(container.matrixWorld);
    // marker.matrix needs to be up to date before applyMatrix
    marker.applyMatrix(worldToLocal);

    marker.matrix.decompose(marker.position, marker.quaternion, marker.scale);
    marker.matrixAutoUpdate = true;
    marker.updateMatrixWorld(true); // will call updateMatrix because matrixAutoUpdate is true
  };
})();

// round to Math.PI / 16 = 11.25 degrees, but special case for Math.PI / 2 and 0
export const sharpAngle = (n: number) => {
  // vertical marker
  // 1.57
  if (n > Math.PI / 2 - Math.PI / 16 && n < Math.PI / 2 + Math.PI / 16) {
    return Math.PI / 2;
  }

  // vertical marker upside down
  // -1.57
  if (n > -Math.PI / 2 - Math.PI / 16 && n < -Math.PI / 2 + Math.PI / 16) {
    return -Math.PI / 2;
  }

  // horizontal marker
  if (n > -Math.PI / 16 && n < Math.PI / 16) {
    return 0;
  }

  return Math.round(n / (Math.PI / 16)) * (Math.PI / 16);
};

export const isVerticalOrHorizontal = (x: number) => {
  const xAngle = Math.round(sharpAngle(x) * 1000) / 1000;
  return xAngle === 0 || xAngle === 1.571;
};

export default updateMarkerMatrix;
