/* global AFRAME, THREE */
// @flow strict
import React, { Component } from "react";
import throttle from "lodash/throttle";
import {
  AppBar,
  Button,
  IconButton,
  FormControlLabel,
  MenuIcon,
  MenuItem,
  Select,
  Snackbar,
  Switch,
  Toolbar,
  Typography,
  withStyles
} from "./material";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import UndoIcon from "@material-ui/icons/Undo";

import { kinto } from "./utils";
import DestinationButton from "./components/DestinationButton";
import Cursor from "./components/Cursor";
import PhantomStep from "./components/PhantomStep";
import ActionsBar from "./components/ActionsBar";
import Prompt from "./components/Prompt";
import InfoModal from "./components/InfoModal";
import EditModal from "./components/EditModal";
import VerticalSlider from "./components/VerticalSlider";
import ScanMarkerButton from "./components/ScanMarkerButton";

import { UTILITIES } from "./state";
import authClient from "./login";
import {
  QRCODEGEN_URL,
  DEFAULT_PARAMS,
  DEFAULT_Y,
  DETECTED_MARKER_COLOR,
  SAVE_BASIC_HEADER,
  emptyObject
} from "./constants";
import pathFinder from "./pathFinder";
import Menu from "./components/Menu";
import Login from "./pages/Login";
import { fromRecords } from "./load";
import { toRecords, createLineString } from "./save";
import {
  addPoint,
  deletePoint,
  loadPoints,
  savePoints,
  updatePoint,
  getDestinations
} from "./reducers";

import "./scene/look-at-camera";
import {
  getPointFromUuid,
  undoStep,
  roundTo3Decimals,
  showHUD
} from "./scene/utils";
import emit from "./scene/emit";
import { drawScene } from "./scene";
import createObject, { CREATE_FUNCTIONS } from "./scene/createObject";
import createMarker from "./scene/createMarker";
import { createStep } from "./scene/createSegment";
import {
  modifyRaycasterObjects,
  onAddedOrUpdatedPlanes,
  onRemovedPlanes
} from "./scene/planes";
import relocateContainer from "./scene/relocateContainer";
import updateMarkerMatrix, {
  sharpAngle,
  isVerticalOrHorizontal
} from "./scene/updateMarkerMatrix";
// import AxesHelper from "./scene/axesHelper";

const DEFAULT_BUCKET = "default";
export const DEFAULT_BUCKET_TITLE = "Sand box";

const XYZ000 = { x: 0, y: 0, z: 0 };

const styles = {
  root: {
    flexGrow: 1
  },
  flex: {
    flex: 1
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20
  },
  toolbar: { backgroundColor: "#eee" },
  loadacollection: { marginRight: "15px" },
  negativeMarginLeft: { marginLeft: "-12px" },
  buttonMargin: { marginRight: 8 }
};

const INITIAL_STATE = {
  loadRecordsFromDBCache: [],
  loadRecordsFromDBCacheKey: "",
  editorMode: false,
  // reset editor state
  currentAction: null,
  subtype: null,
  currentParams: null,
  saved: true,
  snappingEnabled: true,
  userPlaneEnabled: true,
  // reset loaded path
  selectedCollection: null,
  floorFound: false,
  originMarkerFound: false,
  points: new Map(),
  deletedPointIds: [],
  destinations: [],
  selectedDestination: null,
  destinationPath: null,
  searchAccessibleToWheelchair: false,
  searchPreferStairsOverElevator: false,
  searchPathFinderNeedsUpdate: false
};

const savedUser = localStorage.getItem("user");
const INITIAL_USER_STATE = {
  // authenticated user
  profileName: null, // OpenId Connect
  profilePicture: null, // OpenId Connect
  user: savedUser
    ? JSON.parse(savedUser)
    : { id: null, bucket: DEFAULT_BUCKET, principals: [] }, // kinto info about authenticated user
  buckets: JSON.parse(localStorage.getItem("buckets") || "[]"), // buckets the user has access
  collections: JSON.parse(localStorage.getItem("collections") || "{}"), // key is bucketId, value is an array of collections
  permissions: JSON.parse(localStorage.getItem("permissions") || "{}"),
  selectedBucket: localStorage.getItem("selectedBucket") || DEFAULT_BUCKET,
  selectedBucketPermissions: null,
  selectedBucketPublished: null,
  selectedCollectionPermissions: null,
  selectedCollectionPublished: null
};

type Props = {
  classes: Classes,
  enqueueSnackbar: (
    message: string,
    ?{ variant: "success" | "error" | "warning" | "info" }
  ) => void
};

export type State = {
  cvReady: boolean,
  loadRecordsFromDBCache: Array<Record>,
  loadRecordsFromDBCacheKey: string,
  activeRealityType: RealityTypes,
  editorMode: boolean,
  currentAction: ?EditorAction,
  subtype: ?DoorEnum,
  currentParams: ?Params,
  saved: boolean,
  snappingEnabled: boolean,
  userPlaneEnabled: boolean,
  markerDetectionEnabled: boolean,
  floorFound: boolean,
  originMarkerFound: boolean,
  points: PointsMap,
  deletedPointIds: Array<string>,
  destinations: Destinations,
  selectedDestination: ?string,
  destinationPath: ?PathRecord,
  drawerOpen: boolean,
  online: boolean,
  modalOpen: boolean,
  editModalOpen: boolean,
  modalData: ?Point,
  modalCallback: (point: Point, emitEvent?: boolean) => void,
  promptOpen: boolean,
  promptMessage: string,
  promptDefaultValue: string,
  promptCallback: (value: string) => void,
  snackbarMessage: string,
  authenticated: boolean,
  providers: Array<Provider>,
  authenticationType: AuthenticationType,
  email: ?string,
  venueNumber: string,
  error: string,
  password: string,
  profileName: ?string,
  profilePicture: ?string,
  user: KintoUserObject,
  buckets: Buckets,
  collections: { [BucketId]: Collections },
  permissions: AllPermissions,
  selectedBucket: string,
  selectedBucketPermissions: null | {
    read?: Array<string>,
    write?: Array<string>
  },
  selectedBucketPublished: null | boolean,
  selectedCollectionPermissions: null | {
    read?: Array<string>,
    write?: Array<string>
  },
  selectedCollectionPublished: null | boolean,
  selectedCollection: ?string,
  searchAccessibleToWheelchair: boolean,
  searchPreferStairsOverElevator: boolean,
  searchPathFinderNeedsUpdate: boolean,
  downloadingQRcodes: boolean
};

class App extends Component<Props, State> {
  state = {
    ...INITIAL_STATE,
    cvReady: false,
    markerDetectionEnabled: false,
    activeRealityType: "magicWindow",
    // ui state
    drawerOpen: false,
    online: navigator.onLine,
    modalOpen: false,
    editModalOpen: false,
    modalData: null,
    modalCallback: (point, emitEvent) => {},
    promptOpen: false,
    promptMessage: "",
    promptDefaultValue: "",
    promptCallback: value => {},
    snackbarMessage: "",
    authenticated:
      (localStorage.getItem("authenticated") || "false") === "true",
    // login screen
    providers: [], // kinto providers for authentication
    authenticationType: "account",
    email: localStorage.getItem("email"),
    venueNumber: localStorage.getItem("venueNumber") || "",
    password: "",
    error: "",
    downloadingQRcodes: false,
    ...INITIAL_USER_STATE
  };

  componentDidUpdate(prevProps, prevState) {
    // const sc = document.getElementsByTagName("a-scene")[0];
    // if (
    //   this.state.authenticated &&
    //   sc.systems.xr.supportAR &&
    //   this.state.activeRealityType !== "ar"
    // ) {
    //   sc.enterAR.bind(sc)();
    // }

    UTILITIES.activeRealityType = this.state.activeRealityType;
    UTILITIES.points = this.state.points;
    UTILITIES.currentAction = this.state.currentAction;
    UTILITIES.currentParams = this.state.currentParams;
    UTILITIES.editorMode = this.state.editorMode;
    UTILITIES.email = this.state.email;
    UTILITIES.prompt = this.prompt;
    UTILITIES.showInfoModal = this.showInfoModal;
    UTILITIES.closeInfoModal = this.closeInfoModal;
    UTILITIES.showEditModal = this.showEditModal;
    UTILITIES.selectedDestination = this.state.selectedDestination;
    UTILITIES.selectedCollection = this.state.selectedCollection;
    UTILITIES.floorFound = this.state.floorFound;
    UTILITIES.originMarkerFound = this.state.originMarkerFound;
    UTILITIES.snappingEnabled = this.state.snappingEnabled;
    UTILITIES.canWriteOnSelectedCollection = this.canWriteOnSelectedCollection();
    const {
      currentAction,
      selectedDestination,
      selectedCollection,
      editorMode,
      originMarkerFound,
      searchPathFinderNeedsUpdate
    } = this.state;
    const path = document.getElementById("path");
    if (!originMarkerFound) {
      path.setAttribute("visible", "true"); // If immersive-ar visible should be false
    } else {
      path.setAttribute("visible", "true");
    }

    if (editorMode !== prevState.editorMode) {
      // const cursor = document.getElementById("cursor");
      // const cameraCursor = document.getElementById("cameraCursor");
      if (!editorMode) {
        // when we switch from editor to viewer mode, hide planes
        // $FlowFixMe
        const planes = document.querySelectorAll(".plane,.blueprint");
        for (let i = 0; i < planes.length; i++) {
          planes[i].setAttribute("visible", "false");
        }
        if (selectedCollection && this.state.saved) {
          // redraw without steps (only if we saved)
          this._loadRecords(selectedCollection, editorMode, false);
        }
        // disable all cursors
        // cursor.setAttribute("visible", false);
        // cameraCursor.setAttribute("visible", false);
      } else {
        const blueprints = document.querySelectorAll(".blueprint");
        for (let i = 0; i < blueprints.length; i++) {
          blueprints[i].setAttribute("visible", "true");
        }
        // reload the records from indexedDB and set back the local state (if we previously set a destination)
        // redraw all segments with different colors
        if (selectedCollection) {
          this._loadRecords(selectedCollection, editorMode, false);
        }
        this.createOrUpdatePlane();
        // show red cursor and don't show the green one until we found a plane
        // cursor.setAttribute("visible", false);
        // cameraCursor.setAttribute("visible", true);
      }
    }

    if (
      editorMode !== prevState.editorMode ||
      currentAction !== prevState.currentAction ||
      originMarkerFound !== prevState.originMarkerFound
    ) {
      if (editorMode) {
        modifyRaycasterObjects(
          editorMode,
          !originMarkerFound || currentAction !== null
        );
      } else {
        modifyRaycasterObjects(editorMode, !originMarkerFound);
      }
    }
    if (
      selectedCollection &&
      (selectedDestination !== prevState.selectedDestination ||
        (!searchPathFinderNeedsUpdate &&
          searchPathFinderNeedsUpdate !==
            prevState.searchPathFinderNeedsUpdate)) // allow to redraw the path if we changed checkboxes but selected same destination
    ) {
      if (selectedDestination) {
        const camera = document.getElementById("camera");
        const path = document.getElementById("path");
        const startLocalVec = new THREE.Vector3();
        startLocalVec.copy(camera.object3D.position);
        path.object3D.worldToLocal(startLocalVec);
        const start = {
          x: startLocalVec.x,
          y: startLocalVec.y - 1.4, // be closer to the ground
          z: startLocalVec.z
        };
        // $FlowFixMe
        const endPos = this.state.destinations.find(
          d => d.value === selectedDestination
        ).position;
        const end = { x: endPos.x, y: endPos.y, z: endPos.z };
        const foundPath = pathFinder.getPath(start, end);
        // for start y, use same y as the first step in foundPath
        const coordinates: Array<GeoJsonPoint> =
          foundPath.length > 0
            ? [[start.x, start.z, foundPath[0][2]]].concat(foundPath)
            : [];
        if (coordinates.length > 1) {
          const lineString = createLineString(coordinates, "-1", {
            accessible: true
          });
          this.setState(
            () => ({ destinationPath: lineString }),
            () => this._loadRecords(selectedCollection, true, false)
          );
        } else if (this.state.destinationPath) {
          // if no path found and we previously had a path, redraw without path
          this.setState(
            () => ({ destinationPath: null }),
            () => this._loadRecords(selectedCollection, true, false)
          );
        }
      } else {
        // reload the records from indexedDB and set back the local state
        // if we removed the destination (set it back to null)
        this._loadRecords(selectedCollection, editorMode, false);
      }
    }
    if (
      this.state.selectedBucket !== prevState.selectedBucket ||
      (this.state.authenticated !== prevState.authenticated &&
        this.state.authenticated)
    ) {
      // if switching project or login again, clear the scene
      this.new(false);
    }

    if (!this.state.floorFound && prevState.floorFound) {
      // If we previously had the plane_aroundUser and we just clicked
      // the new button in editor mode, remove it.
      const planeAroundUser = document.getElementById("plane_aroundUser");
      if (planeAroundUser) {
        planeAroundUser.parentNode &&
          planeAroundUser.parentNode.removeChild(planeAroundUser);
      }
    }

    // save relevant state in localStorage
    if (this.state.selectedBucket !== prevState.selectedBucket) {
      localStorage.setItem("selectedBucket", this.state.selectedBucket);
    }
    if (this.state.buckets !== prevState.buckets) {
      localStorage.setItem("buckets", JSON.stringify(this.state.buckets));
    }
    if (this.state.permissions !== prevState.permissions) {
      localStorage.setItem(
        "permissions",
        JSON.stringify(this.state.permissions)
      );
    }
    if (this.state.collections !== prevState.collections) {
      localStorage.setItem(
        "collections",
        JSON.stringify(this.state.collections)
      );
    }
    if (this.state.authenticated !== prevState.authenticated) {
      localStorage.setItem(
        "authenticated",
        JSON.stringify(this.state.authenticated)
      );
    }
    if (this.state.user !== prevState.user) {
      localStorage.setItem("user", JSON.stringify(this.state.user));
    }
  }

  showInfoModal = (point: Point) => {
    // don't trigger a render if the value has not changed
    if (!this.state.modalOpen || this.state.modalData !== point) {
      this.setState(() => ({
        modalOpen: true,
        modalData: point
      }));
    }
  };

  closeInfoModal = () => {
    // don't trigger a render if the value has not changed
    if (this.state.modalOpen) {
      this.setState(() => ({
        modalOpen: false
        // modalData: null
      }));
    }
  };

  showEditModal = (point: Point, callback) => {
    this.setState(() => ({
      editModalOpen: true,
      modalData: point,
      modalCallback: callback
    }));
  };

  closeEditModal = () => {
    this.setState(() => ({
      editModalOpen: false,
      modalData: null,
      modalCallback: (point, emitEvent) => {}
    }));
  };

  prompt = (message, value, callback) => {
    this.setState(() => ({
      promptOpen: true,
      promptMessage: message,
      promptCallback: callback,
      promptDefaultValue: value || ""
    }));
  };

  closePrompt = (value: string, cancelled = false) => {
    const promptCallback = this.state.promptCallback;
    this.setState(
      () => ({
        promptOpen: false,
        promptMessage: "",
        promptCallback: value => {},
        promptDefaultValue: ""
      }),
      !cancelled ? () => promptCallback(value) : undefined
    );
  };

  selectFirstBucket = () => {
    const { buckets, selectedBucket } = this.state;
    if (buckets.length > 0) {
      const bucketObject = buckets.find(bucket => bucket.id === selectedBucket);
      if (bucketObject) {
        // if we are resyncing, reload the selected bucket
        this.selectBucket(selectedBucket);
      } else {
        // if we logged out and logged in with a different user, be sure to not show a collection the logged in user doesn't have access
        this.setState(() => ({ selectedCollection: null }));
        // select the first bucket
        this.selectBucket(buckets[0].id);
      }
    }
  };

  fetchBuckets = user => {
    const userBucket = user ? user.bucket : this.state.user.bucket;
    if (this.state.online) {
      return kinto.api
        .listBuckets({ sort: "title" })
        .then((res: { data: Buckets }) =>
          this.setState(() => {
            // don't show readonly buckets
            let buckets = res.data.filter(bucket =>
              this.hasPermissionRecursive("write", bucket.id)
            );
            // The default bucket doesn't show up in the list on the first connection.
            // Filter out the default bucket and then put it first with a special title.
            buckets = buckets.filter(bucket => bucket.id !== userBucket);
            if (!this.isAnonymousAccount(user)) {
              // don't add sandbox bucket for an anonymous account
              buckets = [
                { id: userBucket, title: DEFAULT_BUCKET_TITLE },
                ...buckets
              ];
            }

            return { buckets: buckets };
          }, this.selectFirstBucket)
        );
    }
    return Promise.resolve();
  };

  fetchCollections = callback => {
    if (this.state.online) {
      kinto.api
        .bucket(
          this.isPersonalBucket(this.state.selectedBucket)
            ? "default"
            : this.state.selectedBucket
        )
        // to avoid a Forbidden when the default bucket is not created yet
        .listCollections({ sort: "floor" })
        .then((res: { data: Collections }) =>
          this.setState(prevState => {
            // res = {
            //   data: Array
            //   hasNextPage: false
            //   last_modified: "1525088559984"
            //   next: next()
            // }
            return {
              collections: {
                ...prevState.collections,
                [this.state.selectedBucket]: res.data.map(c => ({
                  ...c,
                  visible: true
                }))
              }
            };
          }, callback)
        )
        .catch(err => {
          console.error(err);
        });
    }
  };

  onToggleCollectionVisibility = (
    collectionId: CollectionId,
    updateScene: boolean = true
  ) => {
    const { selectedDestination, editorMode, selectedCollection } = this.state;
    if (!this.state.collections[this.state.selectedBucket]) {
      return;
    }
    this.setState(prevState => {
      return {
        collections: {
          ...prevState.collections,
          [this.state.selectedBucket]: prevState.collections[
            this.state.selectedBucket
          ].map(c => ({
            ...c,
            visible: c.id === collectionId ? !c.visible : c.visible
          }))
        }
      };
    }, updateScene && selectedCollection ? () => this._loadRecords(selectedCollection, !!selectedDestination || editorMode, false) : undefined);
  };

  syncCurrentCollection = () => {
    if (this.state.online) {
      const kintoCollection = kinto.collection(this.state.selectedCollection, {
        bucket: this.state.selectedBucket
      });
      return kintoCollection.sync().catch(err => {
        if (err.message.includes("flushed")) {
          return kintoCollection
            .resetSyncStatus()
            .then(_ => kintoCollection.sync());
        }
        throw err;
      });
      // .then(console.log.bind(console));
    }
    return Promise.resolve();
  };

  syncCollections = () => {
    const collections = this.state.collections[this.state.selectedBucket];
    if (!collections) {
      return Promise.resolve();
    }
    return Promise.all(
      collections.map(collection => {
        const kintoCollection = kinto.collection(collection.id, {
          bucket: this.state.selectedBucket
        });
        return kintoCollection.sync().catch(err => {
          if (err.message.includes("flushed")) {
            return kintoCollection
              .resetSyncStatus()
              .then(_ => kintoCollection.sync());
          }
          throw err;
        });
        // .then(console.log.bind(console));
      })
    ).then(() => {
      // When back online, if we're not currently editing the collection,
      // reload it in the local state
      const { editorMode, saved, selectedCollection } = this.state;
      if (selectedCollection && saved) {
        this.invalidateRecordsCache();
        this._loadRecords(selectedCollection, editorMode);
        this.getCollectionPermissions();
      }
    });
  };

  loginAccount = (event: SyntheticEvent<>) => {
    // set to magicWindow to create virtual environment
    this.realityChanged({ detail: this.state.activeRealityType });
    event.preventDefault();
    if (!this.state.email && !this.state.password) {
      return;
    }
    const email = this.state.email || ""; // not really needed, this is just to make flow happy, we already have a condition at the beginning of the function
    localStorage.setItem("email", email);
    const EmailAndPassword = `${email}:${this.state.password}`;
    const basicb64 = window.btoa(EmailAndPassword);
    if (SAVE_BASIC_HEADER) {
      localStorage.setItem("basicb64", basicb64);
    }
    kinto.api.setHeaders({
      Authorization: `Basic ${basicb64}`
    });
    this.fetchUser().then(user => {
      if (user) {
        // network may be too slow, we need to load now if we already have it in the cache
        this.loadFirstCollection();
        this.fetchPermissions().then(() => this.fetchBuckets(user));
      }
    });
  };

  loginWithVenueNumber = (event: SyntheticEvent<>) => {
    // set to magicWindow to create virtual environment
    this.realityChanged({ detail: this.state.activeRealityType });
    event.preventDefault();
    if (!this.state.venueNumber) {
      return;
    }
    const venueNumber = this.state.venueNumber;
    localStorage.setItem("venueNumber", venueNumber);
    const EmailAndPassword = `${venueNumber}:secret`;
    const basicb64 = window.btoa(EmailAndPassword);
    if (SAVE_BASIC_HEADER) {
      localStorage.setItem("basicb64", basicb64);
    }
    kinto.api.setHeaders({
      Authorization: `Basic ${basicb64}`
    });
    this.fetchUser().then(user => {
      if (user) {
        // network may be too slow, we need to load now if we already have it in the cache
        this.loadFirstCollection();
        this.fetchPermissions().then(() => this.fetchBuckets(user));
      }
    });
  };

  logout = () => {
    if (SAVE_BASIC_HEADER) {
      localStorage.setItem("basicb64", "");
    }
    authClient.logout();
    // remove Authorization header
    kinto.api._headers = {};
    // kinto
    //   .collection(this.state.selectedCollection, {
    //     bucket: this.state.selectedBucket
    //   })
    //   .clear()
    //   .then(
    this.setState(
      () => ({
        ...INITIAL_STATE,
        authenticated: false,
        drawerOpen: false,
        ...INITIAL_USER_STATE
      }),
      this.getProviders.bind(this)
    );
    // );
  };

  async fetchUser() {
    try {
      const res = await kinto.api.fetchUser();
      if (!res) {
        this.setState(() => ({
          snackbarMessage: "Invalid login and password"
        }));
        return;
      }
      this.setState(() => ({
        user: res,
        // selectedBucket: res.bucket,
        authenticated: true,
        password: ""
      }));
      return res;
      // res looks like this:
      // {
      //   bucket: "1eedc420-5c2d-3340-88eb-bca31b890773"
      //   id: "basicauth:ac51ac7ca383eabc4162774c74ad05bc560437f2e959eb9e5e414fa01ced7e8c"
      //   principals: [
      //     "basicauth:ac51ac7ca383eabc4162774c74ad05bc560437f2e959eb9e5e414fa01ced7e8c",
      //     "system.Authenticated",
      //     "system.Everyone"
      //   ]
      // }
    } catch (err) {
      console.error(err);
      this.setState(() => ({ error: "fetchUser request failed." }));
      return;
    }
  }

  isPersonalBucket = bucketId => {
    return this.state.user.bucket === bucketId;
  };

  fetchPermissions = () => {
    return kinto.api
      .listPermissions({
        // fields: "bucket_id,collection_id,resource_name,permissions",  // doesn't show up in the url
        filters: {
          in_resource_name: "bucket,collection"
        }
      })
      .then(({ data }) => {
        this.setState(() => ({
          permissions: data
        }));
      });
  };

  selectBucket = bucketId => {
    if (!this.state.online) {
      // if offline, don't allow changing bucket
      // normally we won't go here because the buttons are disabled
      return;
    }
    this.setState(
      prevState => ({
        selectedBucket: bucketId,
        ...(prevState.selectedBucket !== bucketId
          ? INITIAL_STATE
          : emptyObject),
        drawerOpen: false,
        selectedBucketPermissions: null,
        selectedBucketPublished: null,
        selectedCollectionPermissions: null,
        selectedCollectionPublished: null
      }),
      () => {
        this.fetchCollections(() => {
          this.syncCollections().then(this.loadFirstCollection);
        });
        this.getBucketPermissions();
      }
      // TODO this.syncCollections() but only if not fresh enough?
      // doing the sync each time the user switch bucket is expensive
    );
  };

  isAnonymousAccount = u => {
    // anonymous account when the user id don't include @ (mail)
    const user = u || this.state.user;
    return user.id && !user.id.includes("@");
  };

  loadFirstCollection = () => {
    if (
      this.state.collections[this.state.selectedBucket] &&
      this.state.collections[this.state.selectedBucket].length > 0 &&
      !this.isPersonalBucket(this.state.selectedBucket) &&
      !this.state.selectedCollection
    ) {
      // load the first collection if logged in with a venueNumber
      this.loadRecords(this.state.collections[this.state.selectedBucket][0].id);
    }
  };

  hasPermission = (
    permission: string,
    resource_name: string,
    collectionId: ?CollectionId = null,
    bucketId: ?BucketId = null
  ): boolean => {
    const userId = this.state.user.id;
    if (!userId) {
      // anonymous
      return false;
    }

    let bucketIdToCheck;
    if (bucketId === null) {
      bucketIdToCheck = this.state.selectedBucket;
    } else {
      bucketIdToCheck = bucketId;
    }

    if (this.isPersonalBucket(bucketIdToCheck)) {
      return true;
    }

    for (let i = 0; i < this.state.permissions.length; i++) {
      const resource = this.state.permissions[i];
      if (resource.resource_name !== resource_name) {
        continue;
      }
      if (resource.bucket_id !== bucketIdToCheck) {
        continue;
      }
      if (collectionId && resource.collection_id !== collectionId) {
        continue;
      }
      const permissions = resource.permissions;
      for (let j = 0; j < permissions.length; j++) {
        const p = permissions[j];
        if (p === permission) {
          return true;
        }
      }
      if (
        (resource_name === "collection" && collectionId) ||
        resource_name === "bucket"
      ) {
        // We found the selected bucket or the given collectionId, stop iterating.
        break;
      }
    }
    return false;
  };

  hasPermissionRecursive = (
    permission?: string = "write",
    bucketId?: ?BucketId = null
  ): boolean => {
    if (this.hasPermission(permission, "bucket", null, bucketId)) {
      return true;
    }
    return this.hasPermission(permission, "collection", null, bucketId); // if permission on any collection of the selected bucket
  };

  canAccessEditorMode = (): boolean => {
    return this.hasPermissionRecursive("write");
  };

  canWriteOnSelectedCollection = (): boolean => {
    if (this.hasPermission("write", "bucket")) {
      return true;
    }
    return this.hasPermission(
      "write",
      "collection",
      this.state.selectedCollection
    );
  };

  backOnline = () => {
    this.setState(() => ({ online: true }), this.syncCollections);
  };

  nowOffline = () => {
    this.setState(() => ({ online: false }));
  };

  realityChanged = throttle(
    data => {
      if (data.detail === "ar" && AFRAME.scenes[0].systems.xr.supportAR) {
        const cursor = document.getElementById("raycaster");
        cursor.setAttribute("cursor", "rayOrigin:entity");
        this.setState(() => ({
          activeRealityType: "ar"
        }));
        const environment = document.getElementById("sphereBg");
        if (environment && environment.components.environment) {
          environment.setAttribute("environment", "active:false");
          // AFRAME.scenes[0].removeChild(environment);
        }
        onRemovedPlanes({ detail: { anchors: [{ identifier: "0" }] } });
      } else {
        if (!this.state.authenticated) {
          return;
        }
        const cursor = document.getElementById("raycaster");
        cursor.setAttribute("cursor", "rayOrigin:mouse");
        this.setState(() => ({
          activeRealityType: "magicWindow"
        }));
        const environment = document.getElementById("sphereBg");
        if (environment) {
          environment.setAttribute(
            "environment",
            "preset:default;dressing:none;groundTexture:squares;playArea:50;active:true"
          );
        }
        // default plane for desktop
        onAddedOrUpdatedPlanes({
          detail: {
            anchors: [
              {
                identifier: "0",
                extent: [100, 100],
                modelMatrix: new THREE.Matrix4()
                  .makeTranslation(0, DEFAULT_Y, 0)
                  .toArray()
              }
            ]
          }
        });
      }
    },
    2000,
    { leading: false }
  );

  async getProviders() {
    if (this.state.authenticationType === "account") {
      return;
    }
    // Google authentication doesn't work in webview (WebARonARCore)
    // on WebARonARCore:
    // Mozilla/5.0 (Linux; Android 7.0; SM-G950F Build/NRD90M; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/57.0.2987.5 Mobile Safari/537.36
    // on Chrome:
    // Mozilla/5.0 (Linux; Android 7.0; SM-G950F Build/NRD90M) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/65.0.3325.109 Mobile Safari/537.36
    // if (navigator.userAgent.indexOf("wv") > -1) {
    //   this.setState(() => ({
    //     authenticationType: "account"
    //   }));
    //   return;
    // }

    // Inspect OpenID information from Kinto server.
    let openidCaps;
    try {
      const res = await kinto.api.fetchServerInfo();
      openidCaps = res.capabilities.openid;
      if (!openidCaps) {
        // this.setState(() => ({ error: "OpenID not enabled on server." }));
        return;
      }
    } catch (err) {
      console.error(err);
      this.setState(() => ({ error: "Can't access the kinto server." }));
      return;
    }

    // Add one login button for every provider configured.
    const { providers } = openidCaps;
    this.setState(() => ({ error: "", providers: providers }));

    // Parse location hash for tokens or read from session storage.
    const authResult = authClient.authenticate();
    window.location.hash = "";

    // Show/hide view and enable/disable login buttons.
    this.setState(() => ({ authenticated: !!authResult }));

    if (authResult) {
      // console.log("AuthResult", authResult);
      // const { provider, accessToken, tokenType, idTokenPayload } = authResult;
      const { provider, accessToken, tokenType } = authResult;
      // Set access token for requests to Kinto.
      kinto.api.setHeaders({
        Authorization: `${tokenType} ${accessToken}`
      });
      this.fetchUser().then(user => {
        if (user) {
          this.fetchPermissions().then(() => this.fetchBuckets(user));
          authClient
            .userInfo(kinto.api, provider, accessToken)
            .then(profile => {
              const { name, picture } = profile;
              this.setState(() => ({
                profileName: name,
                profilePicture: picture
              }));
            })
            .catch(err => this.setState(() => ({ error: err })));
        }
      });
    }
  }

  publishBucket = () => {
    (async () => {
      const res = await kinto.api
        .bucket(
          this.isPersonalBucket(this.state.selectedBucket)
            ? "default"
            : this.state.selectedBucket
        )
        .addPermissions({ read: ["system.Everyone"] });
      this.setState(() => ({
        selectedBucketPermissions: res.permissions,
        selectedBucketPublished: true
      }));
    })();
  };

  unpublishBucket = () => {
    (async () => {
      const res = await kinto.api
        .bucket(
          this.isPersonalBucket(this.state.selectedBucket)
            ? "default"
            : this.state.selectedBucket
        )
        .removePermissions({ read: ["system.Everyone"] });
      this.setState(() => ({
        selectedBucketPermissions: res.permissions,
        selectedBucketPublished: false
      }));
    })();
  };

  getBucketPermissions = () => {
    (async () => {
      const permissions = await kinto.api
        .bucket(
          this.isPersonalBucket(this.state.selectedBucket)
            ? "default"
            : this.state.selectedBucket
        )
        .getPermissions();
      this.setState(() => ({
        selectedBucketPermissions: permissions,
        selectedBucketPublished:
          permissions.read && permissions.read.indexOf("system.Everyone") > -1
      }));
    })();
  };

  deleteCollection = () => {
    if (
      !window.confirm(
        "Are you sure you want to permanently delete this collection?"
      )
    ) {
      return;
    }
    (async () => {
      await kinto.api
        .bucket(
          this.isPersonalBucket(this.state.selectedBucket)
            ? "default"
            : this.state.selectedBucket
        )
        .deleteCollection(this.state.selectedCollection);
      // Clear all records for this collection in IndexedDB
      // but I don't know how to remove the collection entry from IndexedDB.
      kinto
        .collection(this.state.selectedCollection, {
          bucket: this.state.selectedBucket
        })
        .clear();
      this.setState(
        () => ({
          selectedCollectionPermissions: null,
          selectedCollectionPublished: null,
          selectedCollection: null
        }),
        () => {
          this.fetchCollections(); // to remove the collection from the list
          this.new(false);
        }
      );
    })();
  };

  publishCollection = () => {
    (async () => {
      const res = await kinto.api
        .bucket(
          this.isPersonalBucket(this.state.selectedBucket)
            ? "default"
            : this.state.selectedBucket
        )
        .collection(this.state.selectedCollection)
        .addPermissions({ read: ["system.Everyone"] });
      this.setState(() => ({
        selectedCollectionPermissions: res.permissions,
        selectedCollectionPublished: true
      }));
    })();
  };

  unpublishCollection = () => {
    (async () => {
      const res = await kinto.api
        .bucket(
          this.isPersonalBucket(this.state.selectedBucket)
            ? "default"
            : this.state.selectedBucket
        )
        .collection(this.state.selectedCollection)
        .removePermissions({ read: ["system.Everyone"] });
      this.setState(() => ({
        selectedCollectionPermissions: res.permissions,
        selectedCollectionPublished: false
      }));
    })();
  };

  getCollectionPermissions = () => {
    (async () => {
      const permissions = await kinto.api
        .bucket(
          this.isPersonalBucket(this.state.selectedBucket)
            ? "default"
            : this.state.selectedBucket
        )
        .collection(this.state.selectedCollection)
        .getPermissions();
      this.setState(() => ({
        selectedCollectionPermissions: permissions,
        selectedCollectionPublished:
          permissions.read && permissions.read.indexOf("system.Everyone") > -1
      }));
    })();
  };

  downloadQRcodes = () => {
    (async () => {
      this.setState(() => ({ downloadingQRcodes: true }));
      const markersByCollection = {};
      for (let r of this.state.loadRecordsFromDBCache) {
        if (r.type === "marker") {
          markersByCollection[r.collectionId] =
            markersByCollection[r.collectionId] || [];
          markersByCollection[r.collectionId].push(r.text);
        }
      }
      const collections = [];
      for (let [key, value] of Object.entries(markersByCollection)) {
        collections.push({
          collectionId: key,
          markers: value
        });
      }
      const body = JSON.stringify({
        host: window.location.host,
        bucketId: this.state.selectedBucket,
        collections: collections
        // collections: [
        //   { collectionId: "FR59Kl_0", markers: ["0", "1", "2"] },
        //   { collectionId: "Ug2sCS44", markers: ["1-0", "1-1"] },
        //   { collectionId: "ZDcldMo0", markers: ["2-0", "2-1"] }
        // ]
      });
      console.log(body);
      const response = await fetch(QRCODEGEN_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: body
      });
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      // $FlowFixMe
      a.href = url;
      // $FlowFixMe
      a.download = "markers.pdf";
      document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click();
      a.remove(); //afterwards we remove the element again
      this.setState(() => ({ downloadingQRcodes: false }));
    })();
  };

  componentDidMount() {
    const sc = document.getElementsByTagName("a-scene")[0];
    if (sc.renderer.xr && sc.renderer.xr.session) {
      this.realityChanged({ detail: sc.systems.xr.activeRealityType });
    } else {
      this.realityChanged({ detail: "magicWindow" });
    }
    sc.addEventListener("realityChanged", this.realityChanged);
    // // for now delete the indexDB at app startup
    // kinto
    //   .collection(this.state.selectedCollection, {
    //     bucket: this.state.selectedBucket
    //   })
    //   .clear();

    const cursor = document.getElementById("cursor");
    const cameraCursor = document.getElementById("cameraCursor");
    // show camera cursor and don't show the floor cursor until we found a plane
    cursor.setAttribute("visible", false);
    cameraCursor.setAttribute("visible", true);

    sc.addEventListener("cvReady", this.cvReady);
    sc.addEventListener("point-added", this.addPoint);
    sc.addEventListener("point-deleted", this.deletePoint);
    sc.addEventListener("point-updated", this.updatePoint);
    sc.addEventListener("path-loaded", this.pathLoaded);
    sc.addEventListener("marker-found", this.markerFound);
    sc.addEventListener("anchor-updated", this.anchorUpdated);
    sc.addEventListener("floor-found", this.floorFound);
    window.addEventListener("online", this.backOnline); // the events don't trigger on WebARonARCore, navigator.onLine is always true
    window.addEventListener("offline", this.nowOffline);
    let stillConnected = this.state.authenticated;
    if (stillConnected) {
      if (this.state.authenticationType === "account") {
        const basicb64 = localStorage.getItem("basicb64");
        if (basicb64) {
          kinto.api.setHeaders({
            Authorization: `Basic ${basicb64}`
          });
        } else {
          this.logout();
          stillConnected = false;
        }
      }
    }
    if (stillConnected) {
      // if we stayed authenticated, load the first collection
      this.loadFirstCollection();
      // resync bucket, permissions, collections, records
      if (this.state.online) {
        this.fetchPermissions().then(() =>
          this.fetchBuckets(this.state.user).catch(err => {
            // HTTP 401 Unauthorized: Missing Authorization Token (Please authenticate yourself to use this endpoint.)
            if (err.message.includes("Unauthorized")) {
              this.logout();
            } else {
              throw err;
            }
          })
        );
      }
    } else {
      this.getProviders.bind(this)();
    }
  }

  componentWillUnmount() {
    const sc = AFRAME.scenes[0];
    sc.removeEventListener("cvReady", this.cvReady);
    sc.removeEventListener("point-added", this.addPoint);
    sc.removeEventListener("point-deleted", this.deletePoint);
    sc.removeEventListener("point-updated", this.updatePoint);
    sc.removeEventListener("path-loaded", this.pathLoaded);
    sc.removeEventListener("marker-found", this.markerFound);
    sc.removeEventListener("anchor-updated", this.anchorUpdated);
    sc.removeEventListener("floor-found", this.floorFound);
  }

  syncSelectedCollection = () => {
    this.syncCurrentCollection().then(() => {
      const { editorMode, selectedCollection } = this.state;
      if (selectedCollection) {
        // We need to reload the records from indexedDB and set back the local state for several reasons:
        // - the collection sync may have loaded updated elements or newly created elements by another person
        // - we need the point.id in the local state for synced points otherwise we have the following bug:
        //   point created, save, undo, save, load another collection, load back the previous collection, the deleted point has not been deleted
        this.invalidateRecordsCache();
        this._loadRecords(selectedCollection, editorMode);
        // to get the publish collection button the first time we created the collection
        this.getCollectionPermissions();
      }
    });
  };

  save = () => {
    let kintoCollection;
    const updateRecords = (kintoCollection, message) => {
      const deletedPointIds = this.state.deletedPointIds;
      const records = toRecords(
        this.state.points,
        this.state.selectedCollection
      );
      return Promise.all([
        ...records.map(record => {
          if (record.id) {
            return kintoCollection.update(record);
          } else {
            return kintoCollection.create(record);
          }
        }),
        ...deletedPointIds.map(pointId => {
          return kintoCollection.delete(pointId);
        })
      ])
        .then(() => {
          this.setState(prevState => ({
            ...savePoints(prevState),
            snackbarMessage: message
          }));
          this.syncSelectedCollection();
        })
        .catch(err => {
          console.error(err);
        });
    };

    if (this.state.selectedCollection) {
      kintoCollection = kinto.collection(this.state.selectedCollection, {
        bucket: this.state.selectedBucket
      });
      updateRecords(kintoCollection, "Path updated");
    } else {
      const callback = newText => {
        let title;
        if (!newText) {
          title = `Path ${this.state.collections[this.state.selectedBucket]
            .length + 1}`;
        } else {
          title = newText;
        }
        // const id = title.toLowerCase().replace(/ /, "_");
        kinto.api
          .bucket(this.state.selectedBucket)
          .createCollection() // auto generate id
          .then(res => {
            // create a new entry in permissions instead of doing this.fetchPermissions();
            this.setState(prevState => ({
              permissions: [
                ...prevState.permissions,
                {
                  resource_name: "collection",
                  bucket_id: this.state.selectedBucket,
                  collection_id: res.data.id,
                  id: res.data.id,
                  uri: `/buckets/${this.state.selectedBucket}/collections/${
                    res.data.id
                  }`,
                  permissions: [
                    "read:attributes",
                    "read",
                    "record:create",
                    "write"
                  ]
                }
              ]
            }));
            kinto.api
              .bucket(
                this.isPersonalBucket(this.state.selectedBucket)
                  ? "default"
                  : this.state.selectedBucket
              ) // needed if default bucket is not created yet, otherwise we get a Forbidden
              .collection(res.data.id)
              .setData({ title: title });
            kintoCollection = kinto.collection(res.data.id, {
              bucket: this.state.selectedBucket
              // Don't use default here because the indexedDB will use default in the db name
              // and you will have trouble when logout and login with another account.
            });
            this.setState(
              prevState => ({
                selectedCollection: res.data.id,
                collections: {
                  ...prevState.collections,
                  [this.state.selectedBucket]: [
                    ...prevState.collections[this.state.selectedBucket],
                    { id: res.data.id, title: title }
                  ]
                }
              }),
              () => updateRecords(kintoCollection, "Path created")
            );
          });
      };
      this.prompt("Enter a title for this collection", "", callback);
    }
  };

  loadRecordsFromDB = (collectionId: CollectionId): Promise<Array<Record>> => {
    const { loadRecordsFromDBCache, loadRecordsFromDBCacheKey } = this.state;
    if (loadRecordsFromDBCacheKey === collectionId) {
      return Promise.resolve(loadRecordsFromDBCache);
    }
    const promises = [];
    let collections;
    const bucketData = this.state.buckets.find(
      bucket => bucket.id === this.state.selectedBucket
    );
    if (bucketData && bucketData.loadAllCollections) {
      // merge all collections if we have loadAllCollections=true in bucket metadata
      collections = this.state.collections[this.state.selectedBucket];
    } else {
      // load only selected collection
      collections = this.state.collections[this.state.selectedBucket].filter(
        col => col.id === collectionId
      );
    }
    collections.forEach(collection => {
      const promise = kinto
        .collection(collection.id, {
          bucket: this.state.selectedBucket
        })
        .list()
        .then(res => {
          const records: Array<Record> = res.data;
          return { collection, records };
        })
        .catch(err => {
          console.error(err);
        });
      promises.push(promise);
    });
    const selectedCollection = collections.find(col => col.id === collectionId);
    const selectedFloor = selectedCollection
      ? selectedCollection.floor || 0
      : 0;
    const DEFAULT_FLOOR_HEIGHT = 2.5; // default minimum floor height
    const heightByFloor = {};
    return Promise.all(promises).then(arrayOfObjects => {
      arrayOfObjects.sort(
        (a, b) =>
          (a.collection.floor || 0) > (b.collection.floor || 0) ? 1 : -1
      ); // sort floor 0, 1, 2
      for (const obj of arrayOfObjects) {
        const { collection, records } = obj;
        const floor: number = collection.floor || 0;
        let floorHeight = heightByFloor[floor] || DEFAULT_FLOOR_HEIGHT;
        for (const r of records) {
          const coordinates = r.geometry.coordinates;
          if (r.geometry.type === "Point") {
          } else if (r.geometry.type === "LineString") {
            for (const xzy of coordinates) {
              // floorHeight should be around 3.627
              if (xzy[2] > floorHeight) {
                floorHeight = xzy[2];
              }
            }
          }
        }
        heightByFloor[floor] = floorHeight;
      }
      const modifiedRecords = [];
      for (const obj of arrayOfObjects) {
        const { collection, records } = obj;
        const floor: number = collection.floor || 0;
        const diffFloor = floor - selectedFloor;
        const floorBelowHeight =
          heightByFloor[floor - 1] || DEFAULT_FLOOR_HEIGHT;
        const floorHeight = heightByFloor[floor] || DEFAULT_FLOOR_HEIGHT;
        const heightOffset =
          diffFloor > 0
            ? diffFloor * floorBelowHeight
            : diffFloor * floorHeight;
        for (const r of records) {
          const coordinates = r.geometry.coordinates;
          if (r.geometry.type === "Point") {
            coordinates[2] += heightOffset;
          } else if (r.geometry.type === "LineString") {
            for (const xzy of coordinates) {
              xzy[2] += heightOffset;
            }
          }
          r.collectionId = collection.id;
          modifiedRecords.push(r);
        }
      }
      this.setState(() => ({
        loadRecordsFromDBCache: modifiedRecords,
        loadRecordsFromDBCacheKey: collectionId
      }));
      return modifiedRecords;
    });
  };

  _loadRecords = (
    collectionId: CollectionId,
    showSteps: boolean = false,
    emitEvent: boolean = true
  ): Promise<Array<Record>> => {
    return this.loadRecordsFromDB(collectionId).then(
      (records: Array<Record>) => {
        let filteredRecords = records;
        const destinationPath = this.state.destinationPath;
        if (destinationPath) {
          filteredRecords = records.filter(r => r.type !== "path");
          filteredRecords.push(destinationPath);
        }
        const createFeatures = emitEvent;
        const { points, features } = fromRecords(
          filteredRecords,
          showSteps,
          createFeatures
        );
        this.drawScene(points, collectionId);
        if (emitEvent) {
          const path = { id: collectionId, points: points }; // TODO get collection title, extends
          const detail = { path: path, features: features };
          emit("path-loaded", detail);
        }
        // don't continue existing path when loading an existing path
        UTILITIES.prevPoint = null;
        return filteredRecords;
      }
    );
  };

  moveCamera = (force: boolean = false) => {
    if (this.state.activeRealityType !== "ar") {
      const camera = document.getElementById("camera").object3D;
      // Reset y to 0 if we moved ourself with the vertical slider.
      camera.position.y = 0;
      if (force || camera.position.z === 0) {
        // Don't put me too close to the origin marker, step back a little,
        // but keep the position when switching floor.
        camera.position.z = 2;
        camera.position.x = -1;
      }
    }
  };

  loadRecords = (collectionId: CollectionId) => {
    this.moveCamera();
    if (collectionId === "0") {
      return;
    }
    this.setState(() => ({
      selectedCollectionPermissions: null,
      selectedCollectionPublished: null
    }));
    this._loadRecords(collectionId, this.state.editorMode)
      .then(() => {
        this.setState(() => ({
          snackbarMessage: "Scene loaded"
        }));
        this.getCollectionPermissions();
      })
      .catch(err => {
        console.error(err);
      });
  };

  invalidateRecordsCache = () => {
    this.setState(() => ({
      loadRecordsFromDBCache: [],
      loadRecordsFromDBCacheKey: ""
    }));
  };

  new = (showMessage = true) => {
    // clear the scene
    this.drawScene([], null);
    showHUD("");
    this.moveCamera(true);
    const detail = { path: { id: null, points: [] } };
    emit("path-loaded", detail);
    createObject(
      "marker",
      document.getElementById("path"),
      // $FlowFixMe
      new THREE.Vector3(0, 1.46, 0),
      // $FlowFixMe
      new THREE.Vector3(Math.PI / 2, 0, 0),
      "0",
      null,
      true,
      `marker0`
    );
    if (showMessage) {
      this.setState(prevState => ({
        snackbarMessage: "Starting from scratch",
        floorFound:
          prevState.activeRealityType === "ar" ? false : prevState.floorFound,
        originMarkerFound:
          prevState.activeRealityType === "ar"
            ? false
            : prevState.originMarkerFound
      }));
    }
  };

  undoStep = () => {
    const undoneSomething = undoStep();
    if (undoneSomething) {
      this.setState(() => ({
        snackbarMessage: "Element undone"
      }));
    }
  };

  pathLoaded = (e: PathLoadedEvent) => {
    const fc = pathFinder.reset(
      e.detail.features,
      this.state.searchAccessibleToWheelchair,
      this.state.searchPreferStairsOverElevator
    );
    const points = e.detail.path.points;
    const destinations = fc.features.length > 0 ? getDestinations(points) : [];
    this.setDestination(null);
    this.setState(prevState => ({
      searchPathFinderNeedsUpdate: false,
      selectedCollection: e.detail.path.id,
      destinations: destinations,
      floorFound:
        prevState.activeRealityType === "ar" &&
        e.detail.path.id !== prevState.selectedCollection
          ? false
          : prevState.floorFound,
      originMarkerFound:
        prevState.activeRealityType === "ar" &&
        e.detail.path.id !== prevState.selectedCollection
          ? false
          : prevState.originMarkerFound
    }));
  };

  drawScene = (points: Points, collectionId: ?CollectionId) => {
    this.setState(prevState => ({
      ...loadPoints(prevState, points)
    }));
    const collectionsVisibility: Map<CollectionId, boolean> = new Map();
    const collections = this.state.collections[this.state.selectedBucket];
    if (collections) {
      for (const collection of collections) {
        if (collectionId) {
          collectionsVisibility.set(collection.id, collection.visible);
        } else {
          // if collectionId is null, don't show any other collection
          collectionsVisibility.set(collection.id, false);
          this.onToggleCollectionVisibility(collection.id, false);
        }
      }
    }
    if (collectionId && !collectionsVisibility.get(collectionId)) {
      collectionsVisibility.set(collectionId, true);
      this.onToggleCollectionVisibility(collectionId, false);
    }

    const filteredPoints = points.filter(p => {
      const r = p.record;
      return (
        r &&
        (!r.collectionId || // path to destination
          (r.collectionId && collectionId === r.collectionId) ||
          (r.collectionId && collectionsVisibility.get(r.collectionId)))
      );
    });
    drawScene(filteredPoints);
    this.createOriginMarker();
  };

  alertNum = 0;
  markerFound = (e: MarkerFoundEvent) => {
    if (!this.state.markerDetectionEnabled) {
      // if we stopped detection and there is still a event, ignore
      return;
    }
    this.alertNum++;
    this.setInfoMessage("marker " + e.detail.markerId + " found", "info");
    // if two markers are detected, only the last one will be handled
    if (!this.state.editorMode) {
      this.stopMarkerDetection();
    }
    this.resetWorldOriginFromMatrix(e.detail.matrix, e.detail.markerId, true);
  };

  anchorUpdated = (e: MarkerFoundEvent) => {
    this.alertNum++;
    this.setInfoMessage(
      "marker anchor " + e.detail.markerId + " updated",
      "info"
    );
    // we go here if marker anchor was updated by ARKit
    this.resetWorldOriginFromMatrix(e.detail.matrix, e.detail.markerId, false);
  };

  floorFound = (e: FloorFoundEvent) => {
    this.setState({ floorFound: true });
    if (this.state.activeRealityType !== "ar") {
      this.setState({ originMarkerFound: true });
    }
    this.createOrUpdatePlane();
  };

  createOriginMarker = () => {
    if (!this.state.editorMode) {
      return;
    }
    // create origin marker
    let path = document.getElementById("path");
    let originMarker = document.getElementById("markerOrigin");
    if (!originMarker) {
      originMarker = createMarker(
        XYZ000,
        XYZ000,
        "markerOrigin",
        "",
        null,
        false,
        "purple"
      );
      // originMarker = document.createElement("a-entity");
      // originMarker.setAttribute("id", "markerOrigin");
      // originMarker.object3D.add(AxesHelper(0.2));
      path.appendChild(originMarker);
    }
  };

  prevDetectedMarkerColor: string = DETECTED_MARKER_COLOR;
  createDetectedMarker = (markerMatrix: Matrix4, opencvUpdate: boolean) => {
    let scene = AFRAME.scenes[0];
    let detectedMarker = document.getElementById("detectedMarker");
    if (!detectedMarker) {
      detectedMarker = createMarker(
        XYZ000,
        XYZ000,
        "detectedMarker",
        "",
        null,
        false,
        DETECTED_MARKER_COLOR
      );
      this.prevDetectedMarkerColor = DETECTED_MARKER_COLOR;
      scene.appendChild(detectedMarker);
    }
    const markerObj = detectedMarker.object3D;
    markerObj.rotation.order = "YXZ";
    markerObj.matrixAutoUpdate = false;
    markerObj.matrix.copy(markerMatrix);
    markerObj.matrix.decompose(
      markerObj.position,
      markerObj.quaternion,
      markerObj.scale
    );
    markerObj.matrixAutoUpdate = true;
    markerObj.rotateX(-Math.PI / 2); // rotateX modify marker.quaternion...
    markerObj.rotateZ(-Math.PI);
    markerObj.rotation.x = sharpAngle(markerObj.rotation.x);
    markerObj.updateMatrixWorld(true);
    // marker horizontal : x=0
    // marker vertical : x=1.57 (PI/2)
    const { editorMode } = this.state;
    if (editorMode && opencvUpdate) {
      if (!isVerticalOrHorizontal(markerObj.rotation.x)) {
        if (this.prevDetectedMarkerColor !== "red") {
          this.prevDetectedMarkerColor = "red";
          detectedMarker
            .querySelector("a-plane")
            // $FlowFixMe
            .setAttribute(
              "material",
              `color: red; side: double; transparent: true; opacity: 0.5`
            );
          if (
            Math.round(sharpAngle(markerObj.rotation.x) * 1000) / 1000 ===
            -1.571
          ) {
            this.setInfoMessage("This marker is upside down.");
          } else {
            this.setInfoMessage("This marker is not vertical or horizontal.");
          }
        }
      } else {
        if (this.prevDetectedMarkerColor !== DETECTED_MARKER_COLOR) {
          this.prevDetectedMarkerColor = DETECTED_MARKER_COLOR;
          detectedMarker
            .querySelector("a-plane")
            // $FlowFixMe
            .setAttribute(
              "material",
              `color: ${DETECTED_MARKER_COLOR}; side: double; transparent: true; opacity: 0.5`
            );
          this.setInfoMessage("");
        }
      }
      // el.innerHTML = `marker rotation: x=${roundTo3Decimals(
      //   markerObj.rotation.x
      // ).toFixed(3)}, y=${roundTo3Decimals(markerObj.rotation.y).toFixed(
      //   3
      // )}, z=${roundTo3Decimals(markerObj.rotation.z).toFixed(3)}`;
    }
    markerObj.visible = opencvUpdate;
    return markerObj;
  };

  hasMarkerInTheScene = () => {
    let markerInTheScene = false;
    for (const p of this.state.points.values()) {
      if (p.type === "marker") {
        markerInTheScene = true;
        break;
      }
    }
    return markerInTheScene;
  };

  useWorldMap = false;
  worldMap: WorldMap | null;
  useWorldMap: boolean;

  createOrUpdatePlane = () => {
    const path = document.getElementById("path");
    const camera = document.getElementById("camera");
    // update regularly plane around user base from camera position
    if (this.state.floorFound && this.state.editorMode) {
      setTimeout(this.createOrUpdatePlane, 200);
      const planeAroundUser = document.getElementById("plane_aroundUser");
      if (this.state.userPlaneEnabled) {
        if (!planeAroundUser) {
          onAddedOrUpdatedPlanes({
            detail: {
              anchors: [
                {
                  identifier: "aroundUser",
                  extent: [5, 5],
                  modelMatrix: new THREE.Matrix4()
                    .makeTranslation(
                      camera.object3D.position.x,
                      path.object3D.position.y + 0.1,
                      camera.object3D.position.z
                    )
                    .toArray()
                }
              ]
            }
          });
        } else {
          planeAroundUser.object3D.position.set(
            camera.object3D.position.x,
            path.object3D.position.y + 0.1,
            camera.object3D.position.z
          );
          planeAroundUser.setAttribute(
            "geometry",
            "width",
            this.state.activeRealityType === "ar" ? 5 : 60
          );
          planeAroundUser.setAttribute(
            "geometry",
            "depth",
            this.state.activeRealityType === "ar" ? 5 : 60
          );
        }
      } else {
        if (planeAroundUser && planeAroundUser.parentNode) {
          planeAroundUser.parentNode.removeChild(planeAroundUser);
        }
      }
    }
  };

  // messageEl: ?AframeHTMLElement;
  // setInfoMessage = throttle((message: string) => {
  //   let el = this.messageEl;
  //   if (!el) {
  //     el = document.getElementById("mapMessageEl2");
  //     this.messageEl = el;
  //   }
  //   if (el) {
  //     el.innerHTML = message;
  //   }
  // }, 200);

  setInfoMessage = (message: string, variant = "warning") => {
    if (message) {
      this.props.enqueueSnackbar(message, { variant: variant });
    }
  };

  resetWorldOriginFromMatrix = (
    tempMatrix: Matrix4,
    markerId: number,
    opencvUpdate: boolean
  ) => {
    const {
      currentAction,
      editorMode,
      floorFound,
      originMarkerFound
    } = this.state;
    const path = document.getElementById("path");
    const path3D = path.object3D;
    const session = AFRAME.scenes[0].renderer.xr.session;
    const markerObj = this.createDetectedMarker(tempMatrix, opencvUpdate);
    if (!isVerticalOrHorizontal(markerObj.rotation.x)) {
      return;
    }
    const markerDomId = `marker${markerId}`; // need the same generated id in load.js for record of type marker
    const markerIdString = String(markerId);
    const markerPoint = getPointFromUuid(markerDomId);

    if (opencvUpdate) {
      if (!editorMode && !markerPoint) {
        this.setInfoMessage(
          "This marker is not known for this venue, switch to editor mode to add it."
        );
        return;
      } else {
        this.setInfoMessage("");
      }
    }

    if (
      (!editorMode && markerPoint) ||
      (editorMode && (!markerPoint && !this.hasMarkerInTheScene())) || // don't reset the origin if the venue has markers and we scanned an unknown marker
      (markerPoint && !opencvUpdate) // allow update via arkit in editor mode, but don't relocalize when we use the marker action
    ) {
      if (
        this.useWorldMap &&
        markerPoint &&
        markerPoint.params &&
        markerPoint.params.worldMap
      ) {
        const worldMap = markerPoint.params.worldMap;
        this.setInfoMessage("try to set worldMap");
        session
          // $FlowFixMe
          .setWorldMap(worldMap)
          .then(val => {
            this.setInfoMessage(
              // $FlowFixMe
              "set worldMap ok, size = " + worldMap.worldMap.length
            );
            const localMarkerMatrix = markerPoint
              ? document.getElementById(markerPoint.uuid).object3D.matrixWorld
              : null;
            relocateContainer(path3D, markerObj.matrix, localMarkerMatrix);
          })
          .catch(err => {
            this.setInfoMessage("Could not set world Map");
            console.error("Could not set world Map", err);
          });
      }
      const localMarkerMatrix = markerPoint
        ? document.getElementById(markerPoint.uuid).object3D.matrixWorld
        : null;
      relocateContainer(path3D, markerObj.matrix, localMarkerMatrix);
      if (!originMarkerFound && markerPoint) {
        this.setState({ originMarkerFound: true });
        emit("floor-found");
      }
      // relocateContainer which update path.object3D.matrixWorld needs to be
      // called before adding the marker below
    }

    if (!floorFound) {
      // Don't create markerPoint until with have a correct height by detecting a first plane or scanning an existing marker
      return;
    }

    if (markerPoint && !originMarkerFound) {
      // Don't update markerPoint when we first scan a marker to relocalize initially.
      // If we wrongly use the marker action to relocalize initially instead of the 'scan marker' button in viewer mode.
      return;
    }

    if (!(editorMode && currentAction === "marker" && opencvUpdate)) {
      return;
    }

    let marker = document.getElementById(markerDomId);
    if (!marker) {
      marker = createMarker(XYZ000, XYZ000, markerDomId, markerIdString);
      path.appendChild(marker);
    }
    const marker3D = marker.object3D;
    updateMarkerMatrix(marker3D, markerObj.matrix, path.object3D);

    let point;
    if (!markerPoint) {
      createObject(
        "marker",
        path,
        {
          x: roundTo3Decimals(marker3D.position.x),
          y: roundTo3Decimals(marker3D.position.y),
          z: roundTo3Decimals(marker3D.position.z)
        },
        {
          x: sharpAngle(marker3D.rotation.x),
          y: marker3D.rotation.y,
          z: 0
        },
        markerIdString,
        null,
        true,
        markerDomId
      );
      point = getPointFromUuid(markerDomId);
    } else {
      point = {
        ...markerPoint,
        position: {
          x: roundTo3Decimals(marker3D.position.x),
          y: roundTo3Decimals(marker3D.position.y),
          z: roundTo3Decimals(marker3D.position.z)
        },
        rotation: {
          x: sharpAngle(marker3D.rotation.x),
          y: marker3D.rotation.y,
          z: 0
        }
      };
      emit("point-updated", point);
    }

    this.stopMarkerDetection();
    this.stopMarkerDetection.flush();
    if (!originMarkerFound) {
      this.setState({ originMarkerFound: true });
      emit("floor-found");
    }

    if (this.useWorldMap) {
      session
        .getWorldMap()
        .then(worldMap => {
          this.worldMap = worldMap;

          // -anchors: [Object]
          //   +0: [Object]
          //   +1: [Object]
          //   +2: [Object]
          //   -3: [Object]
          //     name: anchor-1549220758327-2555479831738421
          //     -transform: [Object]
          //       0: -0.26871418952941895
          //       1: 0.006650874856859446
          //       10: -0.019562674686312675
          //       11: 0
          //       12: -1.2621290683746338
          //       13: -0.014557628892362118
          //       14: -2.9084854125976562
          //       15: 1
          //       2: 0.9631969332695007
          //       3: 0
          //       4: 0.9621492624282837
          //       5: 0.048987437039613724
          //       6: 0.26808372139930725
          //       7: 0
          //       8: -0.045401494950056076
          //       9: 0.9987772107124329
          //       length: 16
          //   +4: [Object]
          //   +5: [Object]
          // -center: [Object]
          //   x: 13.23831558227539
          //   y: -29.847047805786133
          //   z: -30.803115844726562
          // -extent: [Object]
          //   x: 40.783897399902344
          //   y: 61.61555099487305
          //   z: 92.75143432617188
          // featureCount: 3138
          // worldMap: fJx5OFT/G/eHpBKF6otWo

          // console.log("got worldMap, size = ", worldMap.worldMap.length);
          this.setInfoMessage(
            "got worldMap, size = " + worldMap.worldMap.length
          );
          point = { ...point, params: { worldMap: worldMap } };
          emit("point-updated", point);

          // if you have a world map, you can use it with a similar command.
          // to see it in action, uncomment the following code:
          //
          // setTimeout(() => this.session.setWorldMap(worldMap).then(val => {
          //  console.log("set worldMap ok")
          // }).catch(err => {
          //  console.error('Could not set world Map', err)
          // }), 1000);
        })
        .catch(err => {
          console.error("Could not get world Map", err);
          // Cannot get World Map until tracking is fully initialized
          // request to get World Map failed: Error Domain=com.apple.arkit.error Code=400 "Insufficient features."
          //   UserInfo={
          //     NSLocalizedRecoverySuggestion=Make sure that tracking has initialized and continue to scan the environment.,
          //     NSLocalizedDescription=Insufficient features.,
          //     NSLocalizedFailureReason=Not enough features have been recorded to create a world map yet.}
          this.setInfoMessage("Could not get world Map");
          this.worldMap = null;
        });
    }
  };

  showMarkerDetectionBox = () => {
    const textBox = document.querySelector(".text-box");
    if (textBox) {
      textBox.classList.remove("hidden");
    }
  };

  hideMarkerDetectionBox = () => {
    const textBox = document.querySelector(".text-box");
    if (textBox) {
      textBox.classList.add("hidden");
    }
  };

  cvReady = () => {
    if (
      !(
        AFRAME.scenes[0].renderer.xr &&
        AFRAME.scenes[0].renderer.xr.session &&
        typeof AFRAME.scenes[0].renderer.xr.session._display
          ._startVideoFrames !== "undefined"
      )
    ) {
      return;
    }

    this.setState(() => ({ cvReady: true }));
    if (this.state.markerDetectionEnabled) {
      AFRAME.scenes[0].renderer.xr.session.startVideoFrames();
    }
  };

  startMarkerDetection = () => {
    this.stopMarkerDetection.cancel();
    if (this.state.markerDetectionEnabled) {
      return;
    }
    if (
      !(
        AFRAME.scenes[0].renderer.xr &&
        AFRAME.scenes[0].renderer.xr.session &&
        typeof AFRAME.scenes[0].renderer.xr.session._display
          ._startVideoFrames !== "undefined"
      )
    ) {
      return;
    }

    // Chrome on desktop returns a "Cardboard VRDisplay" display when calling navigator.getVRDisplays()
    // in webxr-polyfill/XRPolyfill.js and so session._display is actually
    // HeadMountedDisplay (not having _startVideoFrames) instead of FlatDisplay (having _startVideoFrames))
    // on Firefox AFRAME.scenes[0].renderer.xr.session is null
    if (this.state.cvReady) {
      AFRAME.scenes[0].renderer.xr.session.startVideoFrames();
    }
    this.showMarkerDetectionBox();
    this.setState(() => ({ markerDetectionEnabled: true }));
  };

  stopMarkerDetection = throttle(
    () => {
      if (!this.state.markerDetectionEnabled) {
        return;
      }
      if (
        !(
          AFRAME.scenes[0].renderer.xr &&
          AFRAME.scenes[0].renderer.xr.session &&
          typeof AFRAME.scenes[0].renderer.xr.session._display
            ._stopVideoFrames !== "undefined"
        )
      ) {
        return;
      }
      AFRAME.scenes[0].renderer.xr.session.stopVideoFrames();
      this.hideMarkerDetectionBox();
      this.setState(() => ({ markerDetectionEnabled: false }));
      // setTimeout(() => {
      //   const detectedMarker = document.getElementById("detectedMarker");
      //   if (detectedMarker) {
      //     detectedMarker.parentNode &&
      //       detectedMarker.parentNode.removeChild(detectedMarker);
      //   }
      // }, 5000);
    },
    2000,
    { leading: false }
  );

  addPoint = (e: PointAddedEvent) => {
    const newPoint = e.detail;
    // go back to edit path mode when putting any object that is not a path step, like a door
    this.setState(prevState => ({
      ...addPoint(prevState, newPoint),
      snackbarMessage: "Element positioned",
      currentAction: prevState.currentAction === "editPath" ? "editPath" : null,
      subtype: null
    }));
  };

  deletePoint = (e: PointDeletedEvent) => {
    const pointToDelete = e.detail;
    this.setState(prevState => ({
      ...deletePoint(prevState, pointToDelete),
      snackbarMessage: "Element deleted"
    }));
  };

  updatePoint = (e: PointUpdatedEvent) => {
    const newPoint = e.detail;
    this.setState(prevState => ({
      ...updatePoint(prevState, newPoint),
      snackbarMessage: "Element updated",
      currentAction: prevState.currentAction === "editPath" ? "editPath" : null,
      subtype: null
    }));
  };

  handleToggleDrawer = () =>
    this.setState(oldState => ({
      drawerOpen: !oldState.drawerOpen
    }));

  switchMode = () => {
    this.setDestination(null);
    this.setState(oldState => {
      const newValue = !oldState.editorMode;
      return {
        editorMode: newValue,
        drawerOpen: false
      };
    });
  };

  handleActionClick = (actionId: ?EditorAction, subtype: ?DoorEnum) => {
    // if subtype is null, we clicked on an action
    // don't reset to subtype to null if the previous action clicked is the same we just clicked
    this.setState(oldState => {
      const newSubtype =
        subtype !== null ||
        (subtype === null && oldState.currentAction !== actionId)
          ? subtype
          : oldState.subtype;
      let defaultParams = null;
      if (actionId && actionId !== "editPath" && DEFAULT_PARAMS[actionId]) {
        defaultParams = {
          ...DEFAULT_PARAMS[actionId]
        };
      }
      return {
        currentAction: actionId,
        subtype: newSubtype,
        currentParams:
          actionId === "door"
            ? { subtype: newSubtype || DEFAULT_PARAMS.door.subtype }
            : defaultParams
      };
    });
  };

  setDestination = id => {
    const {
      searchPathFinderNeedsUpdate,
      searchAccessibleToWheelchair,
      searchPreferStairsOverElevator
    } = this.state;
    if (id && searchPathFinderNeedsUpdate) {
      pathFinder.modifyWeights(
        searchAccessibleToWheelchair,
        searchPreferStairsOverElevator
      );
      this.setState(() => ({
        searchPathFinderNeedsUpdate: false
      }));
    }
    this.setState(() => ({
      selectedDestination: id,
      destinationPath: null
    }));
  };

  openDrawer = () => this.setState(() => ({ drawerOpen: false }));

  closeDrawer = () => this.setState(() => ({ drawerOpen: true }));

  onChangeSnapping = () =>
    this.setState(prevState => ({
      snappingEnabled: !prevState.snappingEnabled
    }));

  onChangeUserPlane = () =>
    this.setState(prevState => ({
      userPlaneEnabled: !prevState.userPlaneEnabled
    }));

  setEmptySnackbarMessage = () =>
    this.setState(() => ({ snackbarMessage: "" }));

  onChangeCollection = event => this.loadRecords(event.target.value);

  onChangeAccessibleToWheelchair = (value: boolean) =>
    this.setState(() => ({
      searchAccessibleToWheelchair: value,
      searchPathFinderNeedsUpdate: true
    }));

  onChangePreferStairsOverElevator = (value: boolean) =>
    this.setState(() => ({
      searchPreferStairsOverElevator: value,
      searchPathFinderNeedsUpdate: true
    }));

  render() {
    const {
      activeRealityType,
      authenticated,
      authenticationType,
      buckets,
      collections,
      currentAction,
      currentParams,
      destinations,
      selectedDestination,
      drawerOpen,
      editorMode,
      email,
      venueNumber,
      error,
      floorFound,
      markerDetectionEnabled,
      online,
      originMarkerFound,
      points,
      password,
      profileName,
      profilePicture,
      providers,
      saved,
      selectedBucket,
      selectedCollection,
      selectedCollectionPermissions,
      subtype,
      user,
      searchAccessibleToWheelchair,
      searchPreferStairsOverElevator
    } = this.state;
    const { classes } = this.props;
    const bucketObject = buckets.find(bucket => bucket.id === selectedBucket);
    const prevPoint = UTILITIES.prevPoint;
    const collectionObject = collections[selectedBucket]
      ? collections[selectedBucket].find(
          collection => collection.id === selectedCollection
        )
      : null;

    if (!authenticated) {
      return [
        <Login
          key="loginPage"
          activeRealityType={activeRealityType}
          authenticationType={authenticationType}
          venueNumber={venueNumber}
          email={email}
          password={password}
          error={error}
          loginAccount={this.loginAccount}
          loginWithVenueNumber={this.loginWithVenueNumber}
          onChangeEmail={value =>
            this.setState(() => ({
              email: value
            }))
          }
          onChangeVenueNumber={value =>
            this.setState(() => ({
              venueNumber: value
            }))
          }
          onChangePassword={value => {
            this.setState(() => ({
              password: value
            }));
          }}
          providers={providers}
        />,
        <Snackbar
          key={this.state.snackbarMessage}
          open={!!this.state.snackbarMessage}
          message={this.state.snackbarMessage}
          onClose={this.setEmptySnackbarMessage}
        />
      ];
    }

    const canWriteOnSelectedCollection = this.canWriteOnSelectedCollection();
    const canCreateCollection =
      this.hasPermission("write", "bucket") ||
      this.hasPermission("collection:create", "bucket");
    return (
      <React.Fragment>
        {!editorMode && originMarkerFound && selectedCollection ? (
          <DestinationButton
            destinations={destinations}
            selectedDestination={selectedDestination}
            setDestination={this.setDestination}
            accessibleToWheelchair={searchAccessibleToWheelchair}
            preferStairsOverElevator={searchPreferStairsOverElevator}
            onChangeAccessibleToWheelchair={this.onChangeAccessibleToWheelchair}
            onChangePreferStairsOverElevator={
              this.onChangePreferStairsOverElevator
            }
          />
        ) : null}
        {!markerDetectionEnabled &&
        !editorMode &&
        selectedCollection &&
        activeRealityType === "ar" ? (
          <ScanMarkerButton startMarkerDetection={this.startMarkerDetection} />
        ) : null}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            zIndex: 500
          }}
        >
          <Snackbar
            key={this.state.snackbarMessage}
            open={!!this.state.snackbarMessage}
            message={this.state.snackbarMessage}
            onClose={this.setEmptySnackbarMessage}
          />
          {this.state.promptOpen ? (
            <Prompt
              message={this.state.promptMessage}
              defaultValue={this.state.promptDefaultValue}
              onClose={this.closePrompt}
            />
          ) : null}
          {!this.state.editorMode ? (
            <InfoModal
              open={this.state.modalOpen}
              point={this.state.modalData}
              activeRealityType={activeRealityType}
            />
          ) : null}
          {this.state.editModalOpen && this.state.editorMode ? (
            <EditModal
              selectedBucket={selectedBucket}
              selectedCollection={selectedCollection}
              syncSelectedCollection={this.syncSelectedCollection}
              saved={saved}
              point={this.state.modalData}
              onClose={this.closeEditModal}
              updatePoint={this.state.modalCallback}
              wsUrl={bucketObject ? bucketObject.wsUrl : null}
            />
          ) : null}
          <Menu
            online={online}
            open={drawerOpen}
            onClose={this.openDrawer}
            onOpen={this.closeDrawer}
            profilePicture={profilePicture}
            profileName={profileName}
            editorMode={editorMode}
            onSwitchMode={this.switchMode}
            onLogout={this.logout}
            isPersonalBucket={this.isPersonalBucket(this.state.selectedBucket)}
            downloadQRcodes={this.downloadQRcodes}
            downloadingQRcodes={this.state.downloadingQRcodes}
            selectedBucketPublished={this.state.selectedBucketPublished}
            publishBucket={this.publishBucket}
            unpublishBucket={this.unpublishBucket}
            user={user}
            buckets={buckets}
            canAccessEditorMode={this.canAccessEditorMode}
            onSelectBucket={this.selectBucket}
            selectedBucket={selectedBucket}
            selectedCollection={selectedCollection}
            collections={collections}
            onToggleCollectionVisibility={this.onToggleCollectionVisibility}
            loadRecords={this.loadRecords}
          />
          <AppBar
            position="static"
            style={{
              paddingTop: "calc(env(safe-area-inset-top) / 2)"
            }}
          >
            <Toolbar>
              <IconButton
                className={classes.menuButton}
                color="inherit"
                aria-label="Menu"
                onClick={this.handleToggleDrawer}
              >
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" color="inherit" className={classes.flex}>
                {(bucketObject || emptyObject).title || "Easy Path View"}
                {editorMode
                  ? " - " + ((collectionObject || emptyObject).title || "New")
                  : ""}
              </Typography>
            </Toolbar>
          </AppBar>
          {editorMode ||
          (!editorMode && this.isPersonalBucket(selectedBucket)) ? (
            <Toolbar className={classes.toolbar}>
              {collections[selectedBucket] &&
              collections[selectedBucket].length > 0 &&
              this.isPersonalBucket(selectedBucket) ? (
                <React.Fragment>
                  <Select
                    value={selectedCollection || "0"}
                    onChange={this.onChangeCollection}
                    className={classes.loadacollection}
                  >
                    <MenuItem value="0" key="select">
                      Load a collection
                    </MenuItem>
                    {collections[selectedBucket].map(collection => (
                      <MenuItem value={collection.id} key={collection.id}>
                        {collection.title || collection.id}{" "}
                        {!this.hasPermission(
                          "write",
                          "collection",
                          collection.id
                        )
                          ? "(read only)"
                          : ""}
                      </MenuItem>
                    ))}
                  </Select>
                  {selectedCollection && selectedCollectionPermissions ? (
                    <React.Fragment>
                      <Button
                        style={{ marginLeft: "10px" }}
                        disabled={this.state.downloadingQRcodes}
                        variant="contained"
                        onClick={this.downloadQRcodes}
                      >
                        {this.state.downloadingQRcodes ? (
                          <React.Fragment>
                            Downloading QR codes...
                          </React.Fragment>
                        ) : (
                          <React.Fragment>Download QR codes</React.Fragment>
                        )}
                      </Button>
                      {this.state.selectedCollectionPublished ? (
                        <Button
                          style={{ marginLeft: "10px" }}
                          variant="contained"
                          onClick={this.unpublishCollection}
                        >
                          Unpublish collection
                        </Button>
                      ) : (
                        <Button
                          style={{ marginLeft: "10px" }}
                          variant="contained"
                          onClick={this.publishCollection}
                          title="You need to publish the collection so the visitors can scan the QR codes."
                        >
                          Publish collection
                        </Button>
                      )}
                    </React.Fragment>
                  ) : null}
                </React.Fragment>
              ) : null}
              {editorMode ? (
                <React.Fragment>
                  {selectedCollection &&
                  this.isPersonalBucket(selectedBucket) ? (
                    <Button
                      style={{ marginLeft: "10px" }}
                      variant="contained"
                      onClick={this.deleteCollection}
                      title="Delete permanently this collection."
                    >
                      Delete collection
                    </Button>
                  ) : null}
                  <FormControlLabel
                    style={{ marginLeft: "5px" }}
                    control={
                      <Switch
                        checked={this.state.snappingEnabled}
                        onChange={this.onChangeSnapping}
                        value="snapping"
                        color="primary"
                      />
                    }
                    label="Snap"
                  />
                  {activeRealityType === "ar" ? (
                    <FormControlLabel
                      control={
                        <Switch
                          checked={this.state.userPlaneEnabled}
                          onChange={this.onChangeUserPlane}
                          value="userPlane"
                          color="primary"
                        />
                      }
                      label="User plane"
                    />
                  ) : null}
                </React.Fragment>
              ) : null}
            </Toolbar>
          ) : null}
          {editorMode && canWriteOnSelectedCollection ? (
            <ActionsBar
              isPersonalBucket={this.isPersonalBucket(
                this.state.selectedBucket
              )}
              currentAction={currentAction}
              floorFound={floorFound}
              originMarkerFound={originMarkerFound}
              subtype={subtype}
              onClick={this.handleActionClick}
              startMarkerDetection={this.startMarkerDetection}
              stopMarkerDetection={this.stopMarkerDetection}
            />
          ) : null}
          {!editorMode && !selectedCollection ? (
            <React.Fragment>
              {collections[selectedBucket] &&
              collections[selectedBucket].length > 0 ? (
                <React.Fragment>
                  {this.isPersonalBucket(this.state.selectedBucket) &&
                  this.canAccessEditorMode() ? (
                    <Snackbar
                      key="loadacollection"
                      open
                      message="Load a collection or go in edit mode"
                    />
                  ) : null}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {this.canAccessEditorMode() ? (
                    <Snackbar
                      key="nocollectionavailable"
                      open
                      message="No collection available for this venue. Go create a new collection with the editor mode from menu on the top left."
                    />
                  ) : (
                    <Snackbar
                      key="nocollection"
                      open
                      message="There is no collection available for this venue yet."
                    />
                  )}
                </React.Fragment>
              )}
            </React.Fragment>
          ) : null}
          {!editorMode &&
          selectedCollection &&
          !originMarkerFound &&
          activeRealityType === "ar" ? (
            <Snackbar
              key="touchscanmarker"
              open
              message="Touch the 'Scan marker' button and find a marker to relocalize yourself."
            />
          ) : null}
          {!editorMode &&
          selectedCollection &&
          originMarkerFound &&
          destinations.length > 0 &&
          !selectedDestination ? (
            <Snackbar
              key="touchsearch"
              open
              message="Touch the Search button to select a destination where you want to go."
            />
          ) : null}
          {editorMode && currentAction === "marker" ? (
            <Snackbar
              key="targetamarker"
              open
              message={
                activeRealityType === "ar"
                  ? "Target a marker to add it or update position"
                  : "Touch the ground to add a vertical marker at eye level."
                // : "Touch the ground to add a vertical marker at eye level. Marker 0 will be at the origin, not where you touched."
              }
            />
          ) : null}
          {editorMode &&
          currentAction !== "marker" &&
          !originMarkerFound &&
          activeRealityType === "ar" ? (
            <Snackbar
              key="selectmarkeraction"
              open
              message="Select the marker action on the right and target a marker to start editing"
            />
          ) : null}
          {editorMode ? (
            <Toolbar className={classes.negativeMarginLeft}>
              {canCreateCollection ? (
                <Button
                  variant="contained"
                  onClick={this.new}
                  className={classes.buttonMargin}
                >
                  <DeleteForeverIcon />
                  New
                </Button>
              ) : null}
              {canWriteOnSelectedCollection ? (
                <React.Fragment>
                  <Button
                    variant="contained"
                    disabled={saved}
                    onClick={this.save}
                    className={classes.buttonMargin}
                  >
                    <SaveAltIcon />
                    Save
                  </Button>
                  <Button
                    variant="contained"
                    onClick={this.undoStep}
                    disabled={points.size === 0}
                  >
                    <UndoIcon />
                    Undo
                  </Button>
                </React.Fragment>
              ) : null}
            </Toolbar>
          ) : null}
          {activeRealityType !== "ar" ? (
            <Toolbar className={classes.negativeMarginLeft}>
              <VerticalSlider />
            </Toolbar>
          ) : null}
          {!floorFound ? (
            <React.Fragment>
              {activeRealityType === "ar" && editorMode ? (
                <React.Fragment>
                  <Snackbar
                    key="gobackinviewermode"
                    open
                    message={
                      this.hasMarkerInTheScene()
                        ? "You have markers in this venue. Please go back in viewer mode and first scan a marker before editing."
                        : "Please detect a plane on the floor (reticle should be green) and touch the screen."
                    }
                  />
                </React.Fragment>
              ) : null}
              {activeRealityType !== "ar" ? (
                <Snackbar
                  key="slidetoptobottom"
                  open
                  message="Slide top to bottom and click on the floor"
                />
              ) : null}
            </React.Fragment>
          ) : null}
        </div>
        <PhantomStep>
          {editorMode && currentAction === "editPath" ? (
            <a-entity
              key={`${currentAction}-${JSON.stringify(
                prevPoint ? prevPoint.position : {}
              )}`}
              ref={node => {
                if (node) {
                  node.appendChild(createStep(XYZ000).step);
                }
              }}
            />
          ) : null}
        </PhantomStep>
        <Cursor>
          <a-entity>
            <a-ring
              id="cursorOnPlane"
              color="#F44336"
              radius-inner="0.06"
              radius-outer="0.08"
              rotation="-90 0 0"
            />
            <a-sphere
              id="cursorOnObject"
              color="#4CAF50"
              radius="0.03"
              visible="false"
            />
          </a-entity>
          {editorMode &&
          currentAction &&
          currentAction !== "editPath" &&
          currentAction !== "marker" ? (
            <a-entity
              id="lookAtCamera"
              look-at-camera="#camera"
              key={`${currentAction}-${JSON.stringify(currentParams)}`}
              ref={node => {
                if (node) {
                  node.appendChild(
                    CREATE_FUNCTIONS[currentAction](
                      XYZ000,
                      XYZ000,
                      "newElement",
                      "",
                      currentParams,
                      false
                    )
                  );
                }
              }}
            >
              <a-text
                font="dist/custom-msdf.json"
                negate="false"
                value={currentAction.toUpperCase()}
                position={`${currentAction === "elevator" ? -0.5 : -0.3} 0.2 ${
                  currentAction === "blueprint" ? 0.5 : 0
                }`}
              />
            </a-entity>
          ) : null}
        </Cursor>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(App);
