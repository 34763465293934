// @flow strict
import isEqual from "lodash/isEqual";
import PathFinder from "geojson-path-finder";
import { featureCollection, point } from "@turf/helpers";
import explode from "@turf/explode";
// import nearestPoint from "@turf/nearest-point";
import nearestPoint from "./nearestPoint";

let pathFinder = null;

const getPathFinderOptions = (
  accessible: ?boolean = null,
  stairs: ?boolean = null
) => ({
  precision: 0.00000001, // 10^-8, don't compact graph, avoid having the last two steps the same
  weightFn: function(a, b, props) {
    if (props) {
      if (accessible && !props.accessible) {
        return 999;
      }
      if (!accessible && stairs && props.type === "elevator") {
        return 999;
      }
    }
    var dx = a[0] - b[0];
    var dz = a[1] - b[1];
    var dy = a[2] - b[2];
    const res = Math.sqrt(dx * dx + dz * dz + dy * dy);
    return res;
  }
});

function modifyWeights(accessible: boolean = false, stairs: boolean = false) {
  if (!pathFinder) {
    return;
  }
  const oldPathFinder = pathFinder;
  pathFinder = new PathFinder(
    oldPathFinder.fc,
    getPathFinderOptions(accessible, stairs)
  );
  pathFinder.waypoints = oldPathFinder.waypoints;
  pathFinder.fc = oldPathFinder.fc;
}

const EMPTY_FEATURE_COLLECTION = featureCollection([]);
function reset(
  features: Features,
  accessible: boolean = false,
  stairs: boolean = false
): { features: Features } {
  pathFinder = null;
  if (
    !features ||
    (features.length === 1 && features[0].geometry.coordinates.length === 1)
  ) {
    // We need at least a LineString with two points to create a graph.
    return EMPTY_FEATURE_COLLECTION;
  }
  try {
    const fc = featureCollection(features);
    pathFinder = new PathFinder(fc, getPathFinderOptions(accessible, stairs));
    const waypoints = explode(fc);
    pathFinder.waypoints = waypoints;
    pathFinder.fc = fc;
    return fc;
  } catch (e) {
    console.error(e);
    // Compacted graph contains no forks (topology has no intersections).
    return EMPTY_FEATURE_COLLECTION;
  }
}

function getPath(start: XYZ, end: XYZ): Array<GeoJsonPoint> {
  if (!pathFinder) {
    return [];
  }
  const startPoint = point([start.x, start.z, start.y]);
  const finishPoint = point([end.x, end.z, end.y]);
  const points = pathFinder.waypoints;
  const nearestStart = nearestPoint(startPoint, points);
  const nearestFinish = nearestPoint(finishPoint, points);
  const path = pathFinder.findPath(nearestStart, nearestFinish);
  // console.log(path);
  if (path === null) {
    return [];
  }
  const coordinates = path.path;
  // sometime the last two steps are the same, remove the last one
  if (
    coordinates.length > 1 &&
    isEqual(
      coordinates[coordinates.length - 1],
      coordinates[coordinates.length - 2]
    )
  ) {
    return coordinates.slice(0, coordinates.length - 1);
  }
  return coordinates;
}

const pf = {
  getPath,
  reset,
  modifyWeights
};
export default pf;
