// @flow strict
import * as React from "react";
import ReactDOM from "react-dom";

type Props = {
  children: React.Node
};

export default class Cursor extends React.Component<Props> {
  el: AframeHTMLElement;
  constructor(props: Props) {
    super(props);
    const cursor = document.getElementById("cursor");
    this.el = cursor;
  }

  render() {
    return ReactDOM.createPortal(this.props.children, this.el);
  }
}
