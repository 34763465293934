// @flow strict
import React from "react";

import { createMuiTheme } from "@material-ui/core/styles";
import { withStyles as MUIwithStyles } from "@material-ui/core/styles";
import MUIAppBar from "@material-ui/core/AppBar";
import MUIAvatar from "@material-ui/core/Avatar";
import MUIButton from "@material-ui/core/Button";
import MUIButtonBase from "@material-ui/core/ButtonBase";
import MUICheckbox from "@material-ui/core/Checkbox";
import MUICssBaseline from "@material-ui/core/CssBaseline";
import MUIDivider from "@material-ui/core/Divider";
import MUIFab from "@material-ui/core/Fab";
import MUIFormGroup from "@material-ui/core/FormGroup";
import MUIFormControlLabel from "@material-ui/core/FormControlLabel";
import MUIIconButton from "@material-ui/core/IconButton";
import MUIListSubheader from "@material-ui/core/ListSubheader";
import MUIList from "@material-ui/core/List";
import MUIListItem from "@material-ui/core/ListItem";
import MUIListItemText from "@material-ui/core/ListItemText";
import MUIMenuIcon from "@material-ui/icons/Menu";
import MUIMenuItem from "@material-ui/core/MenuItem";
import MUIModal from "@material-ui/core/Modal";
import MUIPopover from "@material-ui/core/Popover";
import MUISelect from "@material-ui/core/Select";
import MUISnackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import MUITextField from "@material-ui/core/TextField";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import MUIToolbar from "@material-ui/core/Toolbar";
import MUITypography from "@material-ui/core/Typography";
import MUISlider from "@material-ui/lab/Slider";
import MUISwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import MUISwitch from "@material-ui/core/Switch";

import { type SnackbarProps, type ButtonProps } from "./material.type";

export const AppBar = MUIAppBar;
export const Avatar = MUIAvatar;
export const ButtonBase = MUIButtonBase;
export const Checkbox = MUICheckbox;
export const createTheme = createMuiTheme;
export const CssBaseline = MUICssBaseline;
export const Divider = MUIDivider;
export const Fab = MUIFab;
export const FormGroup = MUIFormGroup;
export const FormControlLabel = MUIFormControlLabel;
export const IconButton = MUIIconButton;
export const List = MUIList;
export const ListItem = MUIListItem;
export const ListItemText = MUIListItemText;
export const ListSubheader = MUIListSubheader;
export const MenuIcon = MUIMenuIcon;
export const MenuItem = MUIMenuItem;
export const Modal = MUIModal;
export const Popover = MUIPopover;
export const Select = MUISelect;
export const Slider = MUISlider;
export const SwipeableDrawer = MUISwipeableDrawer;
export const Switch = MUISwitch;
export const TextField = MUITextField;
export const ThemeProvider = MuiThemeProvider;
export const Toolbar = MUIToolbar;
export const Typography = MUITypography;
export const withStyles = MUIwithStyles;

export const Snackbar = ({ open, message, onClose }: SnackbarProps) => (
  <MUISnackbar open={open} onClose={onClose} autoHideDuration={3000}>
    <SnackbarContent
      style={{
        fontSize: "1.2em",
        userSelect: "none"
      }}
      message={message}
    />
  </MUISnackbar>
);

export const Button = ({
  variant,
  style,
  disabled,
  onClick,
  children,
  ...rest
}: ButtonProps) => (
  <MUIButton
    variant={variant}
    style={style}
    disabled={disabled}
    onClick={onClick}
    {...rest}
  >
    {children}
  </MUIButton>
);
