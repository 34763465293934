// @flow strict
import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Button } from "../material";

import logo from "../images/logo.png";
import authClient from "../login";
// import EnterNumberForm from "../components/EnterNumberForm";
import LoginForm from "../components/LoginForm";

type Props = {
  activeRealityType: RealityTypes,
  authenticationType: AuthenticationType,
  email: ?string,
  venueNumber: string,
  error: string,
  loginAccount: (event: SyntheticEvent<>) => void,
  loginWithVenueNumber: (event: SyntheticEvent<>) => void,
  onChangeEmail: (value: string) => void,
  onChangeVenueNumber: (value: string) => void,
  onChangePassword: (value: string) => void,
  password: string,
  providers: Array<Provider>,
  location: { state: ?{ message?: string } }
};

class Login extends Component<Props> {
  render() {
    const {
      activeRealityType,
      authenticationType,
      email,
      // venueNumber,
      error,
      loginAccount,
      // loginWithVenueNumber,
      onChangeEmail,
      // onChangeVenueNumber,
      onChangePassword,
      password,
      providers
    } = this.props;
    return (
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundImage: "linear-gradient(#e0e0e0, #616161)",
          zIndex: 500,
          display: "flex",
          flex: 1,
          flexDirection: "column",
          justifyContent: "space-between",
          padding: "30px 15% 0 15%"
        }}
      >
        <img
          src={logo}
          width="250"
          height="79"
          alt="Logo"
          style={{ margin: "0 auto" }}
        />
        {authenticationType !== "account"
          ? providers.map(provider => {
              const { name } = provider;
              return (
                <Button
                  variant="contained"
                  style={{ marginTop: "20px" }}
                  key={provider.name}
                  className={`login ${name}`}
                  onClick={() => authClient.authorize(provider)}
                >{`Log in with ${name[0].toUpperCase() +
                  name.substr(1)}`}</Button>
              );
            })
          : null}
        {authenticationType === "account" && (
          <React.Fragment>
            <div
              style={{
                flexDirection: "column",
                display: "flex",
                margin: "0 auto",
                width: "15em"
              }}
            >
              {/* <div>
                <EnterNumberForm
                  venueNumber={venueNumber}
                  loginWithVenueNumber={loginWithVenueNumber}
                  onChangeVenueNumber={onChangeVenueNumber}
                />
              </div>
              <div style={{ fontSize: "1.1em", marginTop: "2em" }}>
                Or log in with an account:
              </div> */}
              <div style={{ marginTop: "1em" }}>
                {this.props.location.state
                  ? this.props.location.state.message || ""
                  : ""}
              </div>
              <div style={{ marginTop: "1em" }}>
                <LoginForm
                  email={email}
                  loginAccount={loginAccount}
                  onChangeEmail={onChangeEmail}
                  onChangePassword={onChangePassword}
                  password={password}
                />
              </div>
            </div>
          </React.Fragment>
        )}
        {error && <p>{error}</p>}
        <div style={{ paddingBottom: "40px", color: "#f5f5f5" }}>
          {activeRealityType !== "ar" ? (
            <React.Fragment>
              <p style={{ fontSize: "1.1em", fontWeight: "bold" }}>
                This is the Easy Path View editor for desktop. <br />You may be
                looking for the{" "}
                <a href="/qr" style={{ color: "#cfd8dc" }}>
                  Easy Path View application
                </a>{" "}
                to scan a QR code.
                <br />
                <br />
                Don't have an account?{" "}
                <Link to="/signin" style={{ color: "#cfd8dc" }}>
                  Create one
                </Link>{" "}
                to be able to test the app in your venue.
                {/* You need to open this site with the{" "}
                <a
                  href="https://itunes.apple.com/us/app/webxr-viewer/id1295998056?mt=8"
                  style={{ color: "#cfd8dc" }}
                >
                  WebXR Viewer
                </a>{" "}
                app on iOS.
                <br />
                Devices compatible: iPhone 6s, 7, 8, X, iPad 2017, iPad Pro. */}
              </p>
            </React.Fragment>
          ) : null}
          <p
            style={{
              display: "flex",
              flex: 1,
              justifyContent: "space-between"
            }}
          >
            version 1.9.0
            <a
              style={{ color: "#cfd8dc", textDecoration: "none" }}
              href="https://www.iubenda.com/privacy-policy/81983294"
            >
              Privacy policy
            </a>
            <a
              style={{ color: "#cfd8dc", textDecoration: "none" }}
              href="http://easypathview.com/"
            >
              easypathview.com
            </a>
          </p>
        </div>
      </div>
    );
  }
}

export default withRouter(Login);
