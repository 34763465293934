// @flow strict
import uuid from "uuid";
import { Vector3 } from "super-three";

import emit from "./emit";
import { addPlaneListeners } from "./planes";
import {
  createRandomId,
  getLocalPositionAndRotation,
  getPointFromUuid,
  roundTo3Decimals,
  supportsAR,
  showHUD
} from "./utils";
import { getAttachmentUrl } from "../utils";
import { UTILITIES } from "../state";
import createSegment from "./createSegment";
import createObject from "./createObject";
import deleteObject from "./deleteObject";
import editInfo from "./editInfo";

import { PATH_COLOR, GREEN_CURSOR } from "../theme";
import "./ar";

const prevStepPosition = new Vector3();
const SNAP_DISTANCE = 0.5;

function getContainerNode(el: AframeHTMLElement): AframeHTMLElement {
  let parent = el;
  // $FlowFixMe
  let container: AframeHTMLElement = (parent: any);
  while (!container.getAttribute("id")) {
    container = container.parentNode;
  }
  return container;
}

function addClickToCreatePathListener() {
  const raycaster = document.getElementById("raycaster");
  const path = document.getElementById("path");
  const cursor = document.getElementById("cursor");

  let lastTimeStamp;
  let currentPoint;

  const onClick = (e: MouseEvent) => {
    if (lastTimeStamp && e.timeStamp - lastTimeStamp < 1000) {
      // 1s, there is a lag between the two clicks when creating the photo
      // ignore double click
      return;
    }
    lastTimeStamp = e.timeStamp;
    // const intersectedEl = e.detail.intersectedEl;
    // e.detail.intersectedEl comes from cursor component and is the closest intersected element, not the element checked in raycaster-intersection
    // We use the myIntersectedEl variable set in raycaster-intersection handler for now.
    // const intersectedEls = raycaster.components.raycaster.intersections;  // aframe 0.8.0
    // const idx = getFarthestIntersectionIdx(intersectedEls);
    // const intersectedEl = intersectedEls[idx];
    const intersectedEl = raycaster.components.raycaster.myIntersectedEl;
    const container = getContainerNode(intersectedEl);

    // If floor is not detected yet, take the first detected plane were we have the cursor on,
    if (!UTILITIES.floorFound && intersectedEl.classList.contains("plane")) {
      // showHUD(
      //   `floor found at ${cursor.object3D.position.y} ${intersectedEl.id}`
      // );
      path.object3D.position.y = cursor.object3D.position.y;
      emit("floor-found");
      return;
    } else {
      // if current plane y position has a difference between 0.1 m and 0.2 m, update path container y position
      // const diff = Math.abs(point.y - path.object3D.position.y);
      // if (diff > 0.1 && diff < 0.2) {
      //   path.object3D.position.y = point.y;
      // }
    }

    if (!UTILITIES.editorMode) {
      return;
    }

    const currentAction = UTILITIES.currentAction;
    if (intersectedEl.classList.contains("element")) {
      const point = getPointFromUuid(container.id);
      const sameCollection = !(
        point &&
        point.record &&
        point.record.collectionId !== UTILITIES.selectedCollection
      );
      const canEdit = sameCollection && UTILITIES.canWriteOnSelectedCollection;
      if (!canEdit) {
        return;
      }
      if (!touchEvent || touchEvent.touches.length === 1) {
        if (!currentAction && container.classList.contains("editInfo")) {
          // avoid double dialog
          setTimeout(() => editInfo(container), 200);
        } else if (intersectedEl.classList.contains("step")) {
          const prevPoint = getPointFromUuid(container.id);
          UTILITIES.prevPoint = prevPoint;
          if (prevPoint) {
            // just to make flow happy
            if (!intersectedEl.classList.contains("endOfPath")) {
              // fork the path on next click on plane
              UTILITIES.selectedRecordId = createRandomId();
            } else {
              UTILITIES.selectedRecordId = prevPoint.recordId;
            }
          }
        }
      } else if (touchEvent.touches.length === 2) {
        if (
          !intersectedEl.classList.contains("step") ||
          intersectedEl.classList.contains("endOfPath")
        ) {
          deleteObject(container);
        }
      }
      return;
    }

    if (!UTILITIES.originMarkerFound) {
      // don't allow to add new element until the origin is set
      return;
    }

    if (
      !currentAction ||
      (currentAction === "marker" && UTILITIES.activeRealityType === "ar")
    ) {
      // click on plane does nothing if no action currently selected or if we are in marker mode (those are created from detected marker)
      return;
    }
    const camera = document.getElementById("camera");
    const phantomStep = document.getElementById("phantomStep");
    let prevPoint = UTILITIES.prevPoint;
    const {
      position: localPosition,
      rotation: localRotation
    } = getLocalPositionAndRotation(
      prevPoint ? phantomStep.object3D : cursor.object3D,
      camera.object3D,
      path.object3D
    );

    if (currentAction === "editPath") {
      if (!prevPoint) {
        prevPoint = {
          uuid: uuid.v4(),
          type: "step",
          position: localPosition,
          prevPoint: null,
          recordId: createRandomId(),
          sharedData: { accessible: true }
        };
        UTILITIES.prevPoint = prevPoint;
        UTILITIES.selectedRecordId = prevPoint.recordId;
        createSegment(path, prevPoint, prevPoint);
      } else {
        if (prevPoint.recordId !== UTILITIES.selectedRecordId) {
          // starting a fork, create the start point
          prevPoint = {
            uuid: uuid.v4(),
            forkFrom: prevPoint.uuid,
            type: "step",
            position: prevPoint.position,
            prevPoint: null, // this starts a new LineString
            recordId: UTILITIES.selectedRecordId,
            // $FlowFixMe
            sharedData: { ...prevPoint.sharedData } // do a copy, the fork can change accessibility
          };
          UTILITIES.prevPoint = prevPoint;
          createSegment(path, prevPoint, prevPoint);
        }
        currentPoint = {
          uuid: uuid.v4(),
          type: "step",
          position: localPosition,
          prevPoint: prevPoint,
          recordId: prevPoint.recordId,
          // $FlowFixMe
          sharedData: prevPoint.sharedData
        };
        createSegment(path, prevPoint, currentPoint);
        UTILITIES.prevPoint = currentPoint;
      }
    } else {
      if (currentAction === "marker") {
        localRotation.x = Math.PI / 2;
        localRotation.y = camera.object3D.rotation.y;
        localRotation.z = 0;
        localPosition.y = 1.46;
        let numMarker = 0;
        for (const p of UTILITIES.points.values()) {
          if (p.type === "marker") {
            numMarker++;
          }
        }
        if (numMarker === 0) {
          localRotation.y = 0;
          localPosition.x = 0;
          localPosition.z = 0;
        }
        createObject(
          currentAction,
          path,
          localPosition,
          localRotation,
          String(numMarker),
          null,
          true,
          `marker${numMarker}`
        );
      } else {
        // avoid double dialog
        setTimeout(() => {
          const obj = createObject(
            currentAction,
            path,
            localPosition,
            localRotation,
            null,
            UTILITIES.currentParams
          );
          if (obj && obj.classList.contains("editInfo")) {
            editInfo(obj);
          }
        });
      }
    }
  };

  let touchEvent;
  const keepTouchEvent = (e: TouchEvent) => {
    touchEvent = e;
  };

  window.addEventListener("touchstart", keepTouchEvent, false);
  raycaster.addEventListener("click", onClick);
}

const sc = document.getElementsByTagName("a-scene")[0];

function getFarthestIntersectionIdx(intersections: Intersections) {
  // Use latest hit (which should be farthest).
  var idx = 0;
  var distance = intersections[idx].distance;
  for (let i = 0; i < intersections.length; i++) {
    const intersection = intersections[i];
    // poi has priority over plane
    //          console.log(intersection.object.el);
    if (
      intersection.object.el.classList.contains("element") ||
      intersection.object.el.classList.contains("interactiveElement")
    ) {
      return i;
    }
    //          console.log(i, intersection.distance, intersection.object.el.id);
    if (intersection.distance > distance) {
      idx = i;
      distance = intersection.distance;
    }
  }
  return idx;
}

function addARRaycasterListeners() {
  const raycaster = document.getElementById("raycaster");
  const cursor = document.getElementById("cursor");
  const cameraCursor = document.getElementById("cameraCursor");
  const phantomStep = document.getElementById("phantomStep");

  // Note, -intersection is what the raycaster gets; the hit object gets -intersected.
  raycaster.addEventListener(
    "raycaster-intersection",
    (evt: RaycasterIntersectionEvent) => {
      const idx = getFarthestIntersectionIdx(evt.detail.intersections);
      //          console.log(evt.detail.intersections.length, idx);
      var point = evt.detail.intersections[idx].point;
      cursor.object3D.position.copy(point);

      const prevPoint = UTILITIES.prevPoint;
      if (UTILITIES.snappingEnabled && prevPoint) {
        const prevStep = document.getElementById(prevPoint.uuid);
        if (prevStep) {
          prevStep.object3D.getWorldPosition(prevStepPosition);
          const x =
            Math.abs(prevStepPosition.x - point.x) < SNAP_DISTANCE
              ? prevStepPosition.x
              : point.x;
          const z =
            Math.abs(prevStepPosition.z - point.z) < SNAP_DISTANCE
              ? prevStepPosition.z
              : point.z;
          phantomStep.object3D.position.set(x, point.y, z);
        }
      } else {
        phantomStep.object3D.position.copy(point);
      }
      phantomStep.object3D.visible = false;

      // var distance = evt.detail.intersections[idx].distance;
      const el = evt.detail.els[idx];
      // Set myIntersectedEl for use in the click handler.
      raycaster.components.raycaster.myIntersectedEl = el;
      // el.setAttribute("follow-intersection", "follow:true");

      // cursorOnObject and cursorOnPlane are children of cursor
      const cursorOnObject = document.getElementById("cursorOnObject");
      const cursorOnPlane = document.getElementById("cursorOnPlane");
      if (!cursorOnObject || !cursorOnPlane) {
        // react didn't render them yet
        return;
      }

      if (el.getAttribute("class") === "plane") {
        if (UTILITIES.editorMode) {
          el.setAttribute("visible", supportsAR());
        }

        cursorOnPlane.setAttribute("color", GREEN_CURSOR);
      }
      const container = getContainerNode(el);

      // the cursor position needs to be updated even in viewer mode
      if (!UTILITIES.editorMode) {
        if (el.classList.contains("interactiveElement")) {
          const point = getPointFromUuid(container.id);
          if (point && point.params && point.params.description) {
            cursorOnObject.setAttribute("visible", true);
            cursorOnPlane.setAttribute("visible", false);
            if (point && point.params && point.params.description) {
              setTimeout(() => UTILITIES.showInfoModal(point), 50);
            }
          }
        } else {
          showHUD("");
          cursorOnObject.setAttribute("visible", false);
          cursorOnPlane.setAttribute("visible", true);
        }
        cameraCursor.setAttribute("visible", false);
        cursor.setAttribute("visible", true);
        return;
      }

      cameraCursor.setAttribute("visible", false);
      cursor.setAttribute("visible", true);
      if (
        el.classList.contains("element") ||
        el.classList.contains("interactiveElement")
      ) {
        cursorOnObject.setAttribute("visible", true);
        cursorOnPlane.setAttribute("visible", false);
        const point = getPointFromUuid(container.id);
        const sameCollection = !(
          point &&
          point.record &&
          point.record.collectionId !== UTILITIES.selectedCollection
        );
        const canEdit =
          sameCollection && UTILITIES.canWriteOnSelectedCollection;
        let msg = "";
        if (
          !UTILITIES.currentAction &&
          container.classList.contains("editInfo")
        ) {
          if (canEdit) {
            msg += "Touch to edit info\n";
          } else if (!sameCollection) {
            msg += "Can't edit an element from a different collection\n";
          } else {
            msg += "You don't have the permission to edit this element\n";
          }
          msg += `x: ${roundTo3Decimals(
            container.object3D.position.x
          )}  y: ${roundTo3Decimals(
            container.object3D.position.y
          )}  z: ${roundTo3Decimals(container.object3D.position.z)}\n`;
        }
        if (canEdit && el.classList.contains("step") && !UTILITIES.prevPoint) {
          if (
            UTILITIES.originMarkerFound &&
            UTILITIES.currentAction === "editPath"
          ) {
            el.setAttribute("material", "color", "yellow");
            if (el.classList.contains("endOfPath")) {
              msg += "Touch to continue the path from there.\n";
            } else {
              msg += "Touch to fork the path from there.\n";
            }
          }
        }
        if (
          canEdit &&
          (!el.classList.contains("step") ||
            el.classList.contains("endOfPath")) &&
          UTILITIES.activeRealityType === "ar"
        ) {
          msg += "Touch with 2 fingers to delete the element";
        }
        showHUD(msg);
      } else {
        cursorOnObject.setAttribute("visible", false);
        cursorOnPlane.setAttribute("visible", true);
        if (
          UTILITIES.originMarkerFound &&
          UTILITIES.currentAction &&
          !(
            UTILITIES.currentAction === "marker" &&
            UTILITIES.activeRealityType === "ar"
          )
        ) {
          phantomStep.object3D.visible = true;
          // showHUD("Touch to add on detected plane\n" + el.id);
          showHUD("Touch to add on detected plane");
        } else {
          if (!UTILITIES.floorFound) {
            showHUD("Target the floor and touch");
          } else {
            showHUD(
              UTILITIES.originMarkerFound && !UTILITIES.currentAction
                ? "Target an element or click on an action"
                : ""
            );
          }
        }
        //showHUD('raycaster-intersection ' + distance + '\n' + JSON.stringify(point) + '\n' + el.id /*el.outerHTML*/);
      }
    }
  );
  raycaster.addEventListener(
    "raycaster-intersection-cleared",
    (evt: RaycasterIntersectionClearedEvent) => {
      // in viewer mode, keep the previous position when intersection cleared to have a right y for relocating
      cameraCursor.setAttribute("visible", true);
      cursor.setAttribute("visible", false);

      for (const el of evt.detail.clearedEls) {
        // el.setAttribute("follow-intersection", "follow:false");
        if (el.classList.contains("interactiveElement")) {
          setTimeout(() => UTILITIES.closeInfoModal(), 50);
        }
        if (el.classList.contains("plane")) {
          el.setAttribute("visible", "false");
        }
        if (el.classList.contains("step")) {
          el.setAttribute("material", "color", PATH_COLOR);
        }
      }

      // if we still intersect an element, it becomes the one where we follow the reticle
      // if (raycaster.components.raycaster.intersections.length > 0) {
      //   raycaster.components.raycaster.intersectionDetail.els =
      //     raycaster.components.raycaster.intersectedEls;
      //   raycaster.components.raycaster.intersectionDetail.intersections =
      //     raycaster.components.raycaster.intersections;
      //   raycaster.emit(
      //     "raycaster-intersection",
      //     raycaster.components.raycaster.intersectionDetail
      //   );
      // }

      if (!UTILITIES.editorMode) {
        showHUD("");
        return;
      }
      if (
        UTILITIES.currentAction &&
        !(
          UTILITIES.currentAction === "marker" &&
          UTILITIES.activeRealityType === "ar"
        )
      ) {
        showHUD("Can't detect a plane to add the element. Please move around.");
      } else {
        showHUD("");
      }
    }
  );
}

export function drawScene(points: Points) {
  const path = document.getElementById("path");
  while (path.firstChild) {
    path.removeChild(path.firstChild);
  }
  let prevPoint = null;
  const seen = {};
  for (const point of points) {
    if (point.type === "step") {
      const seenKey = `${point.position.x}:${point.position.y}:${
        point.position.z
      }`;
      const existingPoint = seen[seenKey];
      if (existingPoint) {
        if (!point.prevPoint) {
          point.forkFrom = existingPoint.uuid;
        } else {
          // we draw the fork before the main line string, hide the first point of the fork
          if (!existingPoint.prevPoint) {
            const existingEl = document.getElementById(existingPoint.uuid);
            existingPoint.forkFrom = point.uuid;
            if (existingEl) {
              existingEl.object3D.visible = false;
            }
            seen[seenKey] = point;
          }
        }
      } else {
        seen[seenKey] = point;
      }
      if (!prevPoint) {
        prevPoint = point;
        UTILITIES.selectedRecordId = point.recordId;
      }
      if (prevPoint.recordId !== point.recordId) {
        prevPoint = point;
        UTILITIES.selectedRecordId = point.recordId;
      }
      // if (point.image) {
      //   showThumb(point);
      // }
      createSegment(path, prevPoint, point, false);
      prevPoint = point;
    } else {
      let params = point.params;
      if (point.type === "blueprint") {
        params = { ...params };
        params.url = getAttachmentUrl(point.attachment);
      }
      createObject(
        point.type,
        path,
        point.position,
        point.rotation,
        point.text,
        params,
        false,
        point.uuid
      );
    }
  }
  UTILITIES.prevPoint = prevPoint;
}

function addEventListeners() {
  addARRaycasterListeners();
  addPlaneListeners();
  addClickToCreatePathListener();
}

function onSceneLoaded() {
  addEventListeners();
}

if (sc.hasLoaded) {
  onSceneLoaded();
} else {
  sc.addEventListener("loaded", onSceneLoaded);
}
