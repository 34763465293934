// @flow strict
import { UTILITIES } from "../state";

const createBlueprint = (
  position: XYZ,
  rotation: XYZ,
  id: string,
  textValue: string,
  params: ?Params,
  addClasses: boolean = true
): AframeHTMLElement => {
  const poi = document.createElement("a-entity");
  poi.object3D.position.set(position.x, position.y, position.z);
  poi.object3D.rotation.order = "YXZ";
  poi.object3D.rotation.set(rotation.x, rotation.y, rotation.z);
  poi.setAttribute("id", id);
  poi.setAttribute("visible", UTILITIES.editorMode);

  const obj = document.createElement("a-entity");
  if (params && params.url && params.url.endsWith("png")) {
    obj.setAttribute(
      "material",
      `src:${params.url}; alpha-test: 0.1; transparent: true; shader: flat`
    );
    const width = params.width || 50;
    const depth = params.depth || 50;
    obj.setAttribute(
      "geometry",
      `primitive: plane; width: ${width}; height: ${depth}`
    );
    obj.setAttribute("rotation", "-90 0 0");
  }
  if (params && params.url && params.url.endsWith("glb")) {
    obj.setAttribute("gltf-model", params.url);
  }
  poi.appendChild(obj);

  const origin = document.createElement("a-entity");
  origin.setAttribute("thing", "");
  origin.setAttribute("material", "color:green");
  poi.appendChild(origin);
  if (addClasses) origin.classList.add("element");

  if (addClasses) poi.classList.add("editInfo");
  if (addClasses) poi.classList.add("blueprint");
  return poi;
};

export default createBlueprint;
