// @flow strict
import React, { PureComponent } from "react";
import { Slider, withStyles } from "../material";

const styles = {
  root: {
    top: "40px",
    height: 300,
    position: "absolute",
    userSelect: "none"
  },
  slider: {
    padding: "0px 22px"
  }
};

type Props = {
  classes: Classes
};

type State = {
  value: number
};

class VerticalSlider extends PureComponent<Props, State> {
  state = {
    value: 0
  };

  handleChange = (
    event: SyntheticInputEvent<HTMLInputElement>,
    value: number
  ) => {
    this.setState({ value }, () => {
      document.getElementById("camera").object3D.position.y = this.state.value;
    });
  };

  render() {
    const { classes } = this.props;
    const { value } = this.state;

    return (
      <div className={classes.root}>
        <Slider
          classes={{ container: classes.slider }}
          value={value}
          onChange={this.handleChange}
          min={-60}
          max={60}
          step={1}
          vertical
        />
      </div>
    );
  }
}

export default withStyles(styles)(VerticalSlider);
