// @flow strict
import uuid from "uuid";
import { point as turfPoint } from "@turf/helpers";
import nearestPointOnLine from "@turf/nearest-point-on-line";

const linestrings = [];
const points2add = [];
const elevators = [];
const tempLineString = [];

export const fromRecords = (
  records: Array<Record>,
  includeSteps: boolean = true,
  createFeatures: boolean = true
): { points: Points, features: Features } => {
  records.sort((a, b) => (a.type === "path" ? 1 : 0)); // put path at the end so the steps are the first to remove for undo
  const points = [];
  let features;
  if (createFeatures) {
    features = [];
  }
  linestrings.length = 0;
  points2add.length = 0;
  elevators.length = 0;
  for (const record of records) {
    if (record.type === "path") {
      if (record.geometry.coordinates.length > 1) {
        // in turfLineString, there is this condition, we don't want to throw an error here
        // if (coordinates.length < 2) {
        //   throw new Error(
        //     "coordinates must be an array of two or more positions"
        //   );
        // }
        if (createFeatures) {
          linestrings.push({
            type: "Feature",
            properties: {
              accessible:
                record.accessible !== undefined ? record.accessible : true
            },
            geometry: {
              type: "LineString",
              coordinates: [...record.geometry.coordinates] // do a copy
            }
          });
        }
      }
      if (!includeSteps) {
        continue;
      }
      const sharedData = { accessible: record.accessible };
      // LinesString geometry
      let prevPoint = null;
      // the order of points in geometry must be kept
      for (const xzy of record.geometry.coordinates) {
        const step = {
          uuid: uuid.v4(),
          type: "step",
          position: { x: xzy[0], y: xzy[2], z: xzy[1] },
          prevPoint: prevPoint,
          recordId: record.id,
          record: record,
          sharedData: sharedData
        };
        points.push(step);
        prevPoint = step;
      }
    } else {
      if (createFeatures && record.type === "elevator") {
        elevators.push(record);
      }
      if (record.geometry.type === "Point") {
        const xzy = record.geometry.coordinates;
        if (
          createFeatures &&
          record.type !== "path" &&
          record.type !== "marker"
        ) {
          points2add.push(xzy);
        }
        const point = {
          ...record,
          uuid: record.type === "marker" ? `marker${record.text}` : uuid.v4(),
          position: { x: xzy[0], y: xzy[2], z: xzy[1] },
          recordId: record.id,
          record: record
        };
        delete point.id;
        delete point.geometry;
        delete point._status;
        delete point.last_modified;
        delete point.collectionId;
        // live migration of old data
        // $FlowFixMe point.subtype
        if (point.subtype) {
          point.params = { subtype: point.subtype };
        }
        // $FlowFixMe
        delete point.subtype;
        points.push(point);
      } else {
        console.error("Unknown geometry type", record.geometry.type);
      }
    }
  }
  if (features && linestrings.length > 0) {
    for (const p of points2add) {
      const tp = turfPoint(p);
      let closestPt = turfPoint([Infinity, Infinity], {
        dist: Infinity
      });
      let closestLine = linestrings[0];
      for (const linestring of linestrings) {
        if (
          linestring.geometry.coordinates[0][2] !== tp.geometry.coordinates[2]
        ) {
          // don't use this linestring if the first point and the door are not at the same height
          continue;
        }
        const intersectPt = nearestPointOnLine(linestring, tp);
        if (
          intersectPt.properties.dist !== Infinity &&
          intersectPt.properties.dist < closestPt.properties.dist
        ) {
          closestPt = intersectPt;
          closestLine = linestring;
        }
      }
      if (closestPt.properties.dist !== Infinity) {
        const newPoint = [
          closestPt.geometry.coordinates[0],
          closestPt.geometry.coordinates[1],
          closestLine.geometry.coordinates[closestPt.properties.index][2]
        ];
        // modify existing linestring in place
        closestLine.geometry.coordinates.splice(
          closestPt.properties.index + 1,
          0,
          newPoint
        );
        const dist = Math.sqrt(
          Math.pow(p[0] - newPoint[0], 2) +
            Math.pow(p[1] - newPoint[1], 2) +
            Math.pow(p[2] - newPoint[2], 2)
        );
        if (dist > 0.1) {
          features.push({
            type: "Feature",
            properties: {
              accessible: closestLine.properties.accessible
            },
            geometry: {
              type: "LineString",
              coordinates: [p, newPoint]
            }
          });
        }
      }
    }
  }

  if (features && elevators.length > 0) {
    elevators.sort((a, b) => {
      const xzyA = a.geometry.coordinates;
      const xzyB = b.geometry.coordinates;
      if (xzyA[0] === xzyB[0]) {
        if (xzyA[1] === xzyB[1]) {
          if (xzyA[2] === xzyB[2]) {
            return 0;
          } else if (xzyA[2] > xzyB[2]) {
            return 1;
          }
          return -1;
        } else if (xzyA[1] > xzyB[1]) {
          return 1;
        }
        return -1;
      } else if (xzyA[0] > xzyB[0]) {
        return 1;
      }
      return -1;
    });
    let prevElevator = null;
    tempLineString.length = 0;
    for (const elevator of elevators) {
      if (!prevElevator) {
        prevElevator = elevator;
        tempLineString.push(elevator.geometry.coordinates);
        continue;
      }
      if (
        prevElevator.geometry.coordinates[0] ===
          elevator.geometry.coordinates[0] &&
        prevElevator.geometry.coordinates[1] ===
          elevator.geometry.coordinates[1]
      ) {
        tempLineString.push(elevator.geometry.coordinates);
      } else {
        if (tempLineString.length > 1) {
          features.push({
            type: "Feature",
            properties: {
              accessible:
                elevator.accessible !== undefined ? elevator.accessible : true,
              type: "elevator"
            },
            geometry: {
              type: "LineString",
              coordinates: [...tempLineString] // do a copy
            }
          });
        }
        tempLineString.length = 0;
        tempLineString.push(elevator.geometry.coordinates);
      }
      prevElevator = elevator;
    }
    if (tempLineString.length > 1) {
      features.push({
        type: "Feature",
        properties: {
          accessible:
            prevElevator !== null && prevElevator.accessible !== undefined
              ? prevElevator.accessible
              : true,
          type: "elevator"
        },
        geometry: {
          type: "LineString",
          coordinates: [...tempLineString] // do a copy
        }
      });
    }
  }

  if (features) {
    for (const linestring of linestrings) {
      features.push(linestring);
    }
  }
  // $FlowFixMe
  return { points, features };
};
