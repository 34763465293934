// @flow strict

export const getHueLightState = (config: HueConfig) => {
  return fetch(`${config.api}/lights/${config.light}`, { method: "GET" })
    .then(response => response.json())
    .then(data => data.state);
};

export const setHueLightState = (
  newState: { on: boolean },
  config: HueConfig
) => {
  fetch(`${config.api}/lights/${config.light}/state`, {
    method: "PUT",
    body: JSON.stringify(newState)
  });
};
