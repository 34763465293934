// @flow strict
const createPOI = (
  position: XYZ,
  rotation: XYZ,
  id: string,
  textValue: string
): AframeHTMLElement => {
  const poi = document.createElement("a-entity");
  poi.object3D.position.set(position.x, position.y, position.z);
  poi.object3D.rotation.order = "YXZ";
  poi.object3D.rotation.set(rotation.x, rotation.y, rotation.z);
  poi.setAttribute("id", id);

  const point = document.createElement("a-entity");
  // point.setAttribute("gltf-model", "#cesium-man");
  point.setAttribute("thing", "");
  point.classList.add("element");
  point.setAttribute("scale", "0.15 0.15 0.15");
  point.setAttribute("rotation", "0 -90 0");
  poi.appendChild(point);

  const text = document.createElement("a-text");
  text.setAttribute("value", textValue);
  text.setAttribute("font", "dist/custom-msdf.json");
  text.setAttribute("negate", "false");
  text.setAttribute("scale", "0.5 0.5 0.5");
  text.setAttribute("position", "0.1 0.2 0");
  text.setAttribute("look-at-camera", "#camera");
  poi.appendChild(text);
  poi.classList.add("editInfo");
  return poi;
};

export default createPOI;
