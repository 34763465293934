/* global AFRAME */

// import "./three-ar";
// import "./ar-planes";
// import "./mozilla-xr-ar";

AFRAME.registerComponent("ar", {
  // dependencies: ["three-ar", "mozilla-xr-ar", "ar-planes"]
});

AFRAME.registerSystem("xr", {
  init: function() {
    this.supportAR = false;
    this.activeRealityType = "magicWindow";
    // console.log("xr");
    if ("xr" in navigator) {
      // console.log("navigator.xr");
      navigator.xr
        .isSessionSupported("immersive-ar")
        .then(supported => {
          // console.log("immersive-ar", supported);
          if (supported) {
            this.supportAR = true;
          }
        })
        .catch(() => {});
      const sc = document.getElementsByTagName("a-scene")[0];
      sc.renderer.xr.addEventListener("sessionstart", ev => {
        // console.log("sessionstart", ev);
        this.activeRealityType = "ar";
        this.el.emit("realityChanged", this.activeRealityType);
      });
      sc.renderer.xr.addEventListener("sessionend", ev => {
        // console.log("sessionend", ev);
        this.activeRealityType = "magicWindow";
        this.el.emit("realityChanged", this.activeRealityType);
      });
    }
  }
});
