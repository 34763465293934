// @flow strict
export const UTILITIES: {
  activeRealityType: RealityTypes,
  prompt: (
    message: string,
    value: ?string,
    callback: (value: string) => void
  ) => void,
  showInfoModal: (point: Point) => void,
  closeInfoModal: () => void,
  showEditModal: (point: Point, callback: (point: Point) => void) => void,
  currentAction: ?EditorAction,
  currentParams: ?Params,
  editorMode: boolean,
  email: ?string,
  floorFound: boolean,
  originMarkerFound: boolean,
  points: PointsMap,
  prevPoint: ?Point,
  selectedRecordId: string,
  pathFinder: ?mixed,
  selectedCollection: ?CollectionId,
  selectedDestination: ?string,
  canWriteOnSelectedCollection: boolean,
  snappingEnabled: boolean
} = {
  activeRealityType: "magicWindow",
  prompt: (message, value, callback) => {},
  showInfoModal: point => {},
  closeInfoModal: () => {},
  showEditModal: (point, callback) => {},
  currentAction: "editPath",
  currentParams: null,
  editorMode: false,
  email: "",
  floorFound: false,
  originMarkerFound: false,
  points: new Map(),
  prevPoint: null,
  selectedRecordId: "-1",
  pathFinder: null,
  selectedCollection: null,
  selectedDestination: null,
  canWriteOnSelectedCollection: false,
  snappingEnabled: true
};
