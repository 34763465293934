// @flow strict
import React, { PureComponent } from "react";
import {
  Button,
  Checkbox,
  Fab,
  FormControlLabel,
  Modal,
  withStyles
} from "../material";
import NavigationIcon from "@material-ui/icons/Navigation";
import Select from "./select";

const styles = theme => ({
  absolute: {
    position: "absolute",
    bottom: theme.spacing.unit * 12,
    right: theme.spacing.unit * 3,
    zIndex: 500
  },
  extendedIcon: {
    marginRight: theme.spacing.unit
  },
  buttons: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: "1em"
  },
  left: {
    alignSelf: "flex-start"
  },
  right: {
    alignSelf: "flex-end"
  },
  paper: {
    backgroundColor: "white",
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 2,
    top: theme.spacing.unit * 8,
    height: "70%",
    // overflowY: "scroll",
    zIndex: 999,
    position: "absolute",
    right: "10%",
    width: "80%",
    maxWidth: "40em",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "space-between"
  }
});

type Props = {
  classes: Classes,
  destinations: Destinations,
  selectedDestination: string,
  setDestination: (id: ?string) => void,
  accessibleToWheelchair: boolean,
  preferStairsOverElevator: boolean,
  onChangeAccessibleToWheelchair: (value: boolean) => void,
  onChangePreferStairsOverElevator: (value: boolean) => void
};

type State = {
  open: boolean
};

class DestinationButton extends PureComponent<Props, State> {
  state = {
    open: false
  };

  anchorEl: ?HTMLButtonElement;

  handleRequestClose = () => {
    this.setState({
      open: false
    });
  };

  onChangeAccessible = (event: SyntheticInputEvent<HTMLInputElement>) => {
    const value = event.target.checked;
    this.props.onChangeAccessibleToWheelchair(value);
  };

  onChangePreferStairs = (event: SyntheticInputEvent<HTMLInputElement>) => {
    const value = event.target.checked;
    this.props.onChangePreferStairsOverElevator(value);
  };

  render() {
    const {
      classes,
      destinations,
      selectedDestination,
      setDestination,
      accessibleToWheelchair,
      preferStairsOverElevator
    } = this.props;
    const hasElevator = !!destinations.find(d => d.type === "elevator");
    return (
      <React.Fragment>
        <Fab
          color="primary"
          variant="extended"
          className={classes.absolute}
          onClick={() =>
            this.setState(prevState => ({ open: !prevState.open }))
          }
          buttonRef={node => {
            this.anchorEl = node;
          }}
        >
          <NavigationIcon className={classes.extendedIcon} />
          Search
        </Fab>
        <Modal open={this.state.open} onClose={this.handleRequestClose}>
          <div className={classes.paper}>
            {destinations.length === 0 ? (
              <React.Fragment>
                <p>
                  You need at least a door or a zone with a label and a two
                  points path for a destination to appear here.
                </p>
                <div className={classes.buttons}>
                  <Button
                    className={classes.right}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      this.handleRequestClose();
                    }}
                  >
                    OK
                  </Button>
                </div>
              </React.Fragment>
            ) : null}
            {destinations.length > 0 ? (
              <React.Fragment>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={accessibleToWheelchair}
                      onChange={this.onChangeAccessible}
                      value="accessible"
                    />
                  }
                  label="Accessible to wheelchairs"
                />
                {hasElevator ? (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={preferStairsOverElevator}
                        onChange={this.onChangePreferStairs}
                        value="preferStairs"
                      />
                    }
                    disabled={accessibleToWheelchair}
                    label="Prefer stairs over elevator"
                  />
                ) : null}
                <Select
                  options={destinations}
                  value={destinations.find(
                    d => d.value === selectedDestination
                  )}
                  onChange={value => {
                    setDestination(value.value);
                    this.handleRequestClose();
                  }}
                  placeholder="Search a destination"
                />
                <div className={classes.buttons}>
                  <Button
                    className={classes.left}
                    variant="contained"
                    onClick={() => {
                      setDestination(null);
                      this.handleRequestClose();
                    }}
                  >
                    No destination
                  </Button>
                  <Button
                    className={classes.right}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setDestination(selectedDestination);
                      this.handleRequestClose();
                    }}
                  >
                    OK
                  </Button>
                </div>
              </React.Fragment>
            ) : null}
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(DestinationButton);
