// @flow strict
import React, { PureComponent } from "react";
import { ButtonBase, MenuItem, Popover, Snackbar } from "../material";
import { BLUE } from "../theme";

type Props = {
  active: boolean,
  disabled?: boolean,
  simpleAction: boolean,
  onClick: (actionId: ?EditorAction, subtype: ?DoorEnum) => void,
  children?: Array<MenuItem>,
  icon: string,
  id: string
};

type State = {
  open: boolean
};

export default class ActionButton extends PureComponent<Props, State> {
  static defaultProps = {
    active: false,
    disabled: false,
    simpleAction: false
  };

  state = {
    open: false
  };

  anchorEl: ?HTMLButtonElement;

  handleClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    if (this.props.disabled) {
      return;
    }
    // This prevents ghost click.
    event.preventDefault();
    const actionId: EditorAction = event.currentTarget.id;

    this.setState(
      prevState => ({
        open: !this.props.simpleAction ? !prevState.open : false
      }),
      () => this.props.onClick(actionId, null)
    );
  };

  handleRequestClose = () => {
    this.setState({
      open: false
    });
  };

  render() {
    const {
      active,
      children,
      disabled,
      icon,
      id,
      onClick,
      simpleAction
    } = this.props;
    const newChildren = React.Children.map(children, (child: MenuItem) => {
      const editorAction: EditorAction = id;
      return React.cloneElement(child, {
        onClick: () => {
          this.handleRequestClose();
          onClick(editorAction, child.props.value);
        }
      });
    });
    let color;
    const notImplemented = !simpleAction && !newChildren;
    if (notImplemented) {
      color = this.state.open || active ? "#666" : "#eee";
    } else {
      color = this.state.open || active ? BLUE : "#666";
    }
    if (disabled) {
      color = "#ccc";
    }
    return (
      <div>
        <ButtonBase
          buttonRef={node => {
            this.anchorEl = node;
            if (node) {
              node.id = id;
            }
          }}
          onClick={this.handleClick}
          style={{
            height: 48,
            margin: "0 0 5px 0",
            backgroundColor: color,
            padding: "0 10px"
          }}
        >
          <img src={icon} style={{ width: 48, height: 48 }} alt="" />
        </ButtonBase>
        {!simpleAction && newChildren ? (
          <Popover
            open={this.state.open}
            anchorReference="anchorEl"
            anchorEl={this.anchorEl}
            anchorOrigin={{ horizontal: "left", vertical: "top" }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            onClose={this.handleRequestClose}
          >
            {newChildren}
          </Popover>
        ) : null}
        {notImplemented && (
          <Snackbar
            open={this.state.open}
            message="Action not implemented yet"
            onClose={() => {
              this.handleRequestClose();
              onClick(null, null);
            }}
          />
        )}
      </div>
    );
  }
}
