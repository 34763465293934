// @flow strict
import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import { kinto } from "../utils";
import { Button, TextField } from "../material";
import PageContainer from "../components/PageContainer";

type Props = {
  history: { push: (path: string, { message?: string }) => void }
};

type State = { email: string, password: string, error: string };

class SignIn extends Component<Props, State> {
  state = {
    email: "",
    password: "",
    error: ""
  };

  createAccount = (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (this.state.email.length === 0 || this.state.password === 0) {
      this.setState(() => ({
        error: "Please fill the fields."
      }));

      return;
    }
    this.setState(() => ({ error: "" }));
    (async () => {
      try {
        await kinto.api.createAccount(this.state.email, this.state.password);
        /*
  data:
    id: "johnsmith@example.com"
    last_modified: 1574158138886
    password: "$2b$12$N40F0c/HrROKRxBfoN2I1.U5Yt5OYhzPvrErU/28E.D7JPv3lm2ti"
  permissions:
    write: ["account:johnsmith@example.com"]
  */
        this.props.history.push("/", {
          message: "Account created. Please log in."
        });
      } catch (e) {
        this.setState(() => ({
          error: "There was an error creating the account."
        }));
      }
    })();
  };

  onChangeEmail = (value: string) => {
    this.setState(() => ({ email: value, error: "" }));
  };

  onChangePassword = (value: string) => {
    this.setState(() => ({ password: value, error: "" }));
  };

  render() {
    const { email, password, error } = this.state;
    return (
      <PageContainer>
        <form onSubmit={this.createAccount}>
          <div>
            <TextField
              label="Enter your email"
              type="email"
              required
              onChange={(event: SyntheticInputEvent<HTMLInputElement>) => {
                const value = event.currentTarget.value;
                this.onChangeEmail(value);
              }}
              value={email}
            />
          </div>
          <div>
            <TextField
              label="Enter a password"
              type="password"
              required
              onChange={(event: SyntheticInputEvent<HTMLInputElement>) => {
                const value = event.currentTarget.value;
                this.onChangePassword(value);
              }}
              value={password}
            />
          </div>
          <div>
            <Button
              variant="contained"
              key="button"
              style={{ marginTop: "1em" }}
              className={`login`}
              type="submit"
            >
              Create account
            </Button>
          </div>
          <div style={{ marginTop: "1em" }}>{error}</div>
        </form>
      </PageContainer>
    );
  }
}

export default withRouter(SignIn);
