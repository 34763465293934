// @flow strict
import { PURPLE, GREEN } from "../theme";
import { DEFAULT_PARAMS } from "../constants";

const createZone = (
  position: XYZ,
  rotation: XYZ,
  id: string,
  textValue: string,
  params: ?Params,
  addClasses: boolean = true,
  color: string = PURPLE
): AframeHTMLElement => {
  const poi = document.createElement("a-entity");
  poi.object3D.position.set(position.x, position.y, position.z);
  poi.object3D.rotation.order = "YXZ";
  poi.object3D.rotation.set(rotation.x, rotation.y, rotation.z);
  poi.setAttribute("id", id);

  const width = (params && params.width) || DEFAULT_PARAMS.zone.width;
  const depth = (params && params.depth) || DEFAULT_PARAMS.zone.depth;
  const borderColor = params && params.standNumber ? GREEN : color;
  const borderWidth = 0.04;
  const halfWidth = width / 2;
  const halfDepth = depth / 2;
  const halfBorderWidth = borderWidth / 2;

  const parent = document.createElement("a-entity");
  parent.setAttribute("rotation", "-90 0 0");
  poi.appendChild(parent);

  // border left
  const plane1 = document.createElement("a-plane");
  if (addClasses) plane1.classList.add("element");
  plane1.setAttribute(
    "position",
    `${-halfWidth + halfBorderWidth} ${halfDepth} 0`
  );
  plane1.setAttribute("height", depth);
  plane1.setAttribute("width", borderWidth);
  plane1.setAttribute(
    "material",
    `color: ${borderColor}; shader: flat; side: double`
  );
  parent.appendChild(plane1);

  // border right
  const plane2 = document.createElement("a-plane");
  if (addClasses) plane2.classList.add("element");
  plane2.setAttribute(
    "position",
    `${halfWidth - halfBorderWidth} ${halfDepth} 0`
  );
  plane2.setAttribute("height", depth);
  plane2.setAttribute("width", borderWidth);
  plane2.setAttribute(
    "material",
    `color: ${borderColor}; shader: flat; side: double`
  );
  parent.appendChild(plane2);

  // border top
  const plane3 = document.createElement("a-plane");
  if (addClasses) plane3.classList.add("element");
  plane3.setAttribute("position", `0 ${depth - halfBorderWidth} 0`);
  plane3.setAttribute("height", borderWidth);
  plane3.setAttribute("width", width - 2 * borderWidth);
  plane3.setAttribute(
    "material",
    `color: ${borderColor}; shader: flat; side: double`
  );
  parent.appendChild(plane3);

  // border bottom
  // const plane4 = document.createElement("a-plane");
  // if (addClasses) plane4.classList.add("element");
  // plane4.setAttribute("position", `0 ${halfBorderWidth} 0`);
  // plane4.setAttribute("height", borderWidth);
  // plane4.setAttribute("width", width - 2 * borderWidth);
  // plane4.setAttribute(
  //   "material",
  //   `color: ${PURPLE}; shader: flat; side: double`
  // );
  // parent.appendChild(plane4);

  // border bottom left
  const bottomWidth = width - 2 * borderWidth;
  const plane4 = document.createElement("a-plane");
  if (addClasses) plane4.classList.add("element");
  plane4.setAttribute(
    "position",
    `${-bottomWidth / 4 - DEFAULT_PARAMS.door.width / 4} ${halfBorderWidth} 0`
  );
  plane4.setAttribute("height", borderWidth);
  plane4.setAttribute("width", bottomWidth / 2 - DEFAULT_PARAMS.door.width / 2);
  plane4.setAttribute(
    "material",
    `color: ${borderColor}; shader: flat; side: double`
  );
  parent.appendChild(plane4);

  // border bottom right
  const plane5 = document.createElement("a-plane");
  if (addClasses) plane5.classList.add("element");
  plane5.setAttribute(
    "position",
    `${bottomWidth / 4 + DEFAULT_PARAMS.door.width / 4} ${halfBorderWidth} 0`
  );
  plane5.setAttribute("height", borderWidth);
  plane5.setAttribute("width", bottomWidth / 2 - DEFAULT_PARAMS.door.width / 2);
  plane5.setAttribute(
    "material",
    `color: ${borderColor}; shader: flat; side: double`
  );
  parent.appendChild(plane5);

  const center = document.createElement("a-plane");
  if (addClasses) center.classList.add("element");
  if (addClasses && params && params.description) {
    center.classList.add("interactiveElement");
  }
  center.setAttribute("position", `0 ${halfDepth} 0`);
  center.setAttribute("height", depth - 2 * borderWidth);
  center.setAttribute("width", width - 2 * borderWidth);
  center.setAttribute(
    "material",
    `color: #eee; transparent: true; opacity: 0.2; shader: flat; side: double`
  );
  parent.appendChild(center);

  const text = document.createElement("a-text");
  text.setAttribute("value", textValue);
  text.setAttribute("font", "dist/custom-msdf.json");
  text.setAttribute("negate", "false");
  text.setAttribute("position", `0 0.8 ${-halfDepth}`);
  text.setAttribute("look-at-camera", "#camera");
  poi.appendChild(text);
  if (addClasses) poi.classList.add("editInfo");
  return poi;
};

export default createZone;
