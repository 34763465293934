// @flow strict
import debounce from "lodash/debounce";
import throttle from "lodash/throttle";
import React, { Component } from "react";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Modal,
  Slider,
  TextField,
  withStyles
} from "../material";

import deleteObject from "../scene/deleteObject";

import { DEFAULT_PARAMS, KINTO_URL, emptyObject } from "../constants";
import { getAttachmentUrl, kinto } from "../utils";

const DEG2RAD = Math.PI / 180;
const RAD2DEG = 180 / Math.PI;

const fieldWidth = { width: "15ex" };
const emptyString = "";
const widthInputProps = { min: 1, max: 30 };
const heightInputProps = { min: 1, max: 5 };
const depthInputProps = { min: 1, max: 30 };

export function getModalStyle() {
  return {
    position: "absolute",
    right: 0,
    width: "100%",
    maxWidth: "40em",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "space-between"
  };
}

const styles = (theme: Theme) => ({
  paper: {
    backgroundColor: "rgba(255, 255, 255, 0.6)", //theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 2,
    top: theme.spacing.unit * 8
  },
  container: {
    // for the slider
    margin: "20px 0 30px 0"
  },
  inlineFields: { flex: 1, flexDirection: "row" },
  buttons: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: "10px",
    marginRight: "50px"
  },
  left: { alignSelf: "flex-start" },
  right: { alignSelf: "flex-end" },
  attachmentImgContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: "150px",
    maxHeight: "150px",
    textAlign: "center",
    background: "#fff",
    border: "1px solid #ddd",
    margin: "10px"
  },
  attachmentInputContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "baseline"
  },
  attachmentImg: {
    maxWidth: "100%",
    maxHeight: "100%"
  },
  attachmentLabel: {
    color: "rgba(0, 0, 0, 0.54)"
  }
});

type Props = {
  classes: Classes,
  onClose: () => void,
  updatePoint: (point: Point, emitEvent?: boolean) => void,
  point: Point,
  wsUrl?: string,
  selectedBucket: string,
  selectedCollection: string,
  syncSelectedCollection: () => void,
  saved: boolean
};

type State = {
  point: Point,
  untouchedPoint: Point
};

class EditModal extends Component<Props, State> {
  // $FlowFixMe
  state = { point: {} };
  attachmentFormRef = React.createRef();

  componentDidMount() {
    this.setState(() => ({
      point: this.props.point,
      untouchedPoint: this.props.point
    }));
  }

  postAttachment = () => {
    // var headers = { Authorization: "Basic " + btoa("alice:passwd") };
    const headers = kinto.api._headers;
    const attributes = {};
    const form = this.attachmentFormRef.current;
    if (form === null) return;
    // File object from input field
    if (form.elements.attachment.files.length === 0) return;
    const file = form.elements.attachment.files[0];
    // console.log(file);

    // Build form data
    var payload = new FormData();
    // Multipart attachment
    payload.append("attachment", file, file.name || "floor.png");
    // Record attributes and permissions JSON encoded
    payload.append("data", JSON.stringify(attributes));
    // payload.append("permissions", JSON.stringify(perms));

    // Post form using GlobalFetch API
    const server = KINTO_URL;
    const bucket = this.props.selectedBucket;
    const collection = this.props.selectedCollection;
    const record = this.state.point.recordId;
    var url = `${server}/buckets/${bucket}/collections/${collection}/records/${record}/attachment`;
    fetch(url, { method: "POST", body: payload, headers: headers }).then(
      result => {
        // console.log(result);
        this.props.syncSelectedCollection();
        this.props.onClose();
      }
    );
  };

  updateObject = debounce(
    () => {
      this.props.updatePoint(this.state.point, false);
    },
    200,
    { leading: false }
  );

  throttledUpdateObject = throttle(
    () => {
      this.props.updatePoint(this.state.point, false);
    },
    200,
    { leading: false }
  );

  validate = () => {
    this.props.updatePoint(this.state.point);
    if (
      this.state.point.type === "marker" &&
      this.state.point.position.y !== this.state.untouchedPoint.position.y
    ) {
      const path = document.getElementById("path");
      if (path) {
        path.object3D.position.y =
          path.object3D.position.y +
          this.state.untouchedPoint.position.y -
          this.state.point.position.y;
      }
    }
    this.props.onClose();
  };

  cancel = () => {
    if (this.state.untouchedPoint !== this.state.point) {
      this.props.updatePoint(this.state.untouchedPoint, false);
    }
    this.props.onClose();
  };

  onChangeAccessible = (event: SyntheticInputEvent<HTMLInputElement>) => {
    const value = event.target.checked;
    this.setState(prevState => {
      const newPoint = { ...prevState.point };
      // use the same object on purpose
      newPoint.sharedData.accessible = value;
      return {
        // $FlowFixMe
        point: newPoint
      };
    }, this.updateObject);
  };

  onChangeLabel = (event: SyntheticInputEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    this.setState(
      prevState => ({
        // $FlowFixMe
        point: { ...prevState.point, text: value }
      }),
      this.updateObject
    );
  };

  onChangeDescription = (event: SyntheticInputEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    this.setState(prevState => ({
      // $FlowFixMe
      point: {
        ...prevState.point,
        params: {
          ...(prevState.point.params || emptyObject),
          description: value
        }
      }
    }));
  };

  onChangeStandNumber = (event: SyntheticInputEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    this.setState(
      prevState => ({
        // $FlowFixMe
        point: {
          ...prevState.point,
          params: {
            ...(prevState.point.params || emptyObject),
            standNumber: value
          }
        }
      }),
      this.updateObject
    );
  };

  onChangeWidth = (event: SyntheticInputEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    this.setState(
      prevState => ({
        // $FlowFixMe
        point: {
          ...prevState.point,
          params: {
            ...(prevState.point.params || emptyObject),
            width: parseFloat(value)
          }
        }
      }),
      this.updateObject
    );
  };

  onChangeHeight = (event: SyntheticInputEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    this.setState(
      prevState => ({
        // $FlowFixMe
        point: {
          ...prevState.point,
          params: {
            ...(prevState.point.params || emptyObject),
            height: parseFloat(value)
          }
        }
      }),
      this.updateObject
    );
  };

  onChangeDepth = (event: SyntheticInputEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    this.setState(
      prevState => ({
        // $FlowFixMe
        point: {
          ...prevState.point,
          params: {
            ...(prevState.point.params || emptyObject),
            depth: parseFloat(value)
          }
        }
      }),
      this.updateObject
    );
  };

  onChangePositionX = (event: SyntheticInputEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    this.setState(
      prevState => ({
        // $FlowFixMe
        point: {
          ...prevState.point,
          position: {
            ...prevState.point.position,
            x: parseFloat(value)
          }
        }
      }),
      this.updateObject
    );
  };

  onChangePositionY = (event: SyntheticInputEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    this.setState(
      prevState => ({
        // $FlowFixMe
        point: {
          ...prevState.point,
          position: {
            ...prevState.point.position,
            y: parseFloat(value)
          }
        }
      }),
      this.updateObject
    );
  };

  onChangePositionZ = (event: SyntheticInputEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    this.setState(
      prevState => ({
        // $FlowFixMe
        point: {
          ...prevState.point,
          position: {
            ...prevState.point.position,
            z: parseFloat(value)
          }
        }
      }),
      this.updateObject
    );
  };

  onChangeRotationX = (event: SyntheticInputEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    this.setState(
      prevState => ({
        // $FlowFixMe
        point: {
          ...prevState.point,
          rotation: {
            // $FlowFixMe
            ...prevState.point.rotation,
            x: parseFloat(value) * DEG2RAD
          }
        }
      }),
      this.updateObject
    );
  };

  onChangeRotationY = (event: SyntheticInputEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    this.setState(
      prevState => ({
        // $FlowFixMe
        point: {
          ...prevState.point,
          rotation: {
            // $FlowFixMe
            ...prevState.point.rotation,
            y: parseFloat(value) * DEG2RAD
          }
        }
      }),
      this.updateObject
    );
  };

  onChangeRotationZ = (event: SyntheticInputEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    this.setState(
      prevState => ({
        // $FlowFixMe
        point: {
          ...prevState.point,
          rotation: {
            // $FlowFixMe
            ...prevState.point.rotation,
            z: parseFloat(value) * DEG2RAD
          }
        }
      }),
      this.updateObject
    );
  };

  onChangeRotationYslider = (
    event: SyntheticInputEvent<HTMLInputElement>,
    value: number
  ) => {
    if (isNaN(value)) {
      return;
    }
    this.setState(
      prevState => ({
        // $FlowFixMe
        point: {
          ...prevState.point,
          rotation: {
            // $FlowFixMe
            ...prevState.point.rotation,
            y: value * DEG2RAD
          }
        }
      }),
      this.throttledUpdateObject
    );
  };

  onDeleteElement = () => {
    deleteObject(document.getElementById(this.state.point.uuid));
    this.props.onClose();
  };

  render() {
    const { classes, wsUrl } = this.props;
    const point = this.state.point;
    if (!point.position) {
      // first render, componentDidMount didn't execute yet.
      return null;
    }

    return (
      <Modal open aria-labelledby="modal-title" onClose={this.cancel}>
        <div style={getModalStyle()} className={classes.paper}>
          {point.type === "step" ? (
            <FormControlLabel
              control={
                <Checkbox
                  checked={point.sharedData.accessible}
                  onChange={this.onChangeAccessible}
                  value={String(point.sharedData.accessible)}
                />
              }
              label="Accessible to wheelchairs"
            />
          ) : null}
          {point.type !== "step" && point.type !== "blueprint" ? (
            <TextField
              label="Label"
              margin="normal"
              autoFocus
              onChange={this.onChangeLabel}
              value={point.text}
            />
          ) : null}
          {point.type === "blueprint" ? (
            <React.Fragment>
              {/* $FlowFixMe */}
              <form ref={this.attachmentFormRef}>
                <div>
                  <label
                    htmFor="blueprintImage"
                    className={classes.attachmentLabel}
                  >
                    Blueprint image (should be a transparent png 1024x1024)
                  </label>
                  {point.attachment ? (
                    <div className={classes.attachmentImgContainer}>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={getAttachmentUrl(point.attachment)}
                      >
                        <img
                          className={classes.attachmentImg}
                          src={getAttachmentUrl(point.attachment)}
                          alt=""
                          title={point.attachment.filename}
                        />
                      </a>
                    </div>
                  ) : null}
                  {!this.props.saved ? (
                    <p>You need to save before uploading an image</p>
                  ) : (
                    <div className={classes.attachmentInputContainer}>
                      <TextField
                        label=""
                        id="blueprintImage"
                        type="file"
                        margin="normal"
                        name="attachment"
                        // onChange={this.onChangeDescription}
                        // value={(point.params || emptyObject).url || emptyString}
                      />
                      <div style={{ paddingLeft: "10px" }}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={this.postAttachment}
                        >
                          upload file
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              </form>
            </React.Fragment>
          ) : null}

          {point.type === "zone" || point.type === "door" ? (
            <TextField
              label="Description"
              margin="normal"
              multiline
              rows="4"
              onChange={this.onChangeDescription}
              value={(point.params || emptyObject).description || emptyString}
            />
          ) : null}
          {wsUrl && point.type === "zone" ? (
            <TextField
              label="Stand number"
              margin="normal"
              onChange={this.onChangeStandNumber}
              value={(point.params || emptyObject).standNumber || emptyString}
            />
          ) : null}
          <div className={classes.inlineFields}>
            {DEFAULT_PARAMS[point.type] && DEFAULT_PARAMS[point.type].width ? (
              <TextField
                label="Width"
                style={fieldWidth}
                type="number"
                inputProps={widthInputProps}
                margin="normal"
                onChange={this.onChangeWidth}
                // $FlowFixMe
                value={(point.params || DEFAULT_PARAMS[point.type]).width}
              />
            ) : null}
            {DEFAULT_PARAMS[point.type] && DEFAULT_PARAMS[point.type].height ? (
              <TextField
                label="Height"
                style={fieldWidth}
                type="number"
                inputProps={heightInputProps}
                margin="normal"
                onChange={this.onChangeHeight}
                // $FlowFixMe
                value={(point.params || DEFAULT_PARAMS[point.type]).height}
              />
            ) : null}
            {DEFAULT_PARAMS[point.type] && DEFAULT_PARAMS[point.type].depth ? (
              <TextField
                label="Depth"
                style={fieldWidth}
                type="number"
                inputProps={depthInputProps}
                margin="normal"
                onChange={this.onChangeDepth}
                // $FlowFixMe
                value={(point.params || DEFAULT_PARAMS[point.type]).depth}
              />
            ) : null}
          </div>
          <div className={classes.inlineFields}>
            <TextField
              label="position X (m)"
              style={fieldWidth}
              type="number"
              margin="normal"
              onChange={this.onChangePositionX}
              value={point.position.x}
            />
            <TextField
              label="position Y (m)"
              style={fieldWidth}
              type="number"
              margin="normal"
              onChange={this.onChangePositionY}
              value={point.position.y}
            />
            <TextField
              label="position Z (m)"
              style={fieldWidth}
              type="number"
              margin="normal"
              onChange={this.onChangePositionZ}
              value={point.position.z}
            />
          </div>
          {point.rotation ? (
            <div className={classes.inlineFields}>
              <TextField
                label="rotation X"
                style={fieldWidth}
                type="number"
                margin="normal"
                disabled
                onChange={this.onChangeRotationX}
                // $FlowFixMe
                value={Math.round(point.rotation.x * RAD2DEG)}
              />
              <TextField
                label="rotation Y"
                style={fieldWidth}
                type="number"
                margin="normal"
                onChange={this.onChangeRotationY}
                // $FlowFixMe
                value={Math.round(point.rotation.y * RAD2DEG)}
              />
              <TextField
                label="rotation Z"
                style={fieldWidth}
                type="number"
                margin="normal"
                disabled
                onChange={this.onChangeRotationZ}
                // $FlowFixMe
                value={Math.round(point.rotation.z * RAD2DEG)}
              />
            </div>
          ) : null}
          {point.rotation ? (
            <Slider
              classes={classes}
              // $FlowFixMe
              value={point.rotation.y * RAD2DEG}
              onChange={this.onChangeRotationYslider}
              min={-180}
              max={180}
              step={1}
            />
          ) : null}

          <div className={classes.buttons}>
            <div className={classes.left}>
              <Button
                variant="contained"
                color="secondary"
                onClick={this.onDeleteElement}
              >
                DELETE ELEMENT
              </Button>
            </div>
            <div className={classes.right}>
              <Button
                variant="contained"
                color="primary"
                onClick={this.validate}
              >
                OK
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default withStyles(styles)(EditModal);
