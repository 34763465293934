/* global THREE */
// @flow strict
import emit from "./emit";
import { UTILITIES } from "../state";
import { calcDistance, getPointFromUuid } from "./utils";
import { updateObject } from "./createObject";
import { PATH_OPACITY } from "../theme";

const tempMatrix = new THREE.Matrix4();
const tempVector = new THREE.Vector3();

const updateStep = (
  point: Point,
  points: PointsMap, // will be modified
  emitEvent: boolean = true,
  updateSegmentOnly: boolean = false
) => {
  if (point.type === "step") {
    const step = document.getElementById(point.uuid);
    if (!updateSegmentOnly) {
      step.object3D.position.set(
        point.position.x,
        point.position.y,
        point.position.z
      );
    }
    // point.prevPoint may be an old object from the initial load, we need to get
    // the latest version from the PointsMap
    let prevPoint = point.prevPoint && points.get(point.prevPoint.uuid);
    if (prevPoint) {
      const prevStep = document.getElementById(prevPoint.uuid);
      const target = prevStep.object3D.getWorldPosition(tempVector);
      step.object3D.lookAt(target);
      // $FlowFixMe
      const plane: AframeHTMLElement = step.querySelector("a-plane");
      if (plane) {
        const dist = calcDistance(prevPoint.position, point.position);
        plane.setAttribute("width", dist);
        plane.object3D.matrixAutoUpdate = false;
        plane.object3D.matrix.identity();
        plane.object3D.applyMatrix(tempMatrix.makeTranslation(-dist / 2, 0, 0)); // for a-plane
        plane.object3D.applyMatrix(
          tempMatrix.makeRotationFromEuler(
            new THREE.Euler(-Math.PI / 2, 0, Math.PI / 2)
          )
        );
        let color = point.sharedData.color;
        if (!point.sharedData.accessible) {
          color = "orange";
        } else {
          color = color && color !== "orange" ? color : "#126a98";
        }
        plane.setAttribute(
          "material",
          `color: ${color}; shader: flat; side: double; opacity: ${PATH_OPACITY}`
        );
      }
    }
    if (!updateSegmentOnly) {
      if (emitEvent) {
        emit("point-updated", point);
      }
      points.set(point.uuid, point);
      for (const p of points.values()) {
        if (p.type === "step") {
          if (p.forkFrom === point.uuid) {
            const newPoint = { ...p, position: point.position };
            points.set(newPoint.uuid, newPoint);
            updateStep(newPoint, points, emitEvent);
          }
          // update all points of this linestring
          if (p.uuid !== point.uuid && p.recordId === point.recordId) {
            updateStep(p, points, emitEvent, true);
          }
        }
      }
    }
  }
};

const editInfoCallback = (point: Point, emitEvent: boolean = true) => {
  if (point.type === "step") {
    updateStep(point, new Map(UTILITIES.points), emitEvent);
  } else {
    const el = document.getElementById(point.uuid);
    updateObject(el, point);
    if (emitEvent) {
      emit("point-updated", point);
    }
  }
};

const editInfo = (container: AframeHTMLElement) => {
  const point = getPointFromUuid(container.id);
  if (point) {
    UTILITIES.showEditModal(point, editInfoCallback);
  }
};
export default editInfo;
