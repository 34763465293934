// @flow strict
import uuid from "uuid";

import { UTILITIES } from "../state";
import { createRandomId } from "./utils";
import { raycasterNeedsUpdate } from "./planes";
import createASA from "./createASA";
import createDoor from "./createDoor";
import createPOI from "./createPOI";
import createZone from "./createZone";
import createElevator from "./createElevator";
import createMarker from "./createMarker";
import createGltf from "./createGltf";
import createBlueprint from "./createBlueprint";
import emit from "./emit";
import { getAttachmentUrl } from "../utils";

export const CREATE_FUNCTIONS = {
  asa: createASA,
  door: createDoor,
  elevator: createElevator,
  marker: createMarker,
  poi: createPOI,
  zone: createZone,
  gltf: createGltf,
  blueprint: createBlueprint
};

export function updateObject(container: AframeHTMLElement, point: Point) {
  const path = container.parentNode;
  if (path) {
    path.removeChild(container);
    // $FlowFixMe
    let params = point.params;
    // $FlowFixMe
    if (point.type === "blueprint") {
      params = { ...params };
      params.url = getAttachmentUrl(point.attachment);
    }
    createObject(
      point.type,
      // $FlowFixMe
      path,
      point.position,
      // $FlowFixMe
      point.rotation,
      // $FlowFixMe
      point.text,
      params,
      false,
      point.uuid
    );
  }
}

function createObject(
  type: string,
  path: AframeHTMLElement,
  position: XYZ,
  rotation: XYZ,
  textValue: ?string,
  params: ?Params = null,
  emitEvents: boolean = true,
  pointUuid: ?string
): ?AframeHTMLElement {
  if (type === "blueprint" && UTILITIES.activeRealityType === "ar") {
    // only load blueprint on desktop
    return;
  }
  const createEntityFunction = CREATE_FUNCTIONS[type];
  if (!createEntityFunction) {
    return;
  }
  let poi;
  const callback = newText => {
    const id = pointUuid ? pointUuid : uuid.v4();
    poi = createEntityFunction(position, rotation, id, newText, params);
    if (!poi.parentNode) {
      // for marker, we use createMarker before createObject, so the
      // entity is already in the dom, so be sure to not duplicate the entry in the dom
      path.appendChild(poi);
    }
    setTimeout(raycasterNeedsUpdate);
    if (emitEvents) {
      const detail = {
        uuid: id,
        type: type,
        text: newText,
        position: { x: position.x, y: position.y, z: position.z },
        rotation: { x: rotation.x, y: rotation.y, z: rotation.z },
        recordId: createRandomId(),
        params: params
      };
      if (params) {
        detail.params = params;
      }
      emit("point-added", detail);
    }
  };

  let newText = textValue;
  if (newText === null) {
    newText = "";
  }
  callback(newText);
  return poi;
}

export default createObject;
